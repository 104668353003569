/* eslint-disable key-spacing */
import React from "react";
import {
  Tag,
  Button,
  Avatar,
  DatePicker,
  Space,
  Modal,
  Select,
  message,
} from "antd";
import {
  income as incomeApi,
  exports as exportsApi,
  property as propertyApi,
} from "../../apis";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";
import { datetimeFormat, renderUser, yen } from "../../utils";
import {
  ExclamationCircleOutlined,
  UserOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import Form from "./components/Form";
import EditForm from "./components/EditForm";
import { useTranslate } from "../../hooks";
import useFetch from "../../hooks/useFetch";

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [query, setQuery] = React.useState("");
  const { s3host, roles, incomeStatus, user } = useSelector((state) => state.general);
  const [action, setAction] = React.useState([]);
  const { checkPermission } = usePermission();
  const [properties] = useFetch(propertyApi.selectAdmin)([]);

  const [filters, setFilters] = React.useState({
    date: "",
    status: "",
    property: undefined,
    type: "ADMIN",
  });

  const columns = useHeader({
    s3host,
    roles,
    type: params.type,
    user,
    translate,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "detail": {
          history.push(`/income/${record._id}`);
          break;
        }
        case "update": {
          setAction(["update", record]);
          break;
        }
        case "pay": {
          Modal.confirm({
            title: translate("system.list.model.title", "Confirm"),
            icon: <ExclamationCircleOutlined />,
            content: translate(
              "income.list.modal.content",
              "Would you like to verify this payment?"
            ),
            okText: translate("income.list.modal.oktext", "Verify"),
            cancelText: translate("system.list.modal.canceltext", "Cancel"),
            onOk: async () => {
              try {
                await incomeApi.pay(record._id);
                myTableRef.current.reload();
              } catch (err) {
                console.log(err);
              }
            },
          });
          break;
        }
        default:
      }
    },
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    setFilters({
      date: undefined,
      status: undefined,
      property: undefined,
      type: "ADMIN",
    });
  }, [params.type]);

  const dateFilter = (e) => {
    console.log(e);
    setFilters({ ...filters, date: e && moment(e).format("YYYY/MM/DD") });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      date: undefined,
      status: undefined,
      property: undefined,
      type: "ADMIN",
    });
  };

  const onExport = async () => {
    try {
      await exportsApi.exportIncome();
      history.push("/income/exports");
    } catch (err) {
      message.error(
        translate(
          "exports.actionerror",
          "There is pending export please wait for it to end"
        )
      );
    }
  };

  return (
    <PageContainer>
      <PageHeader
        title={translate("income.list.pageheader.title", "Income")}
        extra={[
          <Button onClick={() => setAction(["new"])}>
            {translate("income.list.btn.calculate", "Calculate")}
          </Button>,
          // <Button onClick={() => onExport()}>
          //   {translate("income.list.btn.export", "Export")}
          // </Button>
        ]}
      />
      <PageContent>
        <Filter
          extra={[
            <DatePicker
              picker="month"
              value={filters.date && moment(filters.date)}
              onChange={dateFilter}
              style={{ width: "100%" }}
              allowClear
              placeholder={translate("calculate.select.month", "Select month")}
            />,
            <Select
              className="grid-item"
              value={filters.status}
              style={{ width: "130px" }}
              onChange={(e) => setFilters({ ...filters, status: e })}
              placeholder={translate(
                "income.list.filter.status",
                "Income status"
              )}
              allowClear
            >
              {incomeStatus.map((type, index) => (
                <Select.Option key={index} value={type}>
                  {translate(`system.incomestatus.${type}`, type)}
                </Select.Option>
              ))}
            </Select>,
            <Button onClick={onClear}>
              {translate("system.filter.btn.clear", "Clear")}
            </Button>,
          ]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 500 }}
            value={filters.property}
            onChange={(e) => setFilters({ ...filters, property: e })}
            placeholder={translate("income.list.select.property", "Property ")}
            allowClear
            options={properties.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })}
          ></Select>
        </Filter>
        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={incomeApi.list}
          rowKey={(record) => record?.rowKey}
        />
      </PageContent>
      <Modal
        title={translate("income.list.btn.calculate", "Calculate")}
        closeIcon={<div></div>}
        maskClosable={false}
        visible={action && action[0] === "new"}
        footer={false}
      >
        <div style={{ paddingTop: "24px" }}>
          <Form
            action={action}
            setAction={setAction}
            onCancel={() => setAction([])}
            type="admin"
            reload={async () => {
              await myTableRef.current.reload();
            }}
          />
        </div>
      </Modal>
      <Modal
        title={translate("system.action.edit", "Edit")}
        closeIcon={<div></div>}
        maskClosable={false}
        visible={action && action[0] === "update"}
        footer={false}
      >
        <div style={{ paddingTop: "24px" }}>
          <EditForm
            action={action[1]}
            setAction={setAction}
            onCancel={() => setAction([])}
            reload={async () => {
              await myTableRef.current.reload();
            }}
          />
        </div>
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate, user }) => {
  return [
    {
      title: "№",
      dataIndex: "rowKey",
      width: "20px",
    },
    {
      title: translate("system.property.list.image", "Property"),
      render: (record) => {
        return (
          <Space style={{ display: "flex" }}>
            <Avatar
              src={`${record?.property?.image}`}
              shape="square"
              icon={<PictureOutlined />}
            />
            <div>{record?.property?.name}</div>
          </Space>
        );
      },
    },
    {
      title: translate("income.header.total", "Total(tax)"),
      render: (record) => {
        return `${yen(record.total)}`;
      },
    },
    {
      title: translate("system.useheader.title.status", "Status"),
      render: (record) => {
        return (
          <Tag>
            {translate(
              `system.incomestatus.${record.incomeStatus}`,
              record.incomeStatus
            )}
          </Tag>
        );
      },
    },
    {
      title: translate("system.useheader.income.date", "Date"),
      render: (record) => {
        return <div>{moment(record.startDate).format("YYYY/MM")}</div>;
      },
    },
    {
      title: translate("system.useheader.title.action", "Action"),
      width: 100,
      render: (record) => {
        if (record.incomeStatus === "PENDING" && (user.role === "ADMIN" || user.role === "SUPER_ADMIN")) {
          return (
            <RowAction
              actions={{
                detail: translate("system.list.action.detail", "Detail"),
                update: translate("system.action.edit", "Edit"),
                pay: translate("income.list.action.pay", "Pay"),
              }}
              onClick={(key) => onAction(key, record)}
            />
          );
        }
        return (
          <RowAction
            actions={{
              detail: translate("system.list.action.detail", "Detail"),
            }}
            onClick={(key) => onAction(key, record)}
          />
        );
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
