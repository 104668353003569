/* eslint-disable key-spacing */
import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { general, auth } from "./apis";
import {
  PrivateRoute,
  PublicRoute,
  NavBar,
  Header,
  Loader,
  SocketClient,
} from "./components";
import { Login, Notfound } from "./pages";
import { NewUser, EditUser, UserList, UserRole } from "./pages/User";
import { PropertyList, EditProperty, NewProperty } from "./pages/Property";
import { OrderList, OrderDetail } from "./pages/Order";
import { EquipmentList } from "./pages/Equipment";
import { NewsList } from "./pages/News";
import { UseTypeList } from "./pages/UseType";
import { PropertyTypeList } from "./pages/PropertyType";
import Policy from "./pages/Policy";
import styled from "styled-components";
import { TransactionList } from "./pages/Transaction";
import { AppEditUser, AppNewUser, AppUserList } from "./pages/AppUser";
import { ConversationDetails, ConversationList } from "./pages/Message";
import { DocumentsList } from "./pages/Documents";
import { LocalizationList } from "./pages/Localization";
import {
  PropertyRequestDetail,
  PropertyRequestList,
  PropertyUpdateList,
} from "./pages/PropertyRequest";
import { RecommendList } from "./pages/Recommend";
import {
  IncomeDetail,
  IncomeList,
  IncomeOwnerDetail,
  IncomeOwnerList,
} from "./pages/Income";
import { ChatDetails, ChatList } from "./pages/Chat";
import { StationsSetting } from "./pages/Location";
import { Calendar } from "./pages/Calendar";

import { ConfigProvider } from "antd";
import { TicketList } from "./pages/Ticket";
import { ExportsList } from "./pages/Exports";
import jaJP from "antd/es/locale/ja_JP"; // Import the locale file for Japanese
import moment from "moment";
import "moment/locale/ja";
import { ArticleChildList, ArticleList } from "./pages/Article";

moment.locale("ja");

const App = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { lang } = useSelector((state) => state.general);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const asyncFn = async () => {
      try {
        await Promise.all([general.init()(dispatch)]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    asyncFn();

    if (token) auth.me()(dispatch);
  }, [dispatch, token]);

  React.useEffect(() => {
    dispatch({
      type: "layout.mobile",
      payload: isMobile,
    });
  }, [isMobile]);

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <div>
      <ConfigProvider locale={lang === "JP" ? jaJP : null}>
        <Switch>
          <PublicRoute path="/login" component={Login} />
          <PrivateRoute path="/">
            <Header isMobile={isMobile} />
            <Container isMobile={isMobile}>
              <div className="content">
                <div className="navbar">
                  <NavBar isMobile={isMobile} />
                </div>
                <div className="wrapper">
                  <Switch>
                    <Route path="/property" component={PropertyList} exact />
                    <Route
                      path="/property/:user"
                      component={PropertyList}
                      exact
                    />
                    <Route
                      path="/property/create/new"
                      component={NewProperty}
                      exact
                    />
                    <Route
                      path="/property/edit/:id"
                      component={EditProperty}
                      exact
                    />
                    <Route
                      path="/property/request/create"
                      component={PropertyRequestList}
                      exact
                    />
                    <Route
                      path="/property/request/update"
                      component={PropertyUpdateList}
                      exact
                    />
                    <Route
                      path="/property/request/:id"
                      component={PropertyRequestDetail}
                      exact
                    />

                    <Route path="/order" component={OrderList} exact />
                    <Route
                      path="/order/:type/:property"
                      component={OrderList}
                      exact
                    />
                    <Route path="/order/:id" component={OrderDetail} exact />

                    <Route
                      path="/transaction"
                      component={TransactionList}
                      exact
                    />

                    <Route path="/income" component={IncomeList} exact />
                    <Route
                      path="/income/owner"
                      component={IncomeOwnerList}
                      exact
                    />
                    <Route
                      path="/income/owner/:id"
                      component={IncomeOwnerDetail}
                      exact
                    />
                    <Route
                      path="/income/exports"
                      component={ExportsList}
                      exact
                    />
                    <Route path="/income/:id" component={IncomeDetail} exact />
                    <Route
                      path="/conversation"
                      component={ConversationList}
                      exact
                    />
                    <Route
                      path="/conversation/:id"
                      component={ConversationDetails}
                      exact
                    />
                    <Route path="/ticket" component={TicketList} exact />

                    <Route path="/app_user" component={AppUserList} exact />
                    <Route path="/app_user/new" component={AppNewUser} exact />
                    <Route
                      path="/app_user/edit/:id"
                      component={AppEditUser}
                      exact
                    />

                    <Route path="/user/:type" component={UserList} exact />
                    <Route path="/user/new/:type" component={NewUser} exact />
                    <Route
                      path="/user/edit/:id/:type"
                      component={EditUser}
                      exact
                    />
                    <Route path="/recommend" component={RecommendList} exact />

                    <Route
                      path="/settings/equipments"
                      component={EquipmentList}
                      exact
                    />
                    <Route path="/settings/news" component={NewsList} exact />
                    <Route path="/settings/article" component={ArticleList} exact />
                    <Route path="/settings/article/:id" component={ArticleChildList} exact />
                    <Route
                      path="/settings/types"
                      component={UseTypeList}
                      exact
                    />
                    <Route
                      path="/settings/property_types"
                      component={PropertyTypeList}
                      exact
                    />
                    <Route path="/settings/role" component={UserRole} exact />
                    <Route path="/settings/policy" component={Policy} exact />
                    <Route
                      path="/settings/location"
                      component={StationsSetting}
                      exact
                    />
                    <Route
                      path="/settings/localization"
                      component={LocalizationList}
                      exact
                    />
                    <Route
                      path="/settings/documents"
                      component={DocumentsList}
                      exact
                    />
                    <Route
                      path="/settings/calendar"
                      component={Calendar}
                      exact
                    />

                    <Route path="/chat" component={ChatList} exact />
                    <Route path="/chat/:id" component={ChatDetails} exact />

                    <Route component={Notfound} />
                  </Switch>
                </div>
              </div>
            </Container>
          </PrivateRoute>
        </Switch>
        {token ? <SocketClient /> : <></>}
      </ConfigProvider>
    </div>
  );
};

export default App;

const Container = styled.div`
  overflow: hidden;
  .content {
    position: relative;
    width: 100%;
    background: #fff;
    .navbar {
      display: flex;
      width: ${(props) => (props.isMobile ? 80 : 230)}px;
      border-right: 1px solid #e8e8e8;
      height: calc(100vh - 60px);
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      overflow: auto;
      overflow-x: hidden;
      background: #ffffff;
      ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-button {
        width: 2px;
        height: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background: #1890ff;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
    }
    .wrapper {
      position: relative;
      padding-left: ${(props) => (props.isMobile ? 80 : 230)}px;
      height: calc(100vh - 60px);
      overflow: auto;
      overflow-x: hidden;
    }
  }
`;
