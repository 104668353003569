import React from "react";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import {
  Button,
  Modal,
  message as notify,
  Input,
  Tag,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { MyTable, RowAction } from "../../components";
import { articleApi } from "../../apis";
import { useSelector } from "react-redux";
import usePermission from "../../hooks/usePermission";
import styled from "styled-components";
import Form from "./ParentForm";
import { useTranslate } from "../../hooks";
import { Filter } from "../../components/Design";
import { useHistory } from "react-router-dom";

const List = () => {
  const { push } = useHistory();
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const { checkPermission } = usePermission();
  const { isMobile } = useSelector((state) => state.general);
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    articleId: null,
    query    : "",
  });

  const columns = useHeader({
    translate,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "edit": {
          setAction(["update", record]);
          break;
        }
        case "detail": {
          push(`/settings/article/${record._id}`);
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction([]);
  };

  const onSubmit = async (data) => {
    if (action && action[0] === "update") {
      await articleApi.update(action[1]?._id, data);
    }
    else {
      await articleApi.create(data);
    }
    notify.success(translate("system.request.success", "Success!"));
    myTableRef.current.reload();
    onCancel();
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title={translate("navbar.submenu.article", "FAQ")}
        extra={[
          <Button
            key={0}
            shape={isMobile && "circle"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setAction(["create"])}
          >
            {!isMobile && translate("system.btn.add", "Add")}
          </Button>
        ]}
      />
      <PageContent>
        <Filter>
          <Input
            className="grid-item"
            prefix={<SearchOutlined />}
            value={query}
            placeholder={translate("system.input.search", "Search...")}
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />
        </Filter>
        <Table
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={articleApi.list}
          rowKey={(record) => record.rowKey}
        />
      </PageContent>
      <Modal
        title={
          action && action[0] === "create"
            ? translate("system.pagecontainer.model.title.add", "Add")
            : translate("system.pagecontainer.model.title.edit", "Edit")
        }
        width="1000"
        open={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}
      >
        {action && action[0] && (
          <Form onCancel={onCancel} action={action} onSubmit={onSubmit} />
        )}
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("article.title", "Title"),
      render: (record) => {
        return `${record.title}`;
      },
    },
    {
      title : translate("article.show", "Show"),
      render: (record) => {
        if (record.show) {
          return <Tag color="green">
            {translate("article.show", "Show")}
          </Tag>;
        }
        return <Tag color="red">
          {translate("article.hide", "Hidden")}
        </Tag>;
      },
    },
    {
      title : translate("article.sort", "Sort"),
      render: (record) => {
        return `${record.sort}`;
      },
    },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        let actions = {
          edit  : translate("system.action.edit", "Edit"),
          detail: translate("system.action.detail", "Detail")
        };
        return (
          <RowAction
            actions={actions}
            onClick={(key) => onAction(key, record)}
          />
        );
      },
    },
  ];
};

export default List;

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
