/* eslint-disable key-spacing */
import { Button, Divider, Text, Image } from "@mantine/core";
import dayjs from "dayjs";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";
import { PrinterOutlined } from "@ant-design/icons";
import { yen } from "../../../utils";
import Inkan from "../../../assets/inkan.png";
import Logo from "../../../assets/logo.svg";
import moment from "moment";
import numeral from "numeral";

const Print = ({ data }) => {
  const { translate } = useTranslate();

  if (!data) {
    return null;
  }

  return (
    <PrintStyle>
      <div className="print">
        <div className="container">
          <div>
            <Divider color="black" />
            <Text size={22} align="center" py={8}>
              {translate("print.summary.title", "Summary")}
            </Text>
            <Divider color="black" />
            <Divider color="black" mt={2} />
          </div>
          <div style={{ marginTop: "15px", marginBottom: "15px", display : "flex", justifyContent: "space-between" }}>
            <Image fit="contain" width={100} src={Logo} alt="logo" />
            <Text size={14} style={{ display: "flex", justifyContent: "end" }}>
              {dayjs(data?.createdAt).format("YYYY")}年
              {dayjs(data?.createdAt).format("MM")}月
              {dayjs(data?.createdAt).format("DD")}日
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ width: "70%", display: "flex", flexDirection: "column", }}
            >
              <Text size={14}>
                {translate("print.summary.spacename", "Space name")}
              </Text>
              <Text size={14} align="center">
                {data?.conversation?.property?.address?.city}
                {data?.conversation?.property?.address?.address}{" "}
              </Text>
              <Text size={14} align="center">
                {data?.conversation?.property?.address?.buildingName}
              </Text>
              <Divider color="black" />
            </div>
            <Image width={80} src={Inkan} alt="inkan" />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                width: "50%",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Text size={14}>
                  {translate("print.summary.tenantname", "Tenant name")}
                </Text>
                <Text size={14} align="center">
                  {data?.conversation?.tenant?.paymentName}
                </Text>
                <Divider labelPosition="right" label={translate("print.summary.sign", "Sign")} color="black" />
              </div>

            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Text size={12} align="end">
                {translate("print.summary.ideal", "Ideal LTD")}
              </Text>
              <Text size={12} align="end">
                {translate(
                  "print.summary.address",
                  "Tokyo,Shibuya-ku, Yoyogi 2-4-9 NMF Shibuya Minamiguchi building 2F"
                )}
              </Text>
              <Text size={12} align="end">
                {translate(
                  "print.summary.address2",
                  "Tokyo,Shibuya-ku, Yoyogi 2-4-9 NMF Shibuya Minamiguchi building 2F"
                )}
              </Text>
              <Text size={12} align="end">
                {translate(
                  "print.businessnumber",
                  "Business number: T3-0110-0105-4106"
                )}
              </Text>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Text size={12} align="start">
              {translate(
                "print.summary.body1",
                "平素は格別のお引さ立てを腸り厚く御礼申し上げます。"
              )}
            </Text>
            <Text size={12} align="start">
              {translate(
                "print.summary.body2",
                "下記の通り御見積させて頂きます。"
              )}
            </Text>
          </div>
          <div style={{ marginTop: 20, gap: 8, display: "flex" }}>
            <div
              style={{
                border: "1px solid black",
                width: "50%",
                display: "flex",
              }}
            >
              <div
                style={{
                  borderRight: "1px solid black",
                }}
              >
                <Text
                  p={12}
                  size={14}
                  style={{
                    width: "100%",
                    minWidth : "100px",
                    display: "flex",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                  align="center"
                >
                  {translate("print.summary.amount", "Estimated amount")}
                </Text>
              </div>
              <Text
                p={12}
                size={14}
                style={{
                  display: "flex",
                  width: "100%",
                  fontSize: "14px",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {numeral(parseFloat(data?.payment?.total)).format("0,0")}円
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                alignItems: "center",
                justifyContent : "space-between",
              }}
            >
              <Text size={14} >
                {translate("print.summary.taxincluded", "(Tax included)")}
              </Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <Text size={12} align="center">
                  {translate("print.summary.expiredate", "Expire date")} :
                  {dayjs.utc(data?.payment?.expireDate).format("YYYY")}年
                  {dayjs.utc(data?.payment?.expireDate).format("MM")}月
                  {dayjs.utc(data?.payment?.expireDate).format("DD")}日
                </Text>
                <Divider w="100%" color="black" />
              </div>
            </div>
          </div>
          <div
            style={{
              border: "1px solid black",
              width: "100%",
              display: "flex",
              marginTop: 40,
            }}
          >
            <div style={{ borderRight: "1px solid black", width: "40%" }}>
              <Text size={14} style={{ borderBottom: "1px solid black" }} p={8}>
                {translate("print.summary.row", "Items")}
              </Text>
              <Text size={12} p={6}>
                {translate("print.summary.item1", "Rental space usage amount")}
              </Text>
            </div>
            <div style={{ borderRight: "1px solid black", width: "50%" }}>
              <Text size={14} style={{ borderBottom: "1px solid black" }} p={8}>
                {translate("print.summary.date", "Date")}
              </Text>
              <div>
                <Text size={12} p={6}>
                  {moment(data?.payment?.startDate).format("YYYY/MM/DD")}〜{moment(data?.payment?.endDate).format("YYYY/MM/DD")}{" "}
                  ({data?.payment?.days}
                  {translate("print.summary.indays", "(In day)")})
                </Text>
              </div>
            </div>
            <div style={{ width: "30%" }}>
              <Text align="right" size={14} p={8} style={{ borderBottom: "1px solid black" }}>
                {translate("print.summary.price", "Price")}
              </Text>
              <Text align="right" size={12} p={6}>
                {numeral(parseFloat(data?.payment?.total)).format("0,0")}円
              </Text>
            </div>
          </div>
        </div>
        <div >
          <Text size={14} mt={50} >
            {translate(
              "print.reminder1",
              "※有効期限が利用開始日を過ぎて設定されている場合は、メツセジ上でお伝えする有効期限までにお振込みをお願いいたします。"
            )}
          </Text>
          <Text size={14} >
            {translate(
              "print.reminder2",
              "※恐れ入りますが、お振込み手数料はお客様のご負担でお願いいたします。"
            )}
          </Text>
        </div>
        <div style={{ paddingTop: "1rem" }}>
          <div style={{ fontSize: "14px" }}>{translate("print.transfer", "Transfer account")}</div>
          <table style={{ tableLayout: "auto", width: "100%", borderCollapse: "collapse", border: "1px solid black" }}>
            <tbody>
              <tr>
                <td style={{ border: "1px solid black", textAlign: "center", padding: "0.5rem 0.25rem", fontSize: "0.875rem" }}>
                  {translate("app.chat.transfer.bank", "Bank")}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center", padding: "0.5rem 0.25rem", fontSize: "0.875rem" }}>
                  {translate("app.chat.transfer.bank.value", "Mitsuho bank")}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", textAlign: "center", padding: "0.5rem 0.25rem", fontSize: "0.875rem" }}>
                  {translate("app.chat.transfer.branch", "Branch")}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center", padding: "0.5rem 0.25rem", fontSize: "0.875rem" }}>
                  {translate("app.chat.transfer.branch.value", "Tokyo Shibuya")}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", textAlign: "center", padding: "0.5rem 0.25rem", fontSize: "0.875rem" }}>
                  {translate("app.chat.transfer.number", "Account number")}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center", padding: "0.5rem 0.25rem", fontSize: "0.875rem" }}>
                  {translate("app.chat.transfer.number.value", "005-2554345")}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black", textAlign: "center", padding: "0.5rem 0.25rem", fontSize: "0.875rem" }}>
                  {translate("app.chat.transfer.name", "Account name")}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center", padding: "0.5rem 0.25rem", fontSize: "0.875rem" }}>
                  {translate("app.chat.transfer.name.value", "Ideal Co.LTD")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Divider my={15} className="isHidden" />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="isHidden"
      >
        <Button
          style={{ width: "50%", backgroundColor: "#e05c04" }}
          className="isHidden"
          onClick={() => window.print()}
        >
          <PrinterOutlined style={{ fontSize: 22, marginRight: 5 }} />{" "}
          {translate("app.chat.chat.print", "Print")}
        </Button>
      </div>
    </PrintStyle>
  );
};

const PrintStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media print {
    min-width: 100%;
    margin-top: 20px;

    body * {
      display: none !important;
    }

    .isHidden {
      display: none !important;
    }
    .container {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`;

export default Print;
