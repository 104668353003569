import React from "react";
import {
  Tag,
  Button,
  Avatar,
  Modal,
  Input,
  DatePicker,
  Radio,
  Select,
} from "antd";
import { app_user as appuserApi } from "../../apis";
import { PlusOutlined, UserOutlined, SearchOutlined } from "@ant-design/icons";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";
import Form from "./components/Form";
import { useTranslate } from "../../hooks";

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [query, setQuery] = React.useState("");
  const { s3host, roles, isMobile } = useSelector((state) => state.general);
  const [action, setAction] = React.useState([]);
  const [rowData, setRowData] = React.useState();
  const { checkPermission } = usePermission();
  const { translate } = useTranslate();

  const [filters, setFilters] = React.useState({
    type        : undefined,
    query       : "",
    role        : undefined,
    startDate   : "",
    endDate     : "",
    isActive    : undefined,
    customerType: "TENANT",
  });

  const onCancel = () => {
    setAction([]);
  };

  const columns = useHeader({
    s3host,
    roles,
    type    : params.type,
    translate,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "remove": {
          Modal.confirm({
            title  : translate("system.list.model.title", "Confirm"),
            icon   : <ExclamationCircleOutlined />,
            content: record.isActive
              ? translate(
                "appuser.list.modal.content",
                "Would you like to disactivate this user!"
              )
              : translate(
                "appuser.list.modal.content.activate",
                "Would you like to disactivate this user!"
              ),
            okText: record.isActive
              ? translate("appuser.modal.disactivate", "Disactivate")
              : translate("appuser.modal.activate", "Activate"),
            cancelText: translate("system.list.modal.canceltext", "Cancel"),
            onOk      : async () => {
              await appuserApi.remove(record._id);
              myTableRef.current.reload();
            },
          });
          break;
        }
        case "edit": {
          history.push(`/app_user/edit/${record._id}`);
          break;
        }
        case "properties": {
          history.push(`/property/${record._id}`);
          break;
        }
        case "password": {
          setAction(["create"]);
          setRowData(record);
          break;
        }
        default:
      }
    },
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    setFilters({
      type        : undefined,
      query       : "",
      role        : undefined,
      customerType: "TENANT",
      isActive    : undefined,
      startDate   : undefined,
      endDate     : undefined,
    });
  }, [params.type]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").toDate(),
      endDate  : e && moment(e[1]).endOf("days").toDate(),
    });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      query       : "",
      type        : undefined,
      role        : undefined,
      isActive    : undefined,
      startDate   : undefined,
      endDate     : undefined,
      customerType: filters.customerType,
    });
  };

  return (
    <PageContainer>
      <PageHeader
        title={translate("appuser.list.pageheader.title", "App user")}
        extra={[
          <Button
            type="primary"
            shape={isMobile && "circle"}
            onClick={() => history.push("/app_user/new")}
            icon={<PlusOutlined />}
          >
            {!isMobile && translate("system.btn.adduser", "Add user")}
          </Button>,
        ]}
      />
      <PageContent>
        <Filter
          extra={[
            <Radio.Group
              defaultValue={filters.customerType}
              buttonStyle="solid"
              onChange={(e) =>
                setFilters((state) => ({
                  ...state,
                  customerType: e.target.value,
                }))
              }
            >
              <Radio.Button value="TENANT">
                {translate("app_user.list.radio.btn.tenant", "Tenant")}
              </Radio.Button>
              <Radio.Button value="OWNER">
                {translate("app_user.list.radio.btn.owner", "Owner")}
              </Radio.Button>
            </Radio.Group>,
            <Input
              className="grid-item"
              prefix={<SearchOutlined />}
              value={query}
              placeholder={translate("system.search", "Search...")}
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />,
            <Select
              className="grid-item"
              value={filters.isActive}
              style={{ width: "130px" }}
              onChange={(e) => setFilters({ ...filters, isActive: e })}
              placeholder={translate("property.list.select.status", "Status")}
              allowClear
            >
              {[true, false].map((item, index) => (
                <Select.Option key={index} value={item}>
                  {item
                    ? translate("appuser.list.status.active", "Active")
                    : translate("appuser.list.status.disactive", "Disactive")}
                </Select.Option>
              ))}
            </Select>,
            <DatePicker.RangePicker
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Button onClick={onClear}>
              {translate("system.filter.btn.clear", "Clear")}
            </Button>,
          ]}
        ></Filter>

        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={appuserApi.list(params.type)}
          rowKey={(record) => record?.rowKey}
        />
      </PageContent>
      <Modal
        title={
          action && action[0] === "create"
            ? translate("system.pagecontainer.model.title.add", "Add")
            : translate("system.pagecontainer.model.title.edit", "Edit")
        }
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}
      >
        {action && action[0] && (
          <Form
            onCancel={onCancel}
            action={action}
            rowData={rowData}
            setAction={setAction}
          />
        )}
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ type, onAction, translate }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("system.useheader.title.avatar", "Avatar"),
      render: (record) => {
        return <Avatar src={`${record.avatar}`} icon={<UserOutlined />} />;
      },
    },
    {
      title : translate("appuser.list.useheader.title.lastname", "Lastname"),
      render: (record) => {
        return record.lastName || "-";
      },
    },
    {
      title : translate("appuser.list.useheader.title.firstname", "Firstname"),
      render: (record) => {
        return record.firstName || "-";
      },
    },
    {
      title: translate(
        "appuser.list.useheader.title.orgname",
        "Organization name"
      ),
      render: (record) => {
        return record.orgname || "-";
      },
    },
    {
      title : translate("appuser.form.rep", "Representative"),
      render: (record) => {
        return record.rep || "-";
      },
    },
    {
      title : translate("appuser.list.useheader.title.email", "Email"),
      render: (record) => {
        return record.email;
      },
    },
    {
      title : translate("appuser.list.useheader.title.status", "Status"),
      render: (record) => {
        return record.isActive ? (
          <Tag color="green">
            {translate("appuser.list.status.active", "Active")}
          </Tag>
        ) : (
          <Tag color="red">
            {translate("appuser.list.status.disactive", "Disactive")}
          </Tag>
        );
      },
    },
    {
      title : translate("appuser.list.useheader.title.date", "Date"),
      render: (record) => {
        return <div>{moment(record.createdAt).format("YYYY/MM/DD")}</div>;
      },
    },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        let actions = {
          edit  : translate("system.action.detail", "Edit"),
          remove: record.isActive
            ? translate("appuser.modal.disactivate", "Disactivate")
            : translate("appuser.modal.activate", "Activate"),
          password: translate(
            "appuser.list.useheader.rowacions.changepassword",
            "Change password"
          ),
        };
        if (record.customerType === "OWNER") {
          actions = {
            ...actions,
            properties: translate("system.action.properties", "Properties"),
          };
        }
        return (
          <RowAction
            actions={actions}
            onClick={(key) => onAction(key, record)}
          />
        );
      },
    },
  ].filter((col) => col);
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
