/* eslint-disable key-spacing */
const initialState = {
  chats: [],
  conversations: [],
  notifications: [],
  conversation: {
    projects: [],
  },
  notification: {},
  payload: false,
  file: undefined,
  isConversation: false,
  scrollOn: true,
  reload: null,
};

const socket = (state = initialState, action) => {
  switch (action.type) {
    case "socket.chats.init": {
      const { payload } = action;
      return {
        ...state,
        chats: [...payload],
      };
    }
    case "socket.chats": {
      const { payload } = action;

      return {
        ...state,
        chats: payload,
      };
    }

    case "socket.chat": {
      const { payload } = action;
      return {
        ...state,
        chats: [payload, ...state.chats],
        scrollOn: true,
      };
    }

    case "scroll.off": {
      return {
        ...state,
        scrollOn: false,
      };
    }

    case "socket.chat.seen": {
      const { payload = {} } = action;

      return {
        ...state,
        chats: {
          ...state.chats,
          [payload.uid]: payload,
        },
      };
    }

    case "socket.chat.reload": {
      const { payload } = action;

      return {
        ...state,
        reload: payload,
      };
    }

    case "socket.conversation": {
      return {
        ...state,
        isConversation: !state.isConversation,
      };
    }

    case "socket.clear": {
      return {
        ...state,
        chats: initialState.chats,
        conversation: initialState.conversation,
      };
    }
    default:
      return state;
  }
};

export default socket;
