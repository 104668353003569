import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form, FormItem, Input, Select } from "formik-antd";
import { user as userApi, property as propertyApi } from "../../../apis";
import { Button, Form as AntForm, message } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { useTranslate } from "../../../hooks";

const { Option } = Select;

const ManagerFormComponent = ({ onCancel, setAction, rowData, reload }) => {
  const { translate } = useTranslate();
  const [searchValue, setSearchValue] = React.useState([]);

  const onSubmit = async (data) => {
    try {
      await propertyApi.setManager(rowData._id, {
        userid: data.userid
      });
      message.success(translate("system.request.success", "Success!"));
      setAction([]);
      reload();
    } catch (error) {
      message.error(error.message || translate("system.request.failed", "Failed!"));
      reload();
    }
  };

  const FormSchema = Yup.object().shape({
    userid: Yup.string().required(translate("system.regex.required", "required field"))
  });

  let timer;

  const selectSearch = async (q = "") => {
    if (timer)
      clearTimeout(timer);

    timer = setTimeout(async () => {
      let res = await userApi.select({ query: q });
      setSearchValue(res);
    }, 300);

  };

  React.useEffect(() => {
    selectSearch();
  }, []);

  return (
    <Formik
      initialValues={{ userid: rowData.manager }}
      validationSchema={FormSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem name="userid" label={translate("property.components.manager", "Manager")} required>
              <Select
                showSearch
                name="userid"
                placeholder={translate("property.components.queryform.choose", "Choose")}
                onSearch={(v) => selectSearch(v)}
                allowClear
              >
                {(searchValue || []).map((item, index) => {
                  return <Option key={index} value={item._id}>{`${item.lastName}${item.firstName}`}</Option>;
                })}
              </Select>
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>{translate("system.btn.save", "Save")}</Button>
              <Button className="button" type="default" onClick={onCancel}>{translate("system.btn.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}

    </Formik>
  );
};

export default ManagerFormComponent;