import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form, FormItem, Input, Select } from "formik-antd";
import { recommend as recommendApi, property as propertyApi } from "../../../apis";
import { Button, Form as AntForm, message } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { useTranslate } from "../../../hooks";

const { Option } = Select;



const QueryFormComponents = ({ onCancel, setAction, rowData, reload }) => {
  const { translate } = useTranslate();
  const [searchValue, setSearchValue] = React.useState([]);

  const onSubmit = async (data) => {
    try {
      await propertyApi.recommend(rowData._id, {
        recommendIds: data.value
      });
      message.success(translate("system.request.success", "Success!"));
      setAction([]);
      reload();
    } catch (error) {
      message.error(error.message || translate("system.request.failed", "Failed!"));
      reload();
    }
  };

  const FormSchema = Yup.object().shape({
    value: Yup.array().required(translate("system.regex.required", "required field"))
  });

  let timer;

  const selectSearch = async (q = "") => {
    if (timer)
      clearTimeout(timer);

    timer = setTimeout(async () => {
      let res = await recommendApi.select({ query: q });
      setSearchValue(res);
    }, 300);

  };

  React.useEffect(() => {
    selectSearch();
  }, []);

  return (
    <Formik
      initialValues={{ value: rowData.recommendation.map(i => i._id) }}
      validationSchema={FormSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem name="recomm" label={translate("property.components.queryform.value", "Value")} required>
              <Select
                showSearch
                mode="tags"
                name="value"
                placeholder={translate("property.components.queryform.choose", "Choose")}
                onSearch={(v) => selectSearch(v)}
                allowClear
              >
                {(searchValue || []).map((item, index) => {
                  return <Option key={index} value={item._id}>{item.name}</Option>;
                })}
              </Select>
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>{translate("system.btn.save", "Save")}</Button>
              <Button className="button" type="default" onClick={onCancel}>{translate("system.btn.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}

    </Formik>
  );
};

export default QueryFormComponents;