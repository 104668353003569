import React from "react";
import { Tag, Button, Avatar, DatePicker, Space, Select, Radio, Input } from "antd";
import { order as orderApi, property as propertyApi, app_user as app_userApi, } from "../../apis";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";
import { dateFormat, renderUser, yen } from "../../utils";
import { useTranslate } from "../../hooks";
import { SearchOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/useFetch";

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [query, setQuery] = React.useState("");
  const { s3host, roles, orderStatuses } = useSelector(
    (state) => state.general
  );
  const { checkPermission } = usePermission();
  const { translate } = useTranslate();
  const [properties] = useFetch(propertyApi.selectAdmin)([]);
  const [filters, setFilters] = React.useState({
    startDate : "",
    query     : "",
    endDate   : "",
    status    : undefined,
    type      : "ADMIN",
    propertyId: undefined,
  });

  const renderStatus = (status) => {
    if (status === "NEW")
      return (
        <Tag color="blue">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "PAID")
      return (
        <Tag color="green">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "CHAT")
      return (
        <Tag color="blue">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "PENDING")
      return (
        <Tag color="orange">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "CANCELLED")
      return (
        <Tag color="red">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "TRANSFER")
      return (
        <Tag color="blue">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
  };
  const columns = useHeader({
    s3host,
    roles,
    renderStatus,
    type    : params.type,
    translate,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "detail": {
          history.push(`/chat/${record.conversation._id}`);
          break;
        }
        default:
      }
    },
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").toDate(),
      endDate  : e && moment(e[1]).endOf("days").toDate(),
    });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      startDate : undefined,
      endDate   : undefined,
      status    : undefined,
      propertyId: undefined,
      query     : "",
      type      : "ADMIN",
    });
  };
  React.useEffect(() => {
    setFilters({
      ...filters,
      propertyId: params.property,
      type      : params.type ? params.type : "ADMIN",
    });
  }, [params]);

  return (
    <PageContainer>
      <PageHeader
        title={translate("order.list.pageheader.title.order", "Order")}
      />
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Button onClick={onClear}>
              {translate("system.filter.btn.clear", "Clear")}
            </Button>,
          ]}
        >
          <Radio.Group
            defaultValue={filters.type}
            buttonStyle="solid"
            onChange={(e) =>
              setFilters((state) => ({ ...state, type: e.target.value }))
            }
          >
            <Radio.Button value="ADMIN">
              {translate("order.list.radio.btn.admin", "Admin")}
            </Radio.Button>
            <Radio.Button value="CUSTOMER">
              {translate("order.list.radio.btn.customer", "Customer")}
            </Radio.Button>
          </Radio.Group>
          <Input
            className="grid-item"
            prefix={<SearchOutlined />}
            value={query}
            placeholder={translate(
              "system.input.search.orderid",
              "Search by order id..."
            )}
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />
          {filters.type === "ADMIN" && <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 300 }}
            value={filters.propertyId}
            onChange={(e) => setFilters({ ...filters, propertyId: e })}
            placeholder={translate("income.list.select.property", "Property ")}
            allowClear
            options={properties.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })}
          ></Select>}
          <Select
            className="grid-item"
            value={filters.status}
            style={{ width: "130px" }}
            onChange={(e) => setFilters({ ...filters, status: e })}
            placeholder={translate("order.list.filter.status", "Order status")}
            allowClear
          >
            {orderStatuses.map((type, index) => (
              <Select.Option key={index} value={type}>
                {translate(`system.orderstatus.${type}`, type)}
              </Select.Option>
            ))}
          </Select>
        </Filter>
        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={orderApi.list}
          rowKey={(record) => record?.rowKey}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate, renderStatus }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("system.useheader.list.property", "Property"),
      render: (row) => {
        return (
          <Space style={{ minWidth: 200 }}>
            <Avatar size={45} shape="square" src={row?.property?.image} />
            <div>
              <b style={{}}>{row?.property?.name}</b> <br />
            </div>
          </Space>
        );
      },
    },
    {
      title : translate("system.useheader.title.owner", "Owner"),
      render: (row) => {
        return <div style={{ minWidth: 150 }}>{renderUser(row.owner)}</div>;
      },
    },
    {
      title : translate("system.useheader.title.tenant", "Tenant"),
      render: (row) => {
        return <div style={{ minWidth: 150 }}>{renderUser(row.tenant)}</div>;
      },
    },
    {
      title : translate("system.useheader.title.orderid", "Order ID"),
      render: (row) => {
        return <Tag>{row?.orderId}</Tag>;
      },
    },
    {
      title : translate("system.useheader.title.amount", "Amount"),
      render: (record) => {
        return `${yen(record.amount)}`;
      },
    },
    {
      title : translate("system.useheader.title.tax", "tax"),
      render: (record) => {
        return `${yen(record.tax)}`;
      },
    },
    {
      title : translate("system.useheader.title.total", "Total"),
      render: (record) => {
        return `${yen(record.total)}`;
      },
    },
    {
      title : translate("order.list.useheader.dates", "Dates"),
      render: (record) => {
        return (
          <Tag>{`${dateFormat(record.startDate)}-${dateFormat(
            record.endDate
          )}`}</Tag>
        );
      },
    },
    {
      title : translate("system.useheader.title.status", "Status"),
      render: (record) => {
        return renderStatus(record.orderStatus);
      },
    },
    {
      title : translate("system.useheader.title.date", "Date"),
      render: (record) => {
        return <div>{moment(record.createdAt).format("YYYY/MM/DD HH:mm")}</div>;
      },
    },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        return (
          <Button onClick={(key) => onAction("detail", record)}>
            {translate("system.list.action.detail", "Detail")}
          </Button>
        );
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
