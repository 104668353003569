import { Row, Col, Tag, Button, Select, Collapse } from "antd";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { income as incomeApi, transaction as transactionApi } from "../../apis";
import { Loader, MyTable } from "../../components";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import { datetimeFormat, renderUser, yen } from "../../utils";
import { Filter } from "../../components/Design";
import moment from "moment";
const { Panel } = Collapse;

const Detail = () => {
  const params = useParams();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [data, setData] = useState();
  const [properties, setProperties] = useState();
  const { translate } = useTranslate();

  const [filters, setFilters] = React.useState({
    paymentIds: [],
    user      : undefined,
    property  : undefined,
  });

  const reload = React.useCallback(async (signal) => {
    let res = await incomeApi.get(params.id, null, { signal });
    setData(res);
    setFilters({
      ...filters,
      user      : res.user,
      paymentIds: res?.incomes?.reduce((acc, itr) =>{
        return [...acc, ...itr.payments];
      }, [])
    });
    setProperties(res.incomes.map(item => item.property));
  }, [params.id]);

  console.log("----------", filters);
  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);

  const column = useHeader({ translate });

  React.useEffect(() => {
    setFilters({
      property  : undefined,
      paymentIds: data?.incomes?.reduce((acc, itr) =>{
        return [...acc, ...itr.payments];
      }, [])
    });
  }, []);

  const onClear = () => {
    setFilters({
      ...filters,
      property: undefined,
    });
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("income.detail.pageheader.title", "Income detail")}
      />
      <ContainerStyled> {!data ? <Loader/> :
        <PageContent>
          {data?.incomes.map(item => {
            return <Collapse style={{ marginBottom: "4px" }} >
              <Panel header={item?.property?.name} extra={`${yen(item.amount)}${translate("income.taxless", "Taxless")}`} >
                {item.dates.map(itr => {
                  return <div style={{ display: "flex", marginBottom: "4px", justifyContent: "space-between", borderBottom: "1px solid #dadada" }}>
                    <div>{moment(itr.date).format("YYYY/MM/DD")}</div>
                    <div>{yen(itr?.price)}</div>
                  </div>;
                })}
              </Panel>
            </Collapse>;
          })}
          <div style={{ margin: "14px 0" }}>
            <div className="ant-page-header-heading-title">{translate("income.detail.title.payments", "Payments")}</div>
          </div>
          <Filter extra={[<Button onClick={onClear}>{translate("system.filter.btn.clear", "Clear")}</Button>]} >
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: 500 }}
              value={filters.property}
              onChange={(e) => setFilters({ ...filters, property: e })}
              placeholder={translate(
                "income.list.select.property",
                "Property "
              )}
              allowClear
              options={properties?.map(item => {
                return {
                  value: item._id,
                  label: item.name
                };
              })}
            >
            </Select>
          </Filter>
          <Table
            ref={myTableRef}
            columns={column}
            filters={filters}
            loadData={transactionApi.list}
            rowKey={(record) => record?.rowKey}/>
        </PageContent>}
      </ContainerStyled>
    </PageContainer>
  );
};

const ContainerStyled = styled.div`
  max-width: 1200px;
`;
const StyledCardRow = styled.div`
  background: #fff;  
  padding: 0;
  padding: 24px;
  border : 1px solid #dadada;
  border-radius: 5px;

  .content-wrapper { 
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    .income {
      display: flex;
      justify-content: space-between;
    }
    .ant-list-item-meta-description {
      padding-bottom: 8px;
    }
  } 
`;
const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
const useHeader = ({ translate }) => {
  return ([{
    title    : "№",
    dataIndex: "rowKey",
    width    : "20px"
  }, {
    title : translate("system.useheader.title.tenant", "Tenant"),
    render: (row) => {
      return renderUser(row.tenant);
    }
  }, {
    title : translate("system.useheader.title.orderid", "Order ID"),
    render: (record) => {
      return <Tag>{record?.order?.orderId}</Tag>;
    }
  }, {
    title : translate("system.useheader.title.usagerange", "Dates"),
    render: (record) => {
      return <Tag>{`${moment(record.order.startDate).format("YYYY/MM/DD")}-${moment(record.order.endDate).format("YYYY/MM/DD")}` }</Tag>;
    }
  }, {
    title : translate("system.useheader.title.amount", "Amount"),
    render: (record) => {
      return `${yen(record.amount)}`;
    }
  }, {
    title : translate("system.useheader.title.tax", "Tax"),
    render: (record) => {
      return `${yen(record.tax)}`;
    }
  }, {
    title : translate("system.useheader.title.total", "Total"),
    render: (record) => {
      return `${yen(record.total)}`;
    }
  }, {
    title : translate("system.useheader.title.status", "Status"),
    render: (record) => {
      return <Tag>{translate(record.paymentStatus, record.paymentStatus)}</Tag>;
    }
  }, {
    title : translate("system.useheader.title.statusdate", "Date"),
    render: (record) => {
      return (<div>{datetimeFormat(record.paymentStatusDate)}</div>);
    }
  }]);
};

export default Detail;