import React from "react";
import { Form, FormItem, Input, Select } from "formik-antd";
import { Button, Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { ImageUpload } from "../../components";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslate } from "../../hooks";



const ProjectForm = ({ action, onSubmit, onCancel }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    name: undefined,
    icon: undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const FormSchema = () => Yup.object({
    name: Yup.string().required(translate("system.regex.required", "required field")),
    icon: Yup.string().required(translate("system.regex.required", "required field"))
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem name="name" label={translate("usetype.form.name", "Name")} required>
              <Input name="name" placeholder={translate("usetype.form.name", "Name")} />
            </FormItem>
            <FormItem name="icon" label={translate("usetype.form.image", "Image")}>
              <ImageUpload action="/api/general/upload" name="icon" />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>{translate("system.btn.save", "Save")}</Button>
              <Button className="button" type="default" onClick={onCancel}>{translate("system.btn.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProjectForm;