import React from "react";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import {
  Button,
  Modal,
  message as notify,
  Space,
  Radio,
  Tag,
} from "antd";
import {
  PlusOutlined,
} from "@ant-design/icons";
import { MyTable } from "../../components";
import { news as newsApi } from "../../apis";
import { useSelector } from "react-redux";
import usePermission from "../../hooks/usePermission";
import styled from "styled-components";
import Form from "./Form";
import { useTranslate } from "../../hooks";

const List = () => {
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const { checkPermission } = usePermission();
  const { isMobile } = useSelector((state) => state.general);
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    isActive: true,
    query   : "",
  });

  const columns = useHeader({
    translate,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "edit": {
          setAction(["update", record]);
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction([]);
  };

  const onSubmit = async (data) => {
    if (action && action[0] === "update") {
      await newsApi.update(action[1]?._id, data);
    } else {
      await newsApi.create(data);
    }

    notify.success(translate("system.request.success", "Success!"));
    myTableRef.current.reload();
    onCancel();
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title={translate("navbar.submenu.news", "News")}
        extra={[
          <Button
            key={0}
            shape={isMobile && "circle"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setAction(["create"])}
          >
            {!isMobile && translate("system.btn.add", "Add")}
          </Button>,
        ]}
      />
      <PageFilter>
        <Space>
          <Radio.Group
            defaultValue={filters.isActive}
            buttonStyle="solid"
            onChange={(e) =>
              setFilters((state) => ({ ...state, isActive: e.target.value }))
            }
          >
            <Radio.Button value={true}>
              {translate("news.active", "Active")}
            </Radio.Button>
            <Radio.Button value={false}>
              {translate("news.disactive", "Disactive")}
            </Radio.Button>
          </Radio.Group>
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          bordered={!isMobile}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={newsApi.list}
          rowKey={(record) => record.id}
        />
      </PageContent>

      <Modal
        title={
          action && action[0] === "create"
            ? translate("system.pagecontainer.model.title.add", "Add")
            : translate("system.pagecontainer.model.title.edit", "Edit")
        }
        width="1000"
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}
      >
        {action && action[0] && (
          <Form onCancel={onCancel} action={action} onSubmit={onSubmit} />
        )}
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate }) => {
  return [
    {
      title : "№",
      width : 20,
      render: (r, i, count) => {
        return count + 1;
      },
    },
    {
      title : translate("new.title", "Title"),
      render: (record) => {
        if (record.isCrucial) {
          return (
            <div>
              {record.title}{" "}
              <Tag color="orange" >{translate("news.crucial", "Important")}</Tag>
            </div>
          );
        }
        return `${record.title}`;
      },
    },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        return (
          <Button onClick={() => onAction("edit", record)} > {translate("system.action.edit", "Edit")}</Button>
        );
      },
    },
  ];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;

export default List;
