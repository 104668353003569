/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable key-spacing */
import {
  Avatar,
  Col,
  Row,
  Space,
  Tag,
  Modal,
  message,
  Button,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { message as messageApi } from "../../apis";
import { Loader } from "../../components";
import { PageContent } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import { postCode, renderUser, truncate, yen } from "../../utils";
import Chat from "./components/Chat";
import { HomeOutlined } from "@ant-design/icons";
import {
  CalendarIcon,
  HashtagIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { general } from "../../apis";
import { order as orderApi } from "../../apis";
import dayjs from "dayjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Divider, NumberInput } from "@mantine/core";
import NewCalendarForm from "../../components/Calendar/NewCalendarForm";
import PreviewOrder from "./components/Preview";

const Details = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { translate } = useTranslate();
  const [data, setData] = React.useState();
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = React.useState(false);
  const [verifyLoading, setVerifyLoading] = React.useState(false);
  const { webhost } = useSelector((state) => state.general);
  const { reload } = useSelector((state) => state.socket);
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const [prices, setPrices] = React.useState({});
  const [selectedDateRange, setSelectedDateRange] = React.useState([]);
  const [gahaiError, setGahaiError] = React.useState([false, false]);
  const [previewData, setPreviewData] = React.useState({});
  const [previewModal, setPreviewModal] = React.useState(false);
  const [disabledDates, setDisabledDates] = React.useState([]);

  const onCancel = async (orderStatus) => {
    try {
      Modal.confirm({
        title: translate("order.details.oncancel.cancel", "Cancel"),
        icon: <ExclamationCircleOutlined />,
        content: translate(
          orderStatus === "TRANSFER"
            ? "order.details.oncancel.content.transferring"
            : "order.details.oncancel.content.perform.cancel.action",
          "You are about to perform a cancel action."
        ),
        okText: translate("order.details.oncancel.confirm", "Confirm"),
        cancelText: translate("order.details.oncancel.back", "Cancel"),
        className: "btn-custom-class",
        onOk: async () => {
          try {
            setCancelLoading(true);
            await orderApi.cancel({ orderId: data.order._id });
            message.success(translate("system.request.success", "Success!"));
            window.location.reload();
            setCancelLoading(false);
          } catch (error) {
            message.error(
              error.message || translate("system.request.failed", "Failed!")
            );
            console.log(error);
            setCancelLoading(false);
          }
        },
      });
    } catch (error) {
      message.error(
        error?.message || translate("system.request.failed", "Failed!")
      );
      setCancelLoading(false);
    }
  };

  const update = async () => {
    setVerifyLoading(true);
    try {
      await orderApi.verify({
        orderId: data?.order?._id,
        startDate: selectedDateRange[0].startDate,
        endDate: selectedDateRange[0].endDate,
        weekPrice: prices.weekPrice,
        weekendPrice: prices.weekendPrice,
        specialPrice: prices.specialPrice,
        useSpecialPrice: prices.useSpecialPrice,
        useMultiPrice: prices.useMultiPrice,
      });
      message.success(
        translate("order.details.message.success", "Bulk successfully updated")
      );
      setCalendarOpen(false);
      setVerifyLoading(false);
      setPreviewModal(false);
      window.location.reload();
    } catch (error) {
      message.error(
        error?.message || translate("system.request.failed", "Failed!")
      );
      setVerifyLoading(false);
    }
  };

  const preview = async () => {
    setVerifyLoading(true);

    try {
      const res = await orderApi.preview({
        orderId: data?.order?._id,
        startDate: selectedDateRange[0].startDate,
        endDate: selectedDateRange[0].endDate,
        weekPrice: prices.weekPrice,
        weekendPrice: prices.weekendPrice,
        specialPrice: prices.specialPrice,
        useSpecialPrice: prices.useSpecialPrice,
        useMultiPrice: prices.useMultiPrice,
      });
      setPreviewData(res);
      setPreviewModal(true);
    } catch (error) {
      message.error(
        error?.message || translate("system.request.failed", "Failed!")
      );
      setVerifyLoading(false);
    }
  };

  const getDates = (startDate, endDate) => {
    const daysbetween =
      Math.floor((Date.parse(startDate) - Date.parse(endDate)) / 86400000) - 1;

    const dates = Array(-daysbetween || 0).fill("");
    return dates?.map((date, index) => {
      if (index === 0) {
        return startDate;
      }
      if (index === -daysbetween) {
        return endDate;
      }
      return moment(startDate, "YYYY-MM-DD").add(index, "days");
    });
  };

  const deleteItem = (indexs, item) => {
    let array = selectedDateRange;
    array = array.filter(function (item, index) {
      return index !== indexs;
    });
    setSelectedDateRange(array);
    const res = getDates(item.startDate, item.endDate).map((item) =>
      moment(item)?.format("YYYY/MM/DD")
    );

    setDisabledDates(disabledDates.filter((val) => !res.includes(val)));
  };

  useEffect(() => {
    if (params.id) {
      (async () => {
        setLoading(true);

        const res = await messageApi.get(params.id);
        setData(res);
        setPrices({
          weekPrice: res?.order?.weekPrice,
          weekendPrice: res?.order?.weekendPrice,
          specialPrice: res?.order?.specialPrice,
          useSpecialPrice: res.order?.useSpecialPrice,
          useMultiPrice: res.order?.useMultiPrice,
        });
        setDisabledDates(res?.property?.unavailableDates);

        setLoading(false);
      })();
    }
    const asyncFn = async () => {
      try {
        await Promise.all([general.init()(dispatch)]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    asyncFn();
  }, [params.id]);

  useEffect(() => {
    if (params.id === reload) {
      (async () => {
        setLoading(true);
        setData();
        const res = await messageApi.get(params.id);
        setData(res);
        setPrices({
          weekPrice: res?.order?.weekPrice,
          weekendPrice: res?.order?.weekendPrice,
          specialPrice: res?.order?.specialPrice,
          useSpecialPrice: res.order?.useSpecialPrice,
          useMultiPrice: res.order?.useMultiPrice,
        });
        setDisabledDates(res?.property?.unavailableDates);

        setLoading(false);
      })();
    }
  }, [reload]);

  if (loading && !data) return <Loader />;

  return (
    <div>
      <PageContent>
        <ChatContainer>
          <ChatStyled>
            <div className="header">
              {renderUser(data?.tenant)}
              {renderUser(data?.owner)}
            </div>
            <div className="chat-body">
              <Chat data={data} />
            </div>
          </ChatStyled>
          <InfoStyled>
            <div className="header">
              <Tag color="orange">
                {translate("app.order.shortid", "Order ID: ")}
                {data?.order?.orderId}
              </Tag>
              <div className="">
                <Button
                  danger
                  onClick={() => onCancel(data?.order?.orderStatus)}
                  loading={cancelLoading}
                  disabled={data?.orderStatus === "CANCELLED"}
                >
                  {translate("order.details.btn.cancel", "Cancel")}
                </Button>
                {"  "}
                <Button
                  type="primary"
                  onClick={() => {
                    setCalendarOpen(true);
                  }}
                  disabled={data?.orderStatus === "PAID"}
                >
                  <a
                    onClick={() => {
                      setCalendarOpen(true);
                      setSelectedDateRange([
                        {
                          startDate: data?.order?.startDate,
                          endDate: data?.order?.endDate,
                        },
                      ]);
                    }}
                  >
                    <div className="font-bold">
                      {translate("order.details.btn.verify", "Verify")}
                    </div>
                  </a>
                </Button>
              </div>
            </div>

            <div className="info-content">
              <div className="info-title">
                {translate("system.details.property", "Property")}
              </div>
              <div className="project-list">
                <ProjectCard>
                  <div className="item">
                    <a
                      onClick={() =>
                        window.open(`${webhost}/preview/${data?.property?._id}`)
                      }
                      className="avatar"
                    >
                      <Avatar
                        src={data?.property?.image}
                        shape="square"
                        className="img"
                      />
                      <div className="button">
                        {translate("system.list.action.detail", "Detail")}
                      </div>
                    </a>
                    <div className="text-container">
                      {!data?.property?.negotiable ? (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.125rem",
                                color: "#ff5722",
                              }}
                            >
                              ¥ {data?.property?.weekTax?.toLocaleString()}
                            </div>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.125rem",
                                color: "#ff5722",
                              }}
                            >
                              /
                            </div>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.125rem",
                                color: "#ff5722",
                              }}
                            >
                              {translate("space.detail.week", "Week")}
                            </div>
                          </div>
                          {data?.property?.useMultiPrice && (
                            <div
                              style={{
                                display: "flex",
                                gap: "0.5rem",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.125rem",
                                  color: "#ff5722",
                                }}
                              >
                                ¥ {data?.property?.weekendTax?.toLocaleString()}
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.125rem",
                                  color: "#ff5722",
                                }}
                              >
                                /
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.125rem",
                                  color: "#ff5722",
                                }}
                              >
                                {translate("space.detail.weekend", "Weekend")}
                              </div>
                            </div>
                          )}
                          {data?.property?.useSpecialPrice && (
                            <div
                              style={{
                                display: "flex",
                                gap: "0.5rem",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.125rem",
                                  color: "#ff5722",
                                }}
                              >
                                ¥ {data?.property?.specialTax?.toLocaleString()}
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.125rem",
                                  color: "#ff5722",
                                }}
                              >
                                /
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1.125rem",
                                  color: "#ff5722",
                                }}
                              >
                                {translate("space.detail.special", "Special")}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.125rem",
                              color: "#ff5722",
                            }}
                          >
                            {translate(
                              "app.property.detail.negotiable",
                              "Negotiable"
                            )}
                          </div>
                        </div>
                      )}

                      <h3 className="title">
                        {truncate(data?.property?.title, 30)}
                      </h3>
                      <div>{postCode(data?.property?.address?.postCode)}</div>
                      <div>
                        {data?.property?.address?.city}{" "}
                        {data?.property?.address?.address}{" "}
                        {data?.property?.address?.buildingName}{" "}
                      </div>
                      <div className="footer">
                        <div>
                          <HashtagIcon
                            style={{ height: "16px", color: "#7d1bf5" }}
                          ></HashtagIcon>
                          {data?.property?.type?.name}
                        </div>
                        <div>
                          <HomeOutlined style={{ color: "#7d1bf5" }} />{" "}
                          {data?.property?.space}㎡
                        </div>
                      </div>
                    </div>
                  </div>
                </ProjectCard>
              </div>
              <div className="chatDetail-container">
                <h3 className="chatDetail-dates">
                  {translate(
                    "app.chat.detail.desiredPeriod",
                    "Desired usage period"
                  )}
                  <Tag color="#f97318">
                    {translate(
                      `system.orderstatus.${data?.order?.orderStatus}`,
                      data?.order?.orderStatus
                    )}
                  </Tag>
                </h3>
                <div>
                  <div className="chatDetail-orderitems">
                    <CalendarIcon
                      style={{ height: "20px", color: "#f97318" }}
                    />
                    <div className="chatDetail-date">
                      {data?.order?.startDate === data?.order?.endDate ? (
                        <div>
                          {moment(data?.order?.startDate).format("YYYY/MM/DD")}
                        </div>
                      ) : (
                        <div>
                          {moment(data?.order?.startDate).format("YYYY/MM/DD")}{" "}
                          - {moment(data?.order?.endDate).format("YYYY/MM/DD")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div />
                </div>
              </div>
              {/* <div className="info-title">
                {translate("system.details.order.info", "Order info")}
              </div> */}
              <div className="chatDetail-container">
                <h3>
                  {translate("app.chat.detail.aboutPlanning", "About planning")}
                </h3>
                <div className="chatDetail-titles">
                  {translate("app.chat.detail.usageDetails", "Usage details")}
                </div>
                <div className="">{data?.order?.useType}</div>
                <div className="chatDetail-titles">
                  {translate("app.chat.detail.projectName", "Project name")}
                </div>
                <div className="">{data?.order?.businessName}</div>
                <div className="chatDetail-titles">
                  {translate("app.chat.detail.planContents", "Plan contents")}
                </div>
                <div className="">{data?.order?.businessDesc}</div>
                <div className="chatDetail-titles">
                  {translate(
                    "app.chat.detail.eating",
                    "Eating and drinking / tasting"
                  )}
                </div>
                <div className="">
                  {data?.order?.isProvisional
                    ? translate("app.chat.detail.provided", "Provided")
                    : translate("app.chat.detail.no", "No")}
                </div>
                <div className="chatDetail-titles">
                  {translate("app.chat.detail.inquiry", "Inquiry")}
                </div>
                <div className="">{data?.order?.inquiry}</div>
              </div>

              <div className="chatDetail-cancelpolicy">
                <div className="desc">
                  {translate(
                    "space.detail.payment.policy.body",
                    "If you cancel after the reservation is confirmed, the following cancellation fee will be charged according to the number of days until the start date of use."
                  )}
                </div>
                <div className="policy">
                  <div>
                    {" "}
                    {translate(
                      "space.detail.policy1",
                      "From 61 to 80 days before the start date"
                    )}
                  </div>
                  <div className="percent">
                    {translate(
                      "space.detail.policy.percents",
                      "% of usage fee"
                    )}
                    <div style={{ fontWeight: 600 }}>
                      {data?.property?.cancelPolicy?.pcy1}%
                    </div>
                  </div>
                </div>
                <div className="policy">
                  <div>
                    {translate(
                      "space.detail.policy2",
                      "From 31 to 60 days before the start date"
                    )}
                  </div>
                  <div className="percent">
                    {translate(
                      "space.detail.policy.percents",
                      "% of usage fee"
                    )}
                    <div style={{ fontWeight: 600 }}>
                      {data?.property?.cancelPolicy?.pcy2}%
                    </div>
                  </div>
                </div>
                <div className="policy">
                  <div>
                    {translate(
                      "space.detail.policy3",
                      "Up to 15 to 30 days before the start date"
                    )}
                  </div>
                  <div className="percent">
                    {translate(
                      "space.detail.policy.percents",
                      "% of usage fee"
                    )}
                    <div style={{ fontWeight: 600 }}>
                      {data?.property?.cancelPolicy?.pcy3}%
                    </div>
                  </div>
                </div>
                <div className="policy">
                  <div>
                    {translate(
                      "space.detail.policy4",
                      "From 0 to 14 days before the start date"
                    )}
                  </div>
                  <div className="percent">
                    {translate(
                      "space.detail.policy.percents",
                      "% of usage fee"
                    )}
                    <div style={{ fontWeight: 600 }}>
                      {data?.property?.cancelPolicy?.pcy4}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </InfoStyled>

          <StyledModal>
            <div className="container">
              <Modal
                centered
                width={700}
                title={translate(
                  "order.details.model.dates.verify",
                  "Edit picked dates"
                )}
                className={{ title: "font-semibold" }}
                open={calendarOpen}
                onCancel={() => setCalendarOpen(false)}
                onOk={() =>
                  selectedDateRange?.length < 1
                    ? message.error(
                        translate(
                          "system.regex.required",
                          "system.regex.required"
                        )
                      )
                    : preview()
                }
                loading={verifyLoading}
              >
                <WrapperContent>
                  <Row gutter={[16, 16]}>
                    <Col className="firstCalendarItem" span={14}>
                      <NewCalendarForm
                        minRange={data?.property?.minimumDay}
                        minRequired={data?.property?.minRequired}
                        gahaiError={setGahaiError}
                        setDisable={setDisabledDates}
                        setRange={setSelectedDateRange}
                        disabledDates={[
                          ...(disabledDates || []),
                          ...getDates(
                            selectedDateRange[0]?.startDate,
                            selectedDateRange[0]?.endDate
                          ).map((c) => moment(c).format("YYYY/MM/DD")),
                        ]}
                        isBalai={true}
                      />
                      <div className="gahaiError">
                        {gahaiError[0] ? (
                          <div className="gahaiError1">
                            利用できない日が含まれています。
                          </div>
                        ) : null}
                        <>
                          {gahaiError[1] ? (
                            data?.property?.minRequired ? (
                              <div className="gahaiError2">
                                {translate(
                                  "space.detail.minrequired.alert",
                                  "The days you choose is at least"
                                )}
                                {data?.property?.minimumDay}
                                {translate(
                                  "space.detail.minrequired.alert2",
                                  "The days you choose is at least"
                                )}
                              </div>
                            ) : (
                              <div className="gahaiError3">
                                {translate(
                                  "space.detail.minrequired.alert",
                                  " The days you choose is at least"
                                )}
                                {data?.property?.minimumDay}
                                {translate(
                                  "space.detail.minrequired.alert2",
                                  "The days you choose is at least"
                                )}
                              </div>
                            )
                          ) : null}
                        </>
                      </div>
                    </Col>
                    <Col className="dates" span={10}>
                      {selectedDateRange?.length > 0 ? (
                        selectedDateRange?.map((item, index) => (
                          <div key={index} className="secondDateItem">
                            <CalendarIcon
                              style={{
                                width: "24px",
                                height: "24px",
                                color: "#f2670a",
                              }}
                            />
                            <div className="">
                              <div className="">
                                {dayjs(item.startDate).format("YYYY/MM/DD")} -{" "}
                                {dayjs(item.endDate).format("YYYY/MM/DD")}
                              </div>
                            </div>
                            <div>
                              <a
                                onClick={() => deleteItem(index, item)}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <XMarkIcon
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </a>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div></div>
                      )}
                      <Divider
                        label={
                          <text style={{ fontSize: 14 }}>
                            {translate(
                              prices.useMultiPrice
                                ? "property.form.weekprice.taxless"
                                : "property.form.daily.change.tax",
                              "Week price"
                            )}
                          </text>
                        }
                      />
                      {data?.order?.negotiable && (
                        <Checkbox
                          onChange={(e) => {
                            setPrices({
                              ...prices,
                              weekendPrice: undefined,
                              weekPrice: undefined,
                              specialPrice: undefined,
                              useMultiPrice: e.target.checked,
                              useSpecialPrice: false,
                            });
                          }}
                        >
                          {translate(
                            "property.form.usemultiprice",
                            "Multi price"
                          )}
                        </Checkbox>
                      )}
                      <NumberInput
                        placeholder={translate(
                          prices.useMultiPrice
                            ? "property.form.weekprice.taxless"
                            : "property.form.daily.change.tax",
                          "Week price"
                        )}
                        icon={<>￥</>}
                        error={prices.weekPrice < 1}
                        hideControls
                        value={prices.weekPrice}
                        onChange={(e) =>
                          setPrices({
                            ...prices,
                            weekPrice: e,
                          })
                        }
                      />
                      {prices.useMultiPrice && (
                        <div>
                          <Divider
                            label={
                              <text style={{ fontSize: 14 }}>
                                {translate(
                                  "property.form.weekendprice.taxless",
                                  "Weekend price"
                                )}
                              </text>
                            }
                          />
                          <NumberInput
                            placeholder={translate(
                              "property.form.weekendprice.taxless",
                              "Weekend price"
                            )}
                            icon={<>￥</>}
                            error={prices.weekendPrice < 1}
                            hideControls
                            value={prices.weekendPrice}
                            onChange={(e) =>
                              setPrices({
                                ...prices,
                                weekendPrice: e,
                              })
                            }
                          />
                        </div>
                      )}
                      {data?.order?.negotiable && (
                        <Checkbox
                          onChange={(e) => {
                            setPrices({
                              ...prices,
                              weekendPrice: undefined,
                              weekPrice: undefined,
                              specialPrice: undefined,
                              useSpecialPrice: e.target.checked,
                            });
                          }}
                        >
                          {translate(
                            "property.form.usespecialprice",
                            "Use special price"
                          )}
                        </Checkbox>
                      )}
                      {prices.useSpecialPrice && (
                        <div>
                          <Divider
                            label={
                              <text style={{ fontSize: 14 }}>
                                {translate(
                                  "property.form.specialprice.taxless",
                                  "Special price"
                                )}
                              </text>
                            }
                          />
                          <NumberInput
                            placeholder={translate(
                              "property.form.specialprice.taxless",
                              "Special price"
                            )}
                            icon={<>￥</>}
                            error={prices.specialPrice < 1}
                            hideControls
                            value={prices.specialPrice}
                            onChange={(e) =>
                              setPrices({
                                ...prices,
                                specialPrice: e,
                              })
                            }
                          />
                        </div>
                      )}
                      <div
                        style={{
                          border: "1px solid orange",
                          borderRadius: 5,
                          marginTop: 5,
                          padding: 5,
                          textAlign: "center",
                          color: "orange",
                        }}
                        className="gahaiError3"
                      >
                        {translate(
                          "admin.order.verify.total.alert",
                          "You can change order's total amount. But you have to tell your customer for misunderstanding. "
                        )}
                      </div>
                    </Col>
                  </Row>
                </WrapperContent>
              </Modal>
            </div>
          </StyledModal>
          <Modal
            centered
            width={700}
            title={translate(
              "order.details.model.dates.preview",
              "Order preview"
            )}
            className={{ title: "font-semibold" }}
            open={previewModal}
            onCancel={() => setPreviewModal(false)}
            onOk={() => update()}
            loading={verifyLoading}
          >
            <PreviewOrder data={previewData} />
          </Modal>
        </ChatContainer>
      </PageContent>
    </div>
  );
};

const StyledModal = styled.div`
  .container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
  }
  .firstCalendarItem {
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rebeccapurple !important;
    padding: 24px;
  }
  .secondDateItem {
    padding: 20px;
    width: 35px;
    background: rebeccapurple !important;
  }
`;

const WrapperContent = styled.div`
  .dates {
    padding: 0px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .secondDateItem {
      border-radius: 4px 4px;
      border: 1px solid #e4e6ea;
      padding: 5px 10px;
      display: flex;
      gap: 8px;
      align-items: center;
      color: #8d8d8d;
      font-weight: 500;
    }
  }
  .gahaiError {
    margin-top: 15px;

    .gahaiError1 {
      border: 1px solid red;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      color: red;
    }
    .gahaiError2 {
      border: 1px solid red;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      color: red;
    }
    .gahaiError3 {
      border: 1px solid orange;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      color: orange;
    }
  }
`;

const ProjectCard = styled.div`
  width: 100%;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-container {
      border: 1px solid #e5e7eb;
      padding: 5px;
      border-radius: 2px;
      width: 100%;

      .footer {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        div {
          font-size: 16px;
        }
      }
    }

    .title {
      font-weight: 600;
      color: black;
    }
    .avatar {
      position: relative;
      cursor: pointer;
      border: 1px solid #e5e7eb;
      padding: 5px;
      border-radius: 2px;
      object-fit: contain;
      width: 100%;
      height: 300px;

      .img {
        height: 100%;
        width: 100%;
      }

      .button {
        position: absolute;
        height: 40px;
        border-radius: 5px;
        width: 80%;
        background-color: #f1f0f5;
        color: black;
        font-size: 18px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        bottom: 20px;
        left: 10%;
        opacity: 80%;

        :hover {
          opacity: 100%;
        }
      }
    }
  }
`;

const ChatStyled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .chat-body {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 89vh;
    height: 100%;
    width: 100%;
  }
`;

const InfoStyled = styled.div`
  max-width: 500px;
  width: 100%;
  border: 1px solid #dadada;
  border-top: none;
  border-bottom: none;
  overflow-y: scroll;
  height: 94vh;
  .item {
    display: flex;
    gap: 10px;
  }
  .info-content {
    padding: 14px;
    max-height: 90vh;
    height: 100%;

    .info-title {
      font-size: 18px;
      font-weight: 500;
      padding: 8px 0;
    }
  }
  .chatDetail-orderitems {
    border: 1px solid #e5e7eb;
    padding: 10px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    /* justify-content: space-around; */
    color: black;
    border-radius: 2px;
    margin-top: 10px;

    .chatDetail-date {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .chatDetail-container {
    border: 1px solid #e5e7eb;
    padding: 5px;
    border-radius: 2px;
    margin-top: 10px;
    max-width: 500px;
    width: 100%;
    .chatDetail-dates {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      color: black;
    }
    .chatDetail-titles {
      font-weight: 600;
      color: black;
      margin-top: 10px;
    }
  }
  .chatDetail-cancelpolicy {
    border: 1px solid #e5e7eb;
    padding: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 500px;
    width: 100%;
    .policy {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .desc {
      font-weight: 400;
    }
    .percent {
      display: flex;
    }
  }
`;
const ChatContainer = styled.div`
  background: #fff;
  display: flex;
  max-width: 1500px;
  max-height: calc(100vh - 120px);
  .header {
    border-bottom: 1px solid #dadada;
    padding: 10px 14px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export default Details;
