import request from "../utils/request";

export const list = (data) => request.get("/api/property", data);
export const selectAdmin = () => request.get("/api/property/select", { type: "ADMIN" });
export const selectOwner = () => request.get("/api/property/select", { type: "OWNER" });
export const create = (data) => request.post("/api/property", data);
export const preview = (data) => request.put("/api/preview", data);
export const activate = (id) => request.put(`/api/property/${id}/activate`);
export const deactivate = (id) => request.put(`/api/property/${id}/deactivate`);
export const recommend = (id, data) =>
  request.post(`/api/property/${id}/recommend`, data);
export const update = (id, data) => request.put(`/api/property/${id}`, data);
export const remove = (id) => request.del(`/api/property/${id}`);
export const get = (id) => request.get(`/api/property/${id}`);
export const calendar = (data) => request.post("/api/property/calendar", data);
export const calendarGet = (data) =>
  request.get("/api/property/calendar", data);
export const setManager = (id, data) => request.post(`/api/property/${id}/manager`, data);
