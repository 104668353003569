import React from "react";
import { Form, FormItem, Input, Select } from "formik-antd";
import { Button, Form as AntForm, message } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { app_user as appuserApi } from "../../../apis";
import { Formik } from "formik";
import * as Yup from "yup";
import { LockOutlined } from "@ant-design/icons";
import { useTranslate } from "../../../hooks";

const PasswordChangeForm = ({ action, onCancel, rowData, setAction }) => {
  const { translate } = useTranslate();


  const FormSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(translate("appuser.components.required.fillout", "Please fill out this field!"))
    // .matches(uppercaseRegex, "Том үсэг оруулна уу!")
      // .matches(lowercaseRegex, "Жижиг үсэг оруулна уу!")
      // .matches(numericRegex, "Та тоо оруулна уу!")
      .min(8, translate("appuser.components.min", "Your password must be at least 8 characters.")),
    verify_password: Yup.string()
      .oneOf([Yup.ref("newPassword")], translate("appuser.components.oneof", "The password you entered is incorrect. Please try again."))
      .required(translate("appuser.components.required.reenter", "Please re-enter your password."))
  });

  const onSubmit = async (data) => {
    try {
      await appuserApi.password(rowData._id, {
        ...data,
      });
      message.success(translate("system.request.success", "Success!"));
      setAction([]);
    } catch (err) {
      message.error(err.message || translate("system.request.failed", "Failed!"));
    }

  };

  return (
    <Formik
      initialValues={{
        newPassword    : undefined,
        verify_password: undefined
      }}
      validationSchema={FormSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem name="newPassword" label={translate("appuser.components.form.newpassword", "New Password")} required>
              <Input.Password
                prefix={<span />}
                name="newPassword"
                placeholder={translate("appuser.components.form.newpassword", "New Password")}
              />
            </FormItem>
            <FormItem
              name="verify_password"
              label={translate("appuser.components.form.confirm.newpassword", "Confirm New Password")}
              required
            >
              <Input.Password
                prefix={<span />}
                name="verify_password"
                placeholder={translate("appuser.components.form.confirm.newpassword", "Confirm New Password")}
              />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>{translate("system.btn.save", "Save")}</Button>
              <Button className="button" type="default" onClick={onCancel}>{translate("system.btn.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PasswordChangeForm;