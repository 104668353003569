import React from "react";
import { Tag, Button, Avatar, DatePicker, Space, Modal, Select } from "antd";
import { exports as exportsApi } from "../../apis";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";
import { datetimeFormat, renderUser, yen } from "../../utils";
import {
  ExclamationCircleOutlined,
  UserOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { useTranslate } from "../../hooks";

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [query, setQuery] = React.useState("");
  const [action, setAction] = React.useState([]);
  const { checkPermission } = usePermission();
  const [filters, setFilters] = React.useState({
    date  : "",
    status: "",
  });

  const columns = useHeader({
    type    : params.type,
    translate,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "download": {
          window.open(`${record?.url}`);
          break;
        }
        default:
      }
    },
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    setFilters({
      date  : undefined,
      status: undefined,
    });
  }, [params.type]);

  const dateFilter = (e) => {
    console.log(e);
    setFilters({ ...filters, date: e && moment(e).format("YYYY/MM/DD") });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      date  : undefined,
      status: undefined,
    });
  };

  return (
    <PageContainer>
      <PageHeader title={translate("navbar.menu.exports", "Exports")} />
      <PageContent>
        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={exportsApi.list}
          rowKey={(record) => record?.rowKey}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("income.list.useheader.title.user", "User"),
      render: (row) => {
        return renderUser(row.user);
      },
    },
    {
      title : translate("exports.createddate", "Create at"),
      render: (row) => {
        return moment(row.createdAt).format("YYYY/MM/DD HH:mm");
      },
    },
    {
      title : translate("exports.status", "Status"),
      render: (row) => {
        return `${row.exportStatus}`;
      },
    },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        return (
          <Button onClick={(key) => onAction(key, record)}>
            {translate("system.action.download", "Download")}
          </Button>
        );
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
