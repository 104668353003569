import React, { useTransition } from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslate } from "../../hooks";

const NotfoundPage = () => {
  const history = useHistory();
  const { translate } = useTranslate();
  return (
    <Result
      status="404"
      title="404"
      subTitle={translate("notfound", "Sorry! Request not found.")}
      extra={<Button type="primary" onClick={() => history.push("/")}>{translate("notfound.btn.home.page", "Home page")}</Button>}
    />
  );
};

export default NotfoundPage;