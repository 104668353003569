/* eslint-disable key-spacing */
/* eslint-disable no-nested-ternary */

/* eslint-disable no-promise-executor-return */
import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Row, Spin, Modal } from "antd";
import styled from "styled-components";
import { datetimeFormat, yen } from "../../../utils";
import { SendOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { socket } from "../../../components/SocketClient";
import { message as messageApi } from "../../../apis";
import dayjs from "dayjs";
import { useTranslate } from "../../../hooks";
import Print from "../../Chat/components/Print";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const Chat = ({ data }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { chats, scrollOn, reload } = useSelector((state) => state.socket);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [chatLength, setChatLength] = useState(0);
  const viewport = useRef();
  const [print, setPrint] = useState([false]);

  const [filters, setFilters] = useState({
    offset: {
      page: 1,
      limit: 10,
    },
    filter: {
      id: params?.id,
    },
  });

  const scrollToBottom = (smooth) =>
    viewport?.current?.scrollTo({
      top: viewport?.current.scrollHeight,
      behavior: smooth ? "smooth" : "auto",
    });

  const reloadChatInit = async () => {
    dispatch({
      type: "socket.chats.init",
      payload: [],
    });
    if (params.id)
      await socket.emit("action", {
        type: "owner.seen",
        payload: params.id,
      });
  };

  const loaData = async () => {
    setLoading(true);
    setTimeout(async () => {
      const res = await messageApi.chat(filters);

      dispatch({
        type: "socket.chats",
        payload: [...chats, ...res.rows],
      });
      setChatLength(res?.count || 0);
      setLoading(false);
      await viewport?.current?.scrollTo({
        top: 20,
        behavior: "smooth",
      });
    }, 1000);
  };

  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  const onScroll = async (e) => {
    if (e) {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (chats.length < chatLength) {
        if (scrollTop === 0) {
          console.log("is top");
          setLoading(true);
          await delay(30);
          setLoading(false);
          setFilters({
            ...filters,
            offset: { ...filters.offset, page: filters.offset.page + 1 },
          });
        }
      }
    }
  };

  useEffect(() => {
    loaData();
  }, [filters]);

  useEffect(() => {
    if (scrollOn) scrollToBottom();
    dispatch({
      type: "scroll.off",
    });
  }, [scrollOn]);

  useEffect(() => {
    console.log("viewport: ", viewport.current.scrollHeight);
  }, [viewport]);

  React.useEffect(() => {
    if (reload === params.id) {
      reloadChatInit();
      loaData();

      dispatch({
        type: "socket.chat.reload",
        payload: null,
      });
    }
  }, [reload]);

  useEffect(() => {
    reloadChatInit();
  }, []);

  const paymentStatus = (status) => {
    if (status === "PENDING" || status === "TRANSFER")
      return (
        <div
          style={{
            padding: 4,
            textAlign: "center",
            backgroundColor: "#2196f3",
            color: "white",
            borderRadius: 5,
          }}
        >
          {translate(status, status)}
        </div>
      );
    if (status === "PAID")
      return (
        <div
          style={{
            padding: 4,
            textAlign: "center",
            backgroundColor: "#4caf50",
            color: "white",
            borderRadius: 5,
          }}
        >
          {translate(status, status)}
        </div>
      );
    if (status === "CANCELLED")
      return (
        <div
          style={{
            padding: 4,
            textAlign: "center",
            backgroundColor: "#f44336",
            color: "white",
            borderRadius: 5,
          }}
        >
          {translate(status, status)}
        </div>
      );
  };
  const Payment = ({ item }) => {
    return (
      <PaymentStyled>
        <div className="container">
          <table>
            <tr>
              <td>
                <div>{translate("chat.amount", "Amount")}:</div>
              </td>
              <td>
                <div>{yen(item?.payment?.amount)}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>{translate("chat.tax", "Tax")}:</div>
              </td>
              <td>
                <div>{yen(item?.payment?.tax)}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>{translate("chat.total.amount", "Total amount")}:</div>
              </td>
              <td>
                <div>{yen(item.payment.tax + item.payment.amount || 0)}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>{translate("chat.total.duration", "Duration")}:</div>
              </td>
              <td>
                <div>
                  {dayjs(item?.payment?.startDate).format("YYYY/MM/DD")}-
                  {dayjs(item?.payment?.endDate).format("YYYY/MM/DD")}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  {translate("app.chat.chat.expireDate", "Payment expire date")}
                  :
                </div>
              </td>
              <td>
                <div className="text-[13px] max-w-md break-words text-end">
                  {dayjs.utc(item?.payment?.expireDate).format("YYYY/MM/DD")}
                </div>
              </td>
            </tr>
          </table>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div >
              {translate("chat.payment.alert.pay", "Pay the before tommorow!")}
            </div>
            {item.payment.paymentStatus !== "CANCELLED" && <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                onClick={() => setPrint([true, item])}
                style={{ color: "#e05c04" }}
                type="text"
              >
                {translate("app.chat.chat.estimation", "See estimation")}
              </Button>
            </div>}
          </div>
          <div style={{ padding: 10 }}>
            {paymentStatus(item?.payment?.paymentStatus)}
          </div>
        </div>
        <div className="chat-date">
          {dayjs(item.chatSendDate).format("YYYY/MM/DD HH:mm")}
        </div>
        <PrintModal
          centered
          title={translate("chat.payment.print", "Print")}
          onCancel={() => setPrint([false])}
          visible={print[0]}
          footer={false}
        >
          <Print data={print[1] || null} />
        </PrintModal>
      </PaymentStyled>
    );
  };

  const Order = ({ item }) => {
    return (
      <OrderStyled>
        <div className="container">
          <div className="title">
            {translate("chat.about.planning", "About planning")}
          </div>

          <div className="value">{item?.order?.useType}</div>
          <div className="label">
            {translate("chat.project.name", "Project name")}
          </div>
          <div>{item?.order?.businessName}</div>
          <div className="label">
            {translate("chat.plan.contents", "Plan contents")}
          </div>
          <div>{item?.order?.businessDesc}</div>
          <div className="label">
            {translate(
              "chat.eating.and.drinking.tasting",
              "Eating and drinking / tasting"
            )}
          </div>
          <div>
            {item?.order?.isProvisional
              ? translate("app.chat.detail.provided", "Provided")
              : translate("app.chat.detail.no", "No")}
          </div>
          <div className="label">{translate("chat.inquiry", "Inquiry")}</div>
          <div>{item?.order?.inquiry}</div>
        </div>
        <div className="chat-date">
          {dayjs(item.chatSendDate).format("YYYY/MM/DD HH:mm")}
        </div>
      </OrderStyled>
    );
  };

  return (
    <Container>
      <ScrollStyled ref={viewport} onScroll={onScroll}>
        {loading === true ? (
          <Row justify="center">
            <Spin></Spin>
          </Row>
        ) : (
          <div />
        )}
        <ChatBodyStyled>
          {chats
            ?.slice(0)
            .reverse()
            ?.map((item, index) => {
              if (item?.order) {
                return (
                  <ChatRow
                    key={index}
                    className={item.user === data?.tenant?._id ? "" : "right"}
                  >
                    <Order item={item} />
                  </ChatRow>
                );
              }
              if (item?.payment) {
                return (
                  <ChatRow
                    className={item.user === data?.tenant?._id ? "" : "right"}
                    key={index}
                  >
                    <Payment item={item} />
                  </ChatRow>
                );
              }
              return (
                <div key={index}>
                  {item?.message && (
                    <ChatRow
                      className={item.user === data?.tenant?._id ? "" : "right"}
                    >
                      <div>
                        <div className="chat-wrapper">
                          <div className="chat-message">{item?.message}</div>
                          <div className="chat-date">
                            {/* {datetimeFormat(item?.chatSendDate)} */}
                            {datetimeFormat(item?.chatSendDate)}
                          </div>
                        </div>
                      </div>
                    </ChatRow>
                  )}
                </div>
              );
            })}
        </ChatBodyStyled>
      </ScrollStyled>
    </Container>
  );
};

const OrderStyled = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 450px;

  .container {
    border: 1px solid #dadada;
    max-width: 450px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .label {
      font-size: 18px;
      font-weight: 500;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #333;
    }
  }
`;

const PrintModal = styled(Modal)`
  .ant-modal-content {
    width: 620px;
  }

  @media print {
    .ant-modal {
      width: 100vh;
      height: 100vh;
      background-color: white;
    }

    .ant-modal-header {
      display: none;
    }
    .ant-modal-close-x {
      display: none;
    }

    .ant-modal-mask {
      display: none !important;
    }
    .ant-modal-body {
      position: relative;
      width: 100%;
      height: 90%;
    }
    .ant-modal-content {
      position: fixed;
      left: 0;
      top: 0;
      box-shadow: none;
      height: 100%;
      width: 100%;
    }
  }
`;

const PaymentStyled = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 450px;

  .container {
    max-width: 450px;
    background-color: white;
    border: 1px solid #dadada;
    padding: 0 10px;
    border-radius: 5px;
    width: 100%;
  }
  table {
    width: 100%;
    font-weight: 600;
    tr {
      border-bottom: 1px solid #dadada;
      div {
        padding: 8px 0;
      }

      td:last-child {
        text-align: end;
      }
    }

    tr:last-child {
      border: 0;
    }
  }
`;

const Container = styled.div`
  border-bottom: 1px solid #dadada;
`;

const ScrollStyled = styled.div`
  max-height: calc(100vh - 230px);
  overflow-y: scroll;
  padding: 10px 0;
`;

const ChatBodyStyled = styled.div`
  overflow: hidden;
  min-height: calc(100vh - 235px);
`;

const ChatRow = styled.div`
  width: 100%;
  display: flex;
  padding: 5px 14px;

  .chat-wrapper {
    max-width: 500px;

    .chat-message {
      background: #f3f4f6;
      border-radius: 5px;
      padding: 10px 14px;
      word-wrap: break-word;
      word-break: break-all;
      white-space: pre-wrap;
    }
  }
  .chat-date {
    font-size: 13px;
    color: #9ca3af;
    line-height: 22px;
  }

  &.right {
    justify-content: end;
    .chat-date {
      text-align: end;
    }

    .chat-message {
      background: #f66d3b;
      color: #fff;
    }
  }
`;

export default Chat;
