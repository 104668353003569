
import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Field } from "formik";

const containerStyle = {
  width : "100%",
  height: "400px",
};

// Tokyo
const DEFAULT_CENTER = {
  lat: 35.6769147,
  lng: 139.7228118,
};

const GoogleMapPin = ({ name, center = DEFAULT_CENTER }) => {
  const { isLoaded } = useJsApiLoader({
    id              : "google-map-script",
    googleMapsApiKey: "AIzaSyAS74VkA2YA0FBA8w7lOJiGwb1mqXFhplw",
  });

  const [, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <Field name={name}>
      {({ form }) => {
        const handleMapClick = (e) => {
          const closureLat = e.latLng.lat();
          const closureLng = e.latLng.lng();

          form.setFieldValue(name, [closureLat, closureLng]);
        };

        return (
          <>
            <GoogleMap
              onClick={handleMapClick}
              mapContainerStyle={containerStyle}
              center={
                form.values[name]
                  ? { lat: form?.values[name][0], lng: form?.values[name][1] }
                  : center
              }
              zoom={!form?.values[name] ? 10 : 18}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {form.values[name] ? (
                <Marker
                  position={{
                    lat: form.values[name][0],
                    lng: form.values[name][1],
                  }}
                />
              ) : null}
            </GoogleMap>
          </>
        );
      }}
    </Field>
  ) : (
    <></>
  );
};

export default React.memo(GoogleMapPin);
