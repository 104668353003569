import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Dropdown, Menu } from "antd";
import { DownOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import NoImage from "../../assets/noimage.png";
import LogoImage from "../../assets/logo-square.svg";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { auth, general as generalApi } from "../../apis";
import { socket } from "../../components/SocketClient";

const { Header } = Layout;

const HeaderComponent = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { isMobile, user, lang } = useSelector((state) => state.general);
  const [checked, setChecked] = useState(lang === "EN");

  const localizationChange = async (e) => {
    try {
      setLoading(true);
      console.log(e);
      await generalApi.localization({
        lang: e ? "EN" : "JP",
      });
      setChecked(e);
      setLoading(false);
      await Promise.all([generalApi.init()(dispatch), auth.me()(dispatch)]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const logout = () => {
    socket.disconnect();
    dispatch({
      type: "auth.logout",
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => logout()}>
        <LogoutOutlined style={{ marginRight: 5 }} />{" "}
        {translate("header.menu.logout", "Log out")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Container>
      <div className="navbar">
        <div className="logo">
          <img src={LogoImage} alt="" />
          <h2>
            {translate("header.kariteya", "Kariteya")}
            <p>{translate("header.admin.dashboard", "Admin dashboard")}</p>
          </h2>
        </div>
      </div>

      <StyledLang
        disabled={loading}
        onClick={() => localizationChange(!checked)}
      >
        <div className={user?.lang === "EN" ? "active" : ""}>JP</div>
        <div className={user?.lang === "JP" ? "active" : ""}>EN</div>
      </StyledLang>
      {isMobile ? (
        <div className="user-wrap">
          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="user-action">
              <UserOutlined />
            </div>
          </Dropdown>
        </div>
      ) : (
        <div className="user-info">
          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="user-action">
              <div className="avatar">
                <img src={NoImage} alt="Avatar" />
              </div>
              <div className="username">
                {user?.nickname || translate("header.user.nickname", "user")}{" "}
                <DownOutlined />
              </div>
            </div>
          </Dropdown>
        </div>
      )}
    </Container>
  );
};

const StyledLang = styled.button`
  margin-right: 10px;
  background: #dadada;
  padding: 2px;
  border-radius: 2px;
  width: 50px;
  display: flex;
  justify-content: end;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border: none;
  background: ${(props) => (props.disabled ? "#898989" : "#dadada")};

  div {
    background: #fff;
    border-radius: 2px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
  }

  .active {
    background: transparent;
  }
`;

const Container = styled(Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;
  background: #fff;
  line-height: normal;
  user-select: none;
  border-bottom: 1px solid #ededed;
  box-shadow: 0 2px 8px #f0f1f2;

  .navbar {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    align-items: center;
  }
  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    h2 {
      margin: 0;
      p {
        font-size: 12px;
        margin: 0;
        font-weight: 500;
      }
    }
    img {
      height: 40px;
      margin: 0 10px;
    }
  }
  .user-wrap {
    max-width: 60px;
    min-width: 60px;
    height: 60px;
    z-index: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    background: #fff;
    flex-direction: column;
    border-bottom: 1px solid #ededed;
    transition: 0.3s;
    justify-content: center;
    align-items: center;
    .user-action {
      display: flex;
      flex-direction: row;
      position: relative;
      cursor: pointer;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #ededed;
    }
  }
  .user-info {
    max-width: 400px;
    min-width: 250px;
    height: 59px;
    z-index: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    background: #fff;
    flex-direction: column;
    border-left: 1px solid #ededed;
    transition: 0.3s;

    .user-action {
      display: flex;
      flex-direction: row;
      position: relative;
      cursor: pointer;

      .avatar {
        img {
          position: relative;
          overflow: hidden;
          margin-top: 5px;
          margin-left: 10px;
          height: 50px;
          width: 50px;
          border-radius: 60px;
          border: 1px solid #ededed;
          display: flex;
        }
      }
      .username {
        display: flex;
        align-items: center;
        font-weight: 700;
        padding-left: 10px;
        padding-right: 35px;

        .anticon-down {
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex !important;
          align-items: center;
        }
      }
    }
  }
`;

export default HeaderComponent;
