import React, { useTransition } from "react";
import { Col, Row, Spin } from "antd";
import { user as userApi } from "../../apis";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Form from "./Form";
import { useTranslate } from "../../hooks";

export default () => {
  const { translate } = useTranslate();
  const params = useParams();
  const [user, fetching] = useFetch(userApi.get, params.id)([]);

  return (
    <Spin spinning={fetching}>
      <PageContainer>
        <PageHeader title={translate("user.edit.pageheader.title.edit.user", "Edit user")} />
        <Row>
          <Col xs={24} sm={24} md={20} lg={18} xl={14} xxl={12}>
            <PageContent>
              {!fetching && (
                <Form action={["update", user]} />
              )}
            </PageContent>
          </Col>
        </Row>
      </PageContainer>
    </Spin>
  );
};