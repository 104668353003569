const initialState = {
  token : null,
  tokens: []
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "auth.change":
      const { accessToken, refreshToken } = action.payload;

      return {
        ...state,
        token : accessToken,
        tokens: [accessToken, refreshToken]
      };
    case "auth.remember":
      return {
        ...state,
        remember: action.payload,
      };
    case "auth.logout":
      return {
        ...state,
        user : {},
        token: null,
      };
    default:
      return state;
  }
};

export default auth;
