import React from "react";
import { Tabs } from "antd";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import Documents from "./components/Documents";
import styled from "styled-components";
import { useTranslate } from "../../hooks";

const DocumentPage = () => {
  const { translate } = useTranslate();

  return (
    <PageContainer>
      <PageHeader title={translate("document.title", "Document")} />
      <PageContent>
        <Styled>
          <Tabs defaultActiveKey="1" >
            <Tabs.TabPane tab={translate("document.about", "About")} key="1">
              <Documents type="ABOUT" />
            </Tabs.TabPane>
            <Tabs.TabPane tab={translate("document.agreement", "Agreement")} key="2">
              <Documents type="AGREEMENT" />
            </Tabs.TabPane>
            <Tabs.TabPane tab={translate("document.policy", "Policy")} key="3">
              <Documents type="POLICY" />
            </Tabs.TabPane>
            <Tabs.TabPane tab={translate("document.rentpolicy", "Rent policy")} key="4">
              <Documents type="RENT_POLICY" />
            </Tabs.TabPane>
            <Tabs.TabPane tab={translate("document.transfer", "Transfer")} key="5">
              <Documents type="TRANSFER" />
            </Tabs.TabPane>
          </Tabs>
        </Styled>
      </PageContent>
    </PageContainer>
  );
};

const Styled = styled.div`
  .ant-tabs-nav { 
    background: #fff;
    padding: 0 14px;
  }
`;

export default DocumentPage;