import React from "react";
import {
  Tag,
  Button,
  Avatar,
  Modal,
  Input,
  DatePicker,
  Select,
  Space,
} from "antd";
import {
  propertyType as propertyTypeApi,
  request as requestApi,
} from "../../apis";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import { ExclamationCircleOutlined, PictureOutlined } from "@ant-design/icons";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";
import useFetch from "../../hooks/useFetch";
import { useTranslate } from "../../hooks";
import { postCode, renderUser, yen } from "../../utils";

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [types] = useFetch(propertyTypeApi.select)([]);
  const [query, setQuery] = React.useState("");
  const { s3host, roles, webhost } = useSelector((state) => state.general);
  const { checkPermission } = usePermission();

  const [filters, setFilters] = React.useState({
    query    : "",
    startDate: "",
    ownerType: "CUSTOMER",
    status   : "APPLIED",
    endDate  : "",
  });

  const columns = useHeader({
    translate,
    s3host,
    roles,
    type    : params.type,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "activate": {
          Modal.confirm({
            title  : translate("system.list.model.title", "Confirm"),
            icon   : <ExclamationCircleOutlined />,
            content: translate(
              "property.request.list.modal.content",
              "Would you like to activate this property for rent?"
            ),
            okText    : translate("property.request.list.modal.oktext", "Activate"),
            cancelText: translate("system.list.modal.canceltext", "Cancel"),
            onOk      : async () => {
              await requestApi.update(record._id, {
                previewStatus: "APPROVED",
              });
              myTableRef.current.reload();
            },
          });
          break;
        }
        case "preview": {
          window.open(`${webhost}/preview/${record?.property}`);
          break;
        }
        default:
      }
    },
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    setFilters({
      query : "",
      status: "APPLIED",
    });
  }, [params.type]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").toDate(),
      endDate  : e && moment(e[1]).endOf("days").toDate(),
    });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      query : "",
      status: "APPLIED",
    });
  };

  return (
    <PageContainer>
      <PageHeader
        title={translate(
          "property.request.list.pageheader.title",
          "Property request"
        )}
      />
      <PageContent>
        <Filter
          extra={[
            <Button onClick={onClear}>
              {translate("system.filter.btn.clear", "Clear")}
            </Button>,
          ]}
        >
          <Input
            className="grid-item"
            prefix={<SearchOutlined />}
            value={query}
            placeholder={translate("system.input.search", "Search...")}
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />
        </Filter>
        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={requestApi.list}
          rowKey={(record) => record?.rowKey}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("system.useheader.title.name", "Name"),
      render: (record) => {
        return (
          <div>
            <div>{record?.name}</div>
            <p>{record?.description}</p>
          </div>
        );
      },
    },
    {
      title : translate("system.useheader.title.owner", "Owner"),
      render: (row) => {
        return renderUser(row.user);
      },
    },
    {
      title : translate("system.property.list.address", "Address"),
      render: (record) => {
        if (!record.address) return <div>-</div>;
        return (
          <Tag style={{ whiteSpace: "pre", }}>
            {postCode(record?.address?.postCode)}
            {record?.address?.city}
            {record?.address?.address}{"\n"}
            {record?.address?.buildingName}
          </Tag>
        );
      },
    },
    {
      title : translate("system.useheader.title.day.taxincluded", "Tax included"),
      render: (record) => {
        if (record.negotiable){
          return <Tag>{translate("app.property.detail.negotiable", "Negotiable")}</Tag>;
        }
        if (record.useMultiPrice){
          if (record.useSpecialPrice){
            return <div>
              <Tag style={{ marginBottom: "2px" }}>{translate("property.list.tag.week", "Week")} {`${yen(record.weekTax)}`}</Tag>
              <Tag style={{ marginBottom: "2px" }}>{translate("property.list.tag.weekend", "Weekend")} {`${yen(record.weekendTax)}`}</Tag>
              <Tag>{translate("property.list.tag.special", "Special")}{`${yen(record.specialTax)}`}</Tag>
            </div>;
          }
          return <div>
            <Tag style={{ marginBottom: "2px" }}>{translate("property.list.tag.week", "Week")} {`${yen(record.weekTax)}`}</Tag>
            <Tag style={{ marginBottom: "2px" }}>{translate("property.list.tag.weekend", "Weekend")} {`${yen(record.weekendTax)}`}</Tag>
          </div>;
        }
        return <Tag style={{ marginBottom: "2px" }}>{`${yen(record.weekTax)}`}</Tag>;
      }
    },
    {
      title : translate("system.useheader.title.status", "Status"),
      render: (record) => {
        return <Tag>{translate(record.previewStatus, record.previewStatus)}</Tag>;
      },
    },
    {
      title : translate("system.useheader.title.date", "Date"),
      render: (record) => {
        return (
          <div>
            {moment(record.propertyStatusDate).format("YYYY/MM/DD HH:mm")}
          </div>
        );
      },
    },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        return (
          <RowAction
            actions={{
              preview : translate("system.action.preview", "Preview"),
              activate: translate(
                "property.request.action.activate",
                "Activate"
              ),
            }}
            onClick={(key) => onAction(key, record)}
          />
        );
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
