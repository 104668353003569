import React from "react";
import {
  Tag,
  Button,
  Avatar,
  DatePicker,
  Space,
  Modal,
  message,
  Select,
} from "antd";
import { transaction as transactionApi, property as propertyApi } from "../../apis";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";
import { dateFormat, datetimeFormat, renderUser, yen } from "../../utils";
import { ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslate } from "../../hooks";
import useFetch from "../../hooks/useFetch";
export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [query, setQuery] = React.useState("");
  const { s3host, roles, paymentStatus } = useSelector(
    (state) => state.general
  );
  const { checkPermission } = usePermission();
  const [properties] = useFetch(propertyApi.selectAdmin)([]);
  const [filters, setFilters] = React.useState({
    startDate    : "",
    endDate      : "",
    paymentMethod: undefined,
    property     : undefined,
  });
  const paymentMethod = [{ value: "CARD", name: "クレジットカード" }, { value: "TRANSFER", name: "振込" }];
  const columns = useHeader({
    translate,
    s3host,
    roles,
    type    : params.type,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "pay": {
          Modal.confirm({
            title  : translate("system.list.model.title", "Confirm"),
            icon   : <ExclamationCircleOutlined />,
            content: translate(
              "transaction.list.model.content",
              "Would you like to verify this transaction?"
            ),
            okText    : translate("transaction.list.model.oktext", "Pay"),
            cancelText: translate("system.list.modal.canceltext", "Cancel"),
            onOk      : async () => {
              try {
                await transactionApi.pay({ paymentId: record._id });
                myTableRef.current.reload();
              } catch (err) {
                message.error(err.message || "Could not get dates");
              }
            },
          });
          break;
        }
        default:
      }
    },
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    setFilters({
      startDate: undefined,
      endDate  : undefined,
      property : undefined,
    });
  }, [params.type]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").toDate(),
      endDate  : e && moment(e[1]).endOf("days").toDate(),
    });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      startDate: undefined,
      property : undefined,
      endDate  : undefined,
    });
  };

  return (
    <PageContainer>
      <PageHeader
        title={translate("transaction.pageheader.title", "Transaction")}
      />
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
              placeholder={[translate("payment.history.start", "Start date"), translate("payment.history.end", "End date")]}
            />,
            <Button onClick={onClear}>
              {translate("system.filter.btn.clear", "Clear")}
            </Button>,
          ]}
        >
          <Select
            className="grid-item"
            value={filters.status}
            style={{ width: "130px" }}
            onChange={(e) => setFilters({ ...filters, status: e })}
            placeholder={translate("property.list.select.status", "Status")}
            allowClear
          >
            {paymentStatus.map((item, index) => (
              <Select.Option key={index} value={item}>
                {translate(item, item)}
              </Select.Option>
            ))}
          </Select>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 300 }}
            value={filters.property}
            onChange={(e) => setFilters({ ...filters, property: e })}
            placeholder={translate("income.list.select.property", "Property ")}
            allowClear
            options={properties.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })}
          ></Select>
          <Select
            className="grid-item"
            value={filters.paymentMethod}
            style={{ width: "130px" }}
            onChange={(e) => setFilters({ ...filters, paymentMethod: e })}
            placeholder={translate("payment.method.select", "Select")}
            allowClear
          >
            {paymentMethod.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Filter>
        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={transactionApi.list}
          rowKey={(record) => record?.rowKey}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("system.useheader.title.owner", "Owner"),
      render: (row) => {
        return <div style={{ minWidth: 100 }} >{renderUser(row.owner)}</div>;
      },
    },
    {
      title : translate("system.useheader.title.tenant", "Tenant"),
      render: (row) => {
        return renderUser(row.tenant);
      },
    },
    {
      title : translate("system.useheader.list.property", "Property"),
      render: (row) => {
        return (
          <Space>
            <Avatar size={45} shape="square" src={row?.property?.image} />
            <div style={{ minWidth: 250 }}>
              <b>{row?.property?.name}</b> <br />
            </div>
          </Space>
        );
      },
    },
    {
      title : translate("system.useheader.title.orderid", "Order ID"),
      render: (record) => {
        return <Tag>{record?.order?.orderId}</Tag>;
      },
    },
    {
      title : translate("system.useheader.title.amount", "Amount"),
      render: (record) => {
        return `${yen(record.amount)}`;
      },
    },
    {
      title : translate("system.useheader.title.tax", "Tax"),
      render: (record) => {
        return `${yen(record.tax)}`;
      },
    },
    {
      title : translate("system.useheader.title.total", "Total"),
      render: (record) => {
        return `${yen(record.total)}`;
      },
    },
    {
      title : translate("system.useheader.title.method", "Method"),
      render: (record) => {
        if (record?.paymentMethod === "TRANSFER"){
          return "振込";
        }
        return `${record?.method || "-"}`;
      },
    },
    {
      title : translate("system.useheader.title.status", "Status"),
      render: (record) => {
        return (
          <Tag>{translate(record.paymentStatus, record.paymentStatus)}</Tag>
        );
      },
    },
    {
      title : translate("transaction.useheader.title.date", "Date"),
      render: (record) => {
        return <div>{datetimeFormat(record.paymentStatusDate)}</div>;
      },
    },
    {
      title : translate("order.list.useheader.dates", "Dates"),
      render: (record) => {
        return (
          <Tag>{`${dateFormat(record.startDate)}-${dateFormat(
            record.endDate
          )}`}</Tag>
        );
      },
    },
    {
      title : translate("transaction.list.gmo", "GMO ID"),
      render: (record) => {
        return <div>{record?.gmoAccessID || "-"}</div>;
      },
    },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        if (record.paymentStatus === "TRANSFER") {
          return (
            <Button onClick={() => onAction("pay", record)}>
              {translate("transaction.list.action.verify", "Verify")}
            </Button>
          );
        }
        return "-";
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
