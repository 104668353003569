const initialState = {
  user         : {},
  roles        : [],
  userTypes    : [],
  permissions  : [],
  paymentStatus: [],
  orderStatuses: [],
  lang         : null,
  notifications: null,
  policies     : [],
  translate    : {},
  incomeStatus : [],
  adminHost    : null,
  webhost      : null,
  orders       : null,
  tickets      : 0,
  payments     : 0,
  permission   : []
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case "general.init": {
      const { orderStatuses, payments, permission, tickets, orders, adminHost, webhost, notifications, incomeStatus, policies, paymentStatus, permissions, roles, userTypes, translate, lang } = action.payload;
      return {
        ...state,
        roles,
        payments,
        notifications,
        orders,
        permissions,
        userTypes,
        paymentStatus,
        orderStatuses,
        policies,
        translate,
        lang,
        incomeStatus,
        adminHost,
        webhost,
        tickets,
        permission
      };
    }
    case "general.locale.lang": {
      return {
        ...state,
        lang: action.payload
      };
    }
    case "auth.me":
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
};

export default general;
