import React from "react";
import { Checkbox, Form, FormItem, Input, InputNumber } from "formik-antd";
import { Button, Form as AntForm, Spin } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslate } from "../../hooks";

const ArticleParentForm = ({ action, onSubmit, onCancel, }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    title: "",
    show : false,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const FormSchema = () => Yup.object({
    title: Yup.string().required(translate("equipment.form.required.type", "type is a required field")),
    show : Yup.boolean().required(translate("system.required", "This  is a required field")),
    sort : Yup.number(translate("equipment.form.required.name", "name  is a required field")).min(0, translate("equipment.form.required.name", "name  is a required field")).required(translate("equipment.form.required.name", "name  is a required field")),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem name="title" label={translate("article.title", "Title")} required>
              <Input name="title" placeholder={translate("article.title", "Title")} />
            </FormItem>
            <FormItem name="sort" label={translate("equipments.form.sort", "Sort")} required>
              <InputNumber name="sort" placeholder={translate("equipments.form.sort", "Sort")} />
            </FormItem>
            <FormItem
              name="show"
              label={translate("article.form.show", "Show")}
            >
              <Checkbox
                name="show"
              />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>{translate("system.btn.save", "Save")}</Button>
              <Button className="button" type="default" onClick={onCancel}>{translate("system.btn.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ArticleParentForm;