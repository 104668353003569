/* eslint-disable indent */
/* eslint-disable key-spacing */
import React, { useState } from "react";
import { Upload, message } from "antd";
import { Field } from "formik";
import { connect } from "react-redux";
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../colors";
import { useTranslate } from "../../hooks";

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp" ||
    file.type === "image/svg+xml";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Image must smaller than 10MB!");
  }
  return isJpgOrPng && isLt2M;
}

const UploadComponent = ({ name, auth, general, action, ...restProps }) => {
  const [loading, setLoading] = useState(false);
  const { token } = auth;

  const handleChange = (
    info,
    setFieldValue,
    setFieldTouched,
    setFieldError
  ) => {
    setFieldTouched(name, true);

    if (info.file.status === "uploading") {
      setLoading(true);
      setFieldError(name, "Uploading...");
    }

    if (info.file.status === "done") {
      setFieldValue(name, info.file.response.url);
      setLoading(false);
    }
  };

  const remove = (setFieldValue) => {
    setFieldValue(name, undefined);
  };

  const uploadButton = (
    <UploadButton>
      {loading === false ? <PlusOutlined /> : <LoadingOutlined />}
      <div className="ant-upload-text">{"Upload"} </div>
    </UploadButton>
  );

  return (
    <Field name={name}>
      {(fieldProps) => {
        const {
          field: { value },
          form: { setFieldValue, setFieldTouched, setFieldError },
        } = fieldProps;

        return (
          <Container>
            {value && !loading ? (
              <a className="imgContainer" onClick={() => remove(setFieldValue)}>
                <div className="overlay">
                  <DeleteOutlined className="icon" type="delete" />
                </div>
                <img
                  src={`${value}`}
                  alt="avatar"
                  style={{ width: "128px", height: "128px" }}
                />
              </a>
            ) : (
              <Upload
                name="file"
                showUploadList={false}
                action={action}
                headers={{
                  Authorization: `Bearer ${token}`,
                }}
                beforeUpload={beforeUpload}
                onChange={(info) =>
                  value
                    ? null
                    : handleChange(
                        info,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError
                      )
                }
                {...restProps}
              >
                {uploadButton}{" "}
              </Upload>
            )}
          </Container>
        );
      }}
    </Field>
  );
};

const mapStateToProps = ({ auth, general }) => ({
  auth,
  general,
});

export default connect(mapStateToProps)(UploadComponent);

const Container = styled.div`
  width: 128px;
  height: 128px;

  .imgContainer {
    position: relative;
    display: flex;
    width: 128;
    height: 128;
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: #0000006e;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      z-index: 100;
      opacity: 0;
      transition: all 0.3s;
      .icon {
        font-size: 14px;
        &:hover {
          color: #40a9ff;
        }
      }

      &:hover {
        opacity: 100;
      }
    }
  }
`;

const UploadButton = styled.div`
  width: 128px;
  height: 128px;
  border: 1px dashed #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s;
  &:hover {
    border-color: ${colors.primary};
    color: ${colors.primary};
  }
`;
