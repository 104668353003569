import styled from "styled-components";
import { useTranslate } from "../../../hooks";
import dayjs from "dayjs";
import { datetimeFormat, yen } from "../../../utils";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const PreviewOrder = ({ data }) => {
  const { translate } = useTranslate();

  return (
    <PaymentStyled>
      <div className="container">
        <table>
          <tr>
            <td>
              <div>{translate("chat.amount", "Amount")}:</div>
            </td>
            <td>
              <div>{yen(data?.amount)}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>{translate("chat.tax", "Tax")}:</div>
            </td>
            <td>
              <div>{yen(data?.tax)}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>{translate("chat.total.amount", "Total amount")}:</div>
            </td>
            <td>
              <div>{yen(data.total)}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>{translate("chat.total.days", "Days")}:</div>
            </td>
            <td>
              <div>{data.days}日</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                {translate("app.chat.chat.expireDate", "Payment expire date")}:
              </div>
            </td>
            <td>
              <div className="text-[13px] max-w-md break-words text-end">
                {dayjs.utc(data?.expireDate).format("YYYY/MM/DD")}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </PaymentStyled>
  );
};

const PaymentStyled = styled.div`
  margin-top: 10px;
  width: 100%;

  .container {
    background-color: white;
    padding: 0 10px;
    border-radius: 5px;
    width: 100%;
  }

  table {
    width: 100%;
    font-weight: 600;

    tr {
      border-bottom: 1px solid #dadada;
      div {
        padding: 8px 0;
      }

      td:last-child {
        text-align: end;
      }
    }

    tr:last-child {
      border: 0;
    }
  }
`;

export default PreviewOrder;
