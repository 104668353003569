import React from "react";
import { Checkbox, Form, FormItem, Input } from "formik-antd";
import { Button, Form as AntForm, Spin } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslate } from "../../hooks";
import DraftField from "../../components/DraftCke";


const ProjectForm = ({ action, onSubmit, onCancel, }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    title    : "",
    body     : "",
    isActive : false,
    isCrucial: false,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const FormSchema = () => Yup.object({
    title   : Yup.string().required(translate("equipment.form.required.type", "type is a required field")),
    body    : Yup.string().required(translate("equipment.form.required.name", "name  is a required field")),
    isActive: Yup.boolean().required(translate("equipment.form.required.name", "name  is a required field"))
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem name="title" label={translate("news.title", "Title")} required>
              <Input name="title" placeholder={translate("news.title", "Title")} />
            </FormItem>
            <FormItem name="isActive" label={translate("news.isActive", "Active/Disactive")} required>
              <Checkbox name="isActive" />
            </FormItem>
            <FormItem name="isCrucial" label={translate("news.isCrucial", "Crucial/NotCrucial")} required>
              <Checkbox name="isCrucial" />
            </FormItem>
            <FormItem name="body" >
              <DraftField
                name="body"
                initialValue={data?.body || ""}
                autoSize={{ minRows: 20, maxRows: 200 }}
                maxLength={10000}
              />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>{translate("system.btn.save", "Save")}</Button>
              <Button className="button" type="default" onClick={onCancel}>{translate("system.btn.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProjectForm;