import { Row, Col, Tag, Table, Collapse } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { income as incomeApi } from "../../apis";
import { Loader } from "../../components";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import { datetimeFormat, renderUser, yen } from "../../utils";
const { Panel } = Collapse;

const Detail = () => {
  const params = useParams();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [data, setData] = useState();
  const { translate } = useTranslate();

  const reload = React.useCallback(async (signal) => {
    let res = await incomeApi.get(params.id, null, { signal });
    setData(res);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);

  const column = useHeader({ translate });

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("income.detail.pageheader.title", "Income detail")}
      />
      <ContainerStyled> {!data ? <Loader/> :
        <PageContent>
          {/* <StyledCardRow>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="content-wrapper">
                  <div className="income">
                    <div>{data?.property?.name}</div>
                    <div>{yen(data.total)}</div>
                  </div>
                </div>

              </Col>
            </Row>
          </StyledCardRow> */}
          <Collapse >
            <Panel header={data?.property?.name} extra={`${yen(data.amount)}${translate("income.taxless", "Taxless")}`} >
              {data.dates.map(item => {
                return <div style={{ display: "flex", marginBottom: "4px", justifyContent: "space-between", borderBottom: "1px solid #dadada" }}>
                  <div>{moment(item.date).format("YYYY/MM/DD")}</div>
                  <div>{yen(item?.price)}</div>
                </div>;
              })}
            </Panel>
          </Collapse>
          <div style={{ margin: "14px 0" }}>
            <div className="ant-page-header-heading-title">{translate("income.detail.title.payments", "Payments")}</div>
          </div>
          <div style={{ padding: "14px", background: "#fff" }}>
            <Table
              size="small"
              scroll={{
                x    : "auto",
                width: "max-content"
              }}
              dataSource={data?.payments.map((item, index) => {
                return {
                  ...item,
                  key: index + 1
                };
              })}
              columns={column}
              rowKey={(row) => row?._id}
              pagination={false}
            />
          </div>
        </PageContent>}
      </ContainerStyled>
    </PageContainer>
  );
};

const ContainerStyled = styled.div`
  max-width: 1200px;
`;
const StyledCardRow = styled.div`
  background: #fff;  
  padding: 0;
  padding: 24px;
  border : 1px solid #dadada;

  border-radius: 5px;
  .content-wrapper { 
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    .income {
      display: flex;
      justify-content: space-between;
    }
    .ant-list-item-meta-description {
      padding-bottom: 8px;
    }
  } 
`;

const useHeader = ({ onAction, translate }) => {
  return ([{
    title    : "№",
    dataIndex: "key",
    width    : "20px"
  }, {
    title : translate("system.useheader.title.tenant", "Tenant"),
    render: (row) => {
      return renderUser(row.tenant);
    }
  }, {
    title : translate("system.useheader.title.orderid", "Order ID"),
    render: (record) => {
      return <Tag>{record?.order?.orderId}</Tag>;
    }
  }, {
    title : translate("system.useheader.title.usagerange", "Dates"),
    render: (record) => {
      return <Tag>{`${moment(record.order.startDate).format("YYYY/MM/DD")}-${moment(record.order.endDate).format("YYYY/MM/DD")}` }</Tag>;
    }
  }, {
    title : translate("system.useheader.title.amount", "Amount"),
    render: (record) => {
      return `${yen(record.amount)}`;
    }
  }, {
    title : translate("system.useheader.title.tax", "Tax"),
    render: (record) => {
      return `${yen(record.tax)}`;
    }
  }, {
    title : translate("system.useheader.title.total", "Total"),
    render: (record) => {
      return `${yen(record.total)}`;
    }
  }, {
    title : translate("system.useheader.title.status", "Status"),
    render: (record) => {
      return <Tag>{translate(record.paymentStatus, record.paymentStatus)}</Tag>;
    }
  }, {
    title : translate("system.useheader.title.statusdate", "Date"),
    render: (record) => {
      return (<div>{datetimeFormat(record.paymentStatusDate)}</div>);
    }
  }]);
};

export default Detail;