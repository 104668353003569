import request from "./request";
import moment from "moment";
import numeral from "numeral";
import { Avatar, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
const dateFormat = (date) => moment(date).format("YYYY/MM/DD");
const datetimeFormat = (date) => moment(date).format("YYYY/MM/DD HH:mm");
import { useHistory } from "react-router-dom";

numeral.register("locale", "mn", {
  delimiters: {
    thousands: ",",
    decimal  : ",",
  },
  abbreviations: {
    thousand: "k",
    million : "m",
    billion : "b",
    trillion: "t",
  },
  currency: {
    symbol: "₮",
  },
});
numeral.locale("mn");

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  style: {
    marginTop: 20,
  },
  wrapperCol: {
    xs: {
      span  : 24,
      offset: 0,
    },
    sm: {
      span  : 16,
      offset: 8,
    },
  },
};
const yen = (val) => {
  return `￥${numeral(parseFloat(val)).format("0,0")}`;
};
const postCode = (val) => (val ? `〒${val}` : "Post code unavailable");
const renderUser = (user) => {
  const history = useHistory();
  if (user) {
    return (
      <Space style={{ cursor: "pointer" }} onClick={() => {
        history.push(`/app_user/edit/${user._id}`);
      }}>
        <Avatar src={user?.avatar} icon={<UserOutlined />} />
        {user?.type === "PERSONAL" ? (
          <div >
            <b>{user?.lastName}</b>
            <b>{user?.firstName}</b> <br />
          </div>
        ) : (
          <div>
            <b>{user?.orgname}</b> <br />
          </div>
        )}
      </Space>
    );
  }
  return (
    <Space>
      <Avatar icon={<UserOutlined />} />
      <div>
        <div>{"ユーザーが見つかりません"}</div>
      </div>
    </Space>
  );
};
const truncate = (value = "", limit = 20) => {
  if (value?.length >= limit) return `${value?.substring(0, limit)}...`;
  if (value.length === 0) return "-";
  return value;
};

export {
  renderUser,
  truncate,
  postCode,
  yen,
  request,
  dateFormat,
  datetimeFormat,
  formItemLayout,
  tailFormItemLayout,
};
