import React from "react";
import { useSelector } from "react-redux";

const useTranslate = () => {
  const { translate, lang } = useSelector(state => state.general);

  return {
    translate: (key, value) => {
      if (translate[lang] && translate[lang][key])
      {
        return `${translate[lang][key]}`;
        // return `${translate[lang][key]} {${key}}`;
      }
      return key;
    },
  };
};

export default useTranslate;