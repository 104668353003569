/* eslint-disable key-spacing */
/* eslint-disable no-nested-ternary */

import { Space, Image, Row, Col, Tag, Button, message, Modal } from "antd";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { order as orderApi } from "../../apis";
import { Loader } from "../../components";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/Layout";
import { useTranslate } from "../../hooks";
import { dateFormat, datetimeFormat, postCode, yen } from "../../utils";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { CalendarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import NewCalendarForm from "../../components/Calendar/NewCalendarForm";
import { Interweave } from "interweave";
import { RangeCalendar } from "@mantine/dates";
import { Divider, NumberInput } from "@mantine/core";

const Detail = () => {
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState();
  const [totalAmount, setTotalAmount] = React.useState(undefined);
  const { translate } = useTranslate();
  const [cancelLoading, setCancelLoading] = React.useState(false);
  const [verifyLoading, setVerifyLoading] = React.useState(false);
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  // const [selectedWeek, setSelectedWeek] = React.useState(
  //   data?.orderItems || []
  // );

  const [selectedDateRange, setSelectedDateRange] = React.useState([]);
  const [gahaiError, setGahaiError] = React.useState([false, false]);

  const [disabledDates, setDisabledDates] = React.useState([]);

  const reload = React.useCallback(
    async (signal) => {
      let res = await orderApi.get(params.id, null, { signal });
      console.log(data);
      setData(res);
    },
    [params.id]
  );

  const onCancel = async () => {
    try {
      Modal.confirm({
        title: translate("order.details.oncancel.cancel", "Cancel"),
        icon: <ExclamationCircleOutlined />,
        content: translate(
          "order.details.oncancel.content.perform.cancel.action",
          "You are about to perform a cancel action."
        ),
        okText: translate("order.details.oncancel.confirm", "Confirm"),
        cancelText: translate("order.details.oncancel.back", "Cancel"),
        className: "btn-custom-class",
        onOk: async () => {
          try {
            setCancelLoading(true);
            await orderApi.cancel({ orderId: params.id });
            message.success(translate("system.request.success", "Success!"));
            history.push("/order");
            setCancelLoading(false);
          } catch (error) {
            console.log(error);
          }
        },
      });
    } catch (error) {
      message.error(
        error?.message || translate("system.request.failed", "Failed!")
      );
      setCancelLoading(false);
    }
  };

  const getDates = (startDate, endDate) => {
    const daysbetween =
      Math.floor((Date.parse(startDate) - Date.parse(endDate)) / 86400000) - 1;

    const dates = Array(-daysbetween || 0).fill("");
    return dates?.map((date, index) => {
      if (index === 0) {
        return startDate;
      }
      if (index === -daysbetween) {
        return endDate;
      }
      return moment(startDate, "YYYY-MM-DD").add(index, "days");
    });
  };

  const deleteItem = (indexs, item) => {
    let array = selectedDateRange;
    array = array.filter(function (item, index) {
      return index !== indexs;
    });
    setSelectedDateRange(array);
    const res = getDates(item.startDate, item.endDate).map((item) =>
      moment(item)?.format("YYYY/MM/DD")
    );

    setDisabledDates(disabledDates.filter((val) => !res.includes(val)));
  };

  const update = async () => {
    setVerifyLoading(true);
    try {
      await orderApi.verify({
        orderId: params.id,
        startDate: selectedDateRange[0].startDate,
        endDate: selectedDateRange[0].endDate,
        amount: totalAmount,
      });
      message.success(
        translate("order.details.message.success", "Bulk successfully updated")
      );
      setCalendarOpen(false);
      setVerifyLoading(false);
      reload();
    } catch (error) {
      message.error(
        error?.message || translate("system.request.failed", "Failed!")
      );
      setVerifyLoading(false);
    }
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);

  React.useEffect(() => {
    if (data) {
      const res = getDates(data?.startDate, data?.endDate).map((c) =>
        moment(c).format("YYYY/MM/DD")
      );
      const united = data?.property?.unavailableDates
        .map((x) => moment(x).format("YYYY/MM/DD"))
        .concat(res.pop());

      setDisabledDates(united);
    }
  }, [data]);

  return (
    <PageContainer>
      <PageHeader
        onBack={() => history.goBack()}
        title={translate("order.details.title.order.details", "Order detail")}
        extra={
          [
            // <Button
            //   danger
            //   onClick={() => onCancel()}
            //   loading={cancelLoading}
            //   disabled={data?.orderStatus === "CANCELLED"}
            // >
            //   {translate("order.details.btn.cancel", "Cancel")}
            // </Button>,
            // <Button
            //   type="primary"
            //   onClick={() => {
            //     setCalendarOpen(true);
            //   }}
            //   disabled={data?.orderStatus === "PAID"}
            // >
            //   <a
            //     onClick={() => {
            //       setCalendarOpen(true);
            //       setSelectedDateRange([
            //         { startDate: data.startDate, endDate: data.endDate },
            //       ]);
            //     }}
            //     className="flex max-w-[200px] gap-1 items-center mt-2 rounded px-2 py-1 text-white bg-gradient-to-r from-orange-400 to-orange-510 hover:from-orange-400 hover:to-orange-500 hover:shadow "
            //   >
            //     <div className="font-bold">
            //       {translate("order.details.btn.verify", "Verify")}
            //     </div>
            //   </a>
            // </Button>,
          ]
        }
      />
      <ContainerStyled>
        {!data ? (
          <Loader />
        ) : (
          <PageContent>
            <StyledCardRow>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div
                    className="ant-page-header-heading-title"
                    style={{
                      width: "100%",
                    }}
                  >
                    {translate("order.details.col.property", "Property")}
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #dadada",
                        paddingBottom: "24px",
                      }}
                    >
                      <div className="ant-tag">
                        {translate(
                          "order.details.order.status",
                          "Order status"
                        )}
                        :{" "}
                        <b>
                          {translate(
                            `system.orderstatus.${data?.order?.orderStatus}`,
                            data?.order?.orderStatus
                          )}
                        </b>
                      </div>
                      <div className="ant-tag">
                        {translate("order.details.order.date", "Order date")}:{" "}
                        <b>{datetimeFormat(data?.createdAt)}</b>
                      </div>
                      <div className="ant-tag">
                        {translate(
                          "order.details.order.daily.price",
                          "Daily price"
                        )}
                        : <b>{yen(data?.dailyPrice)}</b>
                      </div>
                      <div className="ant-tag">
                        {translate("system.useheader.title.amount", "Amount")}:{" "}
                        <b>{yen(data?.amount)}</b>
                      </div>
                      <div className="ant-tag">
                        {translate("system.useheader.title.tax", "Tax")}:{" "}
                        <b>{yen(data?.tax)}</b>
                      </div>
                      <div className="ant-tag">
                        {translate("system.useheader.title.total", "Total")}:{" "}
                        <b>{yen(data?.total)}</b>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={6}>
                  <Image
                    hveight={280}
                    width={"100%"}
                    style={{ objectFit: "cover" }}
                    className="image"
                    src={data?.property.image || "/"}
                    alt=""
                  />
                  <div className="chatDetail-container">
                    <h3>
                      {translate(
                        "app.chat.detail.aboutPlanning",
                        "About planning"
                      )}
                    </h3>
                    <div className="chatDetail-titles">
                      {translate(
                        "app.chat.detail.usageDetails",
                        "Usage details"
                      )}
                    </div>
                    <div className="">{data?.useType}</div>
                    <div className="chatDetail-titles">
                      {translate("app.chat.detail.projectName", "Project name")}
                    </div>
                    <div className="">{data?.businessName}</div>
                    <div className="chatDetail-titles">
                      {translate(
                        "app.chat.detail.planContents",
                        "Plan contents"
                      )}
                    </div>
                    <div className="">{data?.businessDesc}</div>
                    <div className="chatDetail-titles">
                      {translate(
                        "app.chat.detail.eating",
                        "Eating and drinking / tasting"
                      )}
                    </div>
                    <div className="">
                      {data?.isProvisional
                        ? translate("app.chat.detail.provided", "Provided")
                        : translate("app.chat.detail.no", "No")}
                    </div>
                    <div className="chatDetail-titles">
                      {translate("app.chat.detail.inquiry", "Inquiry")}
                    </div>
                    <div className="">{data?.inquiry}</div>
                  </div>
                  <div className="chatDetail-container">
                    <h3 className="chatDetail-dates">
                      {translate(
                        "app.chat.detail.desiredPeriod",
                        "Desired usage period"
                      )}
                      <Tag color="#f97318">
                        {translate(
                          `system.orderstatus.${data?.orderStatus}`,
                          data?.orderStatus
                        )}
                      </Tag>
                    </h3>
                    <div>
                      <div className="chatDetail-orderitems">
                        <CalendarIcon
                          style={{ height: "20px", color: "#f97318" }}
                        />
                        <div className="chatDetail-date">
                          {data?.startDate === data?.endDate ? (
                            <div>
                              {moment(data?.startDate).format("YYYY/MM/DD")}
                            </div>
                          ) : (
                            <div>
                              {moment(data?.startDate).format("YYYY/MM/DD")} -{" "}
                              {moment(data?.endDate).format("YYYY/MM/DD")}
                            </div>
                          )}
                        </div>
                      </div>
                      <div />
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={14} xl={16} xxl={18}>
                  <div className="content-wrapper">
                    <div>
                      <div className="ant-list-item-meta-title">
                        {data?.property?.name || "-"}
                      </div>
                      <div className="ant-list-item-meta-description">
                        {data?.property?.title || "-"}
                      </div>
                      <div className="ant-list-item-meta-title">
                        {translate("property.form.introdution", "Introduction")}
                      </div>
                      <Interweave
                        content={data?.property?.intro}
                        className="ant-list-item-meta-description"
                      />
                      <div className="ant-list-item-meta-title">
                        {translate("property.form.space.details", "Details")}
                      </div>
                      <Interweave
                        content={data?.property?.details}
                        className="ant-list-item-meta-description"
                      />
                    </div>
                    <Space direction="vertical">
                      <div>
                        <div className="ant-list-item-meta-title">
                          {translate("order.details.space.address", "Address")}:{" "}
                        </div>
                        <Tag>
                          {postCode(data?.property?.address?.postCode)}
                          {data?.property?.address?.city}
                          {data?.property?.address?.address}
                          {data?.property?.address?.buildingName}{" "}
                        </Tag>
                      </div>
                      <div>
                        <div className="ant-list-item-meta-title">
                          {translate("order.details.space.type", "Type")}:{" "}
                        </div>
                        <Tag>{data?.property?.type?.name}</Tag>
                      </div>

                      {
                        <div>
                          <div className="ant-list-item-meta-title">
                            {translate(
                              "order.details.space.use.types",
                              "Use types"
                            )}
                            :{" "}
                          </div>
                          {data?.property?.useTypes?.map((item, index) => {
                            return <Tag key={index}>{item.name}</Tag>;
                          })}
                        </div>
                      }
                    </Space>
                  </div>
                </Col>
              </Row>
            </StyledCardRow>
            <div style={{ margin: "14px" }}>
              <div className="ant-page-header-heading-title">
                {translate("order.details.files", "Images")}
              </div>
            </div>
            <StyledFiles>
              <Row gutter={[16, 16]}>
                {data?.property.images.length > 0 ? (
                  data?.property.images.map((item, index) => {
                    return (
                      <Col
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={4}
                        xxl={4}
                        key={index}
                      >
                        <Image
                          height={170}
                          width={"100%"}
                          style={{ objectFit: "cover", backgroundSize: "fill" }}
                          src={item?.url}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <div>{translate("order.details.no.data", "No data")}</div>
                )}
              </Row>
            </StyledFiles>
          </PageContent>
        )}
      </ContainerStyled>
      <StyledModal>
        <div className="container">
          <Modal
            centered
            width={700}
            title={translate("order.details.model.dates", "Edit picked dates")}
            className={{ title: "font-semibold" }}
            open={calendarOpen}
            onCancel={() => setCalendarOpen(false)}
            onOk={() =>
              selectedDateRange?.length < 1 || !totalAmount
                ? message.error("Bolkushde bolkus")
                : update()
            }
            loading={verifyLoading}
          >
            <WrapperContent>
              <Row gutter={[16, 16]}>
                <Col className="firstCalendarItem" span={14}>
                  <NewCalendarForm
                    minRange={data?.property?.minimumDay}
                    minRequired={data?.property?.minRequired}
                    gahaiError={setGahaiError}
                    setDisable={setDisabledDates}
                    setRange={setSelectedDateRange}
                    disabledDates={[
                      ...(disabledDates || []),
                      ...getDates(
                        selectedDateRange[0]?.startDate,
                        selectedDateRange[0]?.endDate
                      ).map((c) => moment(c).format("YYYY/MM/DD")),
                    ]}
                    isBalai={true}
                  />
                  <div className="gahaiError">
                    {gahaiError[0] ? (
                      <div className="gahaiError1">
                        利用できない日が含まれています。
                      </div>
                    ) : null}
                    <>
                      {gahaiError[1] ? (
                        data?.property?.minRequired ? (
                          <div className="gahaiError2">
                            The days you choose is at least{" "}
                            {data?.property?.minimumDay}
                          </div>
                        ) : (
                          <div className="gahaiError3">
                            Owners minimum day of minimum range is{" "}
                            {data?.property?.minimumDay}. It is possible that
                            your selected date range is gonna decline.
                          </div>
                        )
                      ) : null}
                    </>
                  </div>
                </Col>
                <Col className="datessss" span={10}>
                  {selectedDateRange?.length > 0 ? (
                    selectedDateRange?.map((item, index) => (
                      <div key={index} className="secondDateItem">
                        <CalendarIcon
                          style={{
                            width: "24px",
                            height: "24px",
                            color: "#f2670a",
                          }}
                        />
                        <div className="">
                          <div className="">
                            {dayjs(item.startDate).format("YYYY/MM/DD")} -{" "}
                            {dayjs(item.endDate).format("YYYY/MM/DD")}
                          </div>
                        </div>
                        <div>
                          <a
                            onClick={() => deleteItem(index, item)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <XMarkIcon
                              style={{ width: "24px", height: "24px" }}
                            />
                          </a>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                  <Divider
                    label={<text style={{ fontSize: 14 }}>Total amount</text>}
                  />
                  <NumberInput
                    placeholder="Total amount"
                    icon={<>￥</>}
                    error={totalAmount < 1}
                    hideControls
                    value={totalAmount || data?.amount}
                    onChange={setTotalAmount}
                  />
                  <div
                    style={{
                      border: "1px solid orange",
                      borderRadius: 5,
                      padding: 5,
                      textAlign: "center",
                      color: "orange",
                    }}
                    className="gahaiError3"
                  >
                    {translate(
                      "admin.order.verify.total.alert",
                      "You can change order's total amount. But you have to tell your customer for misunderstanding. "
                    )}
                  </div>
                </Col>
              </Row>
            </WrapperContent>
          </Modal>
        </div>
      </StyledModal>
    </PageContainer>
  );
};

const WrapperContent = styled.div`
  .datessss {
    padding: 0px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .secondDateItem {
      border-radius: 4px 4px;
      border: 1px solid #e4e6ea;
      padding: 5px 10px;
      display: flex;
      gap: 8px;
      align-items: center;
      color: #8d8d8d;
      font-weight: 500;
    }
  }
  .gahaiError {
    margin-top: 15px;

    .gahaiError1 {
      border: 1px solid red;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      color: red;
    }
    .gahaiError2 {
      border: 1px solid red;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      color: red;
    }
    .gahaiError3 {
      border: 1px solid orange;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      color: orange;
    }
  }
`;

const StyledFiles = styled.div`
  padding: 14px;
  background: #fff;
`;
const ContainerStyled = styled.div`
  max-width: 1200px;
`;
const StyledCardRow = styled.div`
  background: #fff;
  padding: 0;
  padding: 24px;
  .chatDetail-orderitems {
    border: 1px solid #e5e7eb;
    padding: 10px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    /* justify-content: space-around; */
    color: black;
    border-radius: 2px;
    margin-top: 10px;

    .chatDetail-date {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .chatDetail-container {
    border: 1px solid #e5e7eb;
    padding: 5px;
    border-radius: 2px;
    margin-top: 10px;
    .chatDetail-dates {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      color: black;
    }
    .chatDetail-titles {
      font-weight: 600;
      color: black;
      margin-top: 10px;
    }
    .items {
      font-weight: 400;
      display: flex;
      justify-content: space-around;
      color: black;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    .ant-list-item-meta-description {
      padding-bottom: 8px;
    }
    .like {
      padding: 8px 12px;
      border: 1px dashed #dadada;
      width: max-content;
      border-radius: 2px;
    }
  }
`;

const StyledModal = styled.div`
  .container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
  }
  .firstCalendarItem {
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rebeccapurple !important;
    padding: 24px;
  }
  .secondDateItem {
    padding: 20px;
    width: 35px;
    background: rebeccapurple !important;
  }
`;

const useHeader = ({ translate }) => [
  {
    title: translate("order.details.useheader.start.date", "Start Date"),
    width: "200px",
    render: (row) => {
      return <Tag> {dateFormat(row?.startDate)}</Tag>;
    },
  },
  {
    title: translate("order.details.useheader.end.date", "End Date"),
    width: "200px",
    render: (row) => {
      return <Tag> {dateFormat(row?.endDate)}</Tag>;
    },
  },
];
export default Detail;
