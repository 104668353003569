/* eslint-disable key-spacing */
import { Modal, Row, Col, message, Select, Divider } from "antd";
import React from "react";
import styled from "styled-components";
import { Loader } from "../../../components";
import { property as propertyApi } from "../../../apis";

import { useTranslate } from "../../../hooks";
import dayjs from "dayjs";
import { CalendarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import NewCalendarForm from "../../../components/Calendar/NewCalendarForm";
import { Overlay } from "@mantine/core";

const CalendarModal = ({ open, setOpen, reload }) => {
  const [loading, setLoading] = React.useState("");
  const { translate } = useTranslate();
  const [selectedDateRange, setSelectedDateRange] = React.useState([]);
  const [gahaiError, setGahaiError] = React.useState([false, false]);
  const [type, setType] = React.useState("UNAVAILABLE");

  const [disabledDates, setDisabledDates] = React.useState([]);

  const selectTypes = [
    {
      label: translate("app.constant.special", "Special price"),
      value: "SPECIAL_PRICE",
    },
    {
      label: translate("app.constant.unavailable", "Unavailable"),
      value: "UNAVAILABLE",
    },
    { label: translate("app.constant.inquire", "Inquire"), value: "INQUIRE" },
  ];

  const getDates = (startDate, endDate) => {
    const daysbetween =
      Math.floor((Date.parse(startDate) - Date.parse(endDate)) / 86400000) - 1;

    const dates = Array(-daysbetween || 0).fill("");
    return dates?.map((date, index) => {
      if (index === 0) {
        return startDate;
      }
      if (index === -daysbetween) {
        return endDate;
      }
      return moment(startDate, "YYYY-MM-DD").add(index, "days");
    });
  };

  const deleteItem = (indexs, item) => {
    let array = selectedDateRange;
    array = array.filter(function (item, index) {
      return index !== indexs;
    });
    setSelectedDateRange(array);
    const res = getDates(item.startDate, item.endDate).map((item) =>
      moment(item)?.format("YYYY/MM/DD")
    );

    setDisabledDates(disabledDates.filter((val) => !res.includes(val)));
  };

  const findProperty = async () => {
    setLoading("get");
    const res = await propertyApi.calendarGet({
      type: type,
      propertyId: open[1]._id,
    });

    const united = res.calendars.map((c) =>
      getDates(c.startDate, c.endDate).map((d) =>
        moment(d).format("YYYY/MM/DD")
      )
    );
    setDisabledDates(united.flat());

    setSelectedDateRange(
      res?.calendars?.map((c) => ({
        startDate: c.startDate,
        endDate: c.endDate,
      }))
    );
    setLoading("");
  };

  const onSubmit = async () => {
    setLoading("submit");
    try {
      await propertyApi.calendar({
        dates: selectedDateRange,
        propertyId: open[1]._id,
        type: type,
      });
      message.success(translate("system.request.success", "Success!"));
      setLoading("");
      reload();
      setOpen([]);
    } catch (error) {
      message.error(
        error?.message || translate("system.request.failed", "Failed!")
      );
      setLoading("");
    }
  };

  React.useEffect(() => {
    if (open[1]) {
      findProperty();
    }
  }, [open[1], type]);

  return (
    <Modal
      centered
      width={700}
      title={translate("order.details.model.dates", "Edit picked dates")}
      className={{ title: "font-semibold" }}
      open={open[0]}
      onCancel={() => setOpen([])}
      onOk={() => onSubmit()}
      loading={loading === "submit"}
    >
      <WrapperContent>
        {loading === "get" && <Overlay />}
        <Row
          className="selectSection"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
          span={14}
        >
          <Select
            style={{ width: "150px" }}
            value={type}
            onChange={(e) => setType(e)}
          >
            {selectTypes.map((type, index) => (
              <Select.Option key={index} value={type.value}>
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </Row>
        <Divider style={{ marginBottom: "10px", marginTop: "10px" }} />
        <Row gutter={[16, 16]}>
          <Col className="firstCalendarItem" span={14}>
            <NewCalendarForm
              minRange={open[1]?.minimumDay}
              minRequired={false}
              gahaiError={setGahaiError}
              setDisable={setDisabledDates}
              setRange={setSelectedDateRange}
              disabledDates={disabledDates}
              isBalai={false}
            />
            <div className="gahaiError">
              {gahaiError[0] ? (
                <div className="gahaiError1">
                  利用できない日が含まれています。
                </div>
              ) : null}
            </div>
          </Col>
          <Col className="datessss" span={10}>
            {selectedDateRange?.length > 0 ? (
              selectedDateRange?.map((item, index) => (
                <div key={index} className="secondDateItem">
                  <CalendarIcon
                    style={{ width: 24, height: "24px", color: "#f2670a" }}
                  />
                  {dayjs(item.startDate).isSame(item.endDate) ? (
                    <div className="">
                      {dayjs(item.startDate).format("YYYY/MM/DD")}
                    </div>
                  ) : (
                    <div className="">
                      {dayjs(item.startDate).format("YYYY/MM/DD")} -{" "}
                      {dayjs(item.endDate).format("YYYY/MM/DD")}
                    </div>
                  )}
                  <div>
                    <a
                      onClick={() => deleteItem(index, item)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <XMarkIcon style={{ width: "24px", height: "24px" }} />
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <div className="secondDateItem">
                <CalendarIcon
                  style={{ width: 24, height: "24px", color: "gray" }}
                />
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginRight: 10,
                  }}
                >
                  No date{" "}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </WrapperContent>
    </Modal>
  );
};

const WrapperContent = styled.div`
  margin-top: -14px;
  .datessss {
    padding: 0px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid #f8f7fa;
    max-height: 400px;
    height: 400px;
    overflow-y: scroll;
    overflow: scroll;

    .secondDateItem {
      border-radius: 4px 4px;
      border: 1px solid #e4e6ea;
      padding: 5px 10px;
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
      color: #8d8d8d;
      font-weight: 500;
    }
  }
  .gahaiError {
    margin-top: 15px;

    .gahaiError1 {
      border: 1px solid red;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      color: red;
    }
    .gahaiError2 {
      border: 1px solid red;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      color: red;
    }
    .gahaiError3 {
      border: 1px solid orange;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      color: orange;
    }
  }
`;

export default CalendarModal;
