import React from "react";
import * as Yup from "yup";
import { income as incomeApi } from "../../../apis";
import { Button, Form as AntForm, message, Row } from "antd";
import { Formik } from "formik";
import { DatePicker, Form, FormItem } from "formik-antd";
import { useTranslate } from "../../../hooks";

const FormCompoents = ({ onCancel, setAction, reload, type }) => {
  const { translate } = useTranslate();
  const FormSchema = Yup.object().shape({
    date: Yup.string()
      .required(translate("system.regex.required", "required field"))
  });

  const onSubmit = async (data) => {
    try {
      await incomeApi.calculate({
        date: data.date,
        type: type
      });
      setAction([]);
      message.success(translate("system.request.success", "Your request is successfull!"));
      reload();
    } catch (err) {
      message.error(err.message || "Failed");
    }
  };

  return (
    <div>
      <Formik
        initialValues={{ date: undefined }}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => {
          return (
            <Form >
              <FormItem name="date" label={translate("calculate.select.month", "Select month")} required>
                <DatePicker.MonthPicker
                  name="date"
                  style={{ width: "100%" }}
                  allowClear
                  placeholder={translate("calculate.select.month", "Select month")}
                />
              </FormItem>
              <Row justify="end">
                <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>{translate("system.btn.confrim", "Confirm")}</Button>
                <Button className="button" type="default" onClick={onCancel}>{translate("system.btn.cancel", "Cancel")}</Button>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormCompoents;