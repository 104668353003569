import { Button, Col, message, Space, Spin } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { document as documentApi } from "../../../apis";
import styled from "styled-components";
import DraftField from "../../../components/DraftCke";
import { useTranslate } from "../../../hooks";
import { Loader } from "../../../components";

const AboutForm =({ type }) => {
  const FormSchema = () => Yup.object().shape({

  });
  const { translate } = useTranslate();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    body: undefined,
    type: type,
  });

  const onSubmit = async (value) => {
    try {
      await documentApi.create(value);
      message.success(translate("document.create.request.success", "Your request is successfull!"));
    } catch (error) {
      if (error.message)
        message.error(error.message || translate("document.create.request.failed", "Your request is unsuccessfull!"));
    }
  };

  const reload = async () => {
    try {
      setLoad(true);
      let res = await documentApi.get(type);
      setData({ type: type, body: res?.body || "" });
      setLoad(false);
    } catch (error) {
      message.error(error.message || translate("document.create.request.failed", "Your request is unsuccessfull!"));
    }
    setLoad(false);
  };

  useEffect(() => {
    reload();
  }, []);

  // const RenderHTML=(value)=>{
  //   return (
  //     <div id="body" dangerouslySetInnerHTML={ { __html: value } } />
  //   );
  // };

  if (load) return <Loader />;
  return (
    <Col span={24} >
      <Container>
        <Formik
          initialValues={data}
          validationSchema={() => FormSchema()}
          onSubmit={onSubmit}>
          {({ isSubmitting, values }) => {
            return (
              <Form layout="vertical" >
                <Spin spinning={isSubmitting}>
                  <DraftField
                    name="body"
                    initialValue={values.body || ""}
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    maxLength={4000}
                  />
                </Spin>
                <Space style={{ marginTop: "10px" }}>
                  <Button htmlType="submit" type="primary" loading={isSubmitting} >
                    {translate("system.btn.save", "Save")}
                  </Button>
                </Space>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Col>
  );
};

const Container = styled.div`
    
`;

export default AboutForm;