
import { BorderlessTableOutlined, HomeOutlined } from "@ant-design/icons";
import { CalendarIcon, HashtagIcon } from "@heroicons/react/24/solid";
import { Avatar, Col, Row, Space, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { message as messageApi } from "../../apis";
import { Loader } from "../../components";
import { PageContent } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import {
  dateFormat,
  datetimeFormat,
  postCode,
  renderUser,
  truncate,
  yen,
} from "../../utils";
import Chat from "./components/Chat";

const Details = () => {
  const params = useParams();
  const { translate } = useTranslate();
  const [data, setData] = React.useState();
  const [loading, setLoading] = useState(false);
  const { webhost } = useSelector(state => state.general);

  const reload = async () => {
    setLoading(true);
    let res = await messageApi.get(params.id);
    setData(res);
    setLoading(false);
  };

  useEffect(() => {
    reload();
  }, [params.id]);

  if (loading && !data) return <Loader />;

  return (
    <div>
      {/* <PageHeader title={translate("conversation.details.pageheader.title", "Chat details")} /> */}
      <PageContent>
        <ChatContainer>
          <ChatStyled>
            <div className="header">
              {renderUser(data?.tenant)}
              {renderUser(data?.owner)}
            </div>
            <div className="chat-body">
              <Chat data={data} />
            </div>
          </ChatStyled>
          <InfoStyled>
            <div className="header">
              <Tag color="orange">{translate("app.order.shortid", "Order ID: ")}{data?.order?.orderId}</Tag>
            </div>
            <div className="info-content">
              <div className="info-title">
                {translate("system.details.property", "Property")}
              </div>
              <div className="project-list">
                <ProjectCard>
                  <div className="item">
                    <a
                      onClick={() =>
                        window.open(
                          `${webhost}/preview/${data?.property?._id}`
                        )
                      }
                      className="avatar"
                    >
                      <Avatar
                        src={data?.property?.image}
                        shape="square"
                        className="img"
                      />
                      <div className="button">
                        {translate("system.list.action.detail", "Detail")}
                      </div>
                    </a>
                    <div className="text-container">
                      {!data?.property?.negotiable ? (
                        <div>
                          <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                            <div style={{ fontWeight: "bold", fontSize: "1.125rem", color: "#ff5722" }}>
                            ¥ {data?.property?.weekTax?.toLocaleString()}
                            </div>
                            <div style={{ fontWeight: "bold", fontSize: "1.125rem", color: "#ff5722" }}>/</div>
                            <div style={{ fontWeight: "bold", fontSize: "1.125rem", color: "#ff5722" }}>
                              {translate("space.detail.week", "Week")}
                            </div>
                          </div>
                          {data?.property?.useMultiPrice && (<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                            <div style={{ fontWeight: "bold", fontSize: "1.125rem", color: "#ff5722" }}>
                            ¥ {data?.property?.weekendTax?.toLocaleString()}
                            </div>
                            <div style={{ fontWeight: "bold", fontSize: "1.125rem", color: "#ff5722" }}>/</div>
                            <div style={{ fontWeight: "bold", fontSize: "1.125rem", color: "#ff5722" }}>
                              {translate("space.detail.weekend", "Weekend")}
                            </div>
                          </div>)}
                          {data?.property?.useSpecialPrice && (<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                            <div style={{ fontWeight: "bold", fontSize: "1.125rem", color: "#ff5722" }}>
                            ¥ {data?.property?.specialTax?.toLocaleString()}
                            </div>
                            <div style={{ fontWeight: "bold", fontSize: "1.125rem", color: "#ff5722" }}>/</div>
                            <div style={{ fontWeight: "bold", fontSize: "1.125rem", color: "#ff5722" }}>
                              {translate("space.detail.special", "Special")}
                            </div>
                          </div>)}
                        </div>
                      ) : (
                        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                          <div style={{ fontWeight: "bold", fontSize: "1.125rem", color: "#ff5722" }}>
                            {translate("app.property.detail.negotiable", "Negotiable")}
                          </div>
                        </div>
                      )}
                      <h3 className="title">
                        {truncate(data?.property?.title, 30)}
                      </h3>
                      <div>{postCode(data?.property?.address?.postCode)}</div>
                      <div>
                        {data?.property?.address?.city}{" "}
                        {data?.property?.address?.address}{" "}
                        {data?.property?.address?.buildingName}{" "}
                      </div>
                      <div className="footer">
                        <div>
                          {" "}
                          <HashtagIcon
                            style={{ height: "16px", color: "#7d1bf5" }}
                          ></HashtagIcon>
                          {data?.property?.type?.name}
                        </div>
                        <div className="">
                          <HomeOutlined style={{ color: "#7d1bf5" }} />{" "}
                          {data?.property?.space}㎡
                        </div>
                      </div>
                    </div>
                  </div>
                </ProjectCard>
              </div>
              <div className="chatDetail-container">
                <h3 className="chatDetail-dates">
                  {translate(
                    "app.chat.detail.desiredPeriod",
                    "Desired usage period"
                  )}
                  <Tag color="#f97318">{translate(`system.orderstatus.${data?.order?.orderStatus}`, data?.order?.orderStatus)}</Tag>
                </h3>
                <div>
                  {/* {data?.order?.orderItems.map((item, index) => {
                    return (
                      <div key={index}> */}
                  <div className="chatDetail-orderitems">
                    <CalendarIcon
                      style={{ height: "20px", color: "#f97318", }}
                    />
                    <div className="chatDetail-date">
                      {data?.order?.startDate === data?.order?.endDate ? (
                        <div>
                          {moment(data?.order?.startDate).format("YYYY/MM/DD")}
                        </div>
                      ) : (
                        <div>
                          {moment(data?.order?.startDate).format("YYYY/MM/DD")} -{" "}
                          {moment(data?.order?.endDate).format("YYYY/MM/DD")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div />
                  {/* </div>
                    );
                  })} */}
                </div>
              </div>
              <div className="chatDetail-container">
                <h3>
                  {translate("app.chat.detail.aboutPlanning", "About planning")}
                </h3>
                <div className="chatDetail-titles">
                  {translate("app.chat.detail.usageDetails", "Usage details")}
                </div>
                <div className="">{data?.order?.useType}</div>
                <div className="chatDetail-titles">
                  {translate("app.chat.detail.projectName", "Project name")}
                </div>
                <div className="">{data?.order?.businessName}</div>
                <div className="chatDetail-titles">
                  {translate("app.chat.detail.planContents", "Plan contents")}
                </div>
                <div className="">{data?.order?.businessDesc}</div>
                <div className="chatDetail-titles">
                  {translate(
                    "app.chat.detail.eating",
                    "Eating and drinking / tasting"
                  )}
                </div>
                <div className="">
                  {data?.order?.isProvisional
                    ? translate("app.chat.detail.provided", "Provided")
                    : translate("app.chat.detail.no", "No")}
                </div>
                <div className="chatDetail-titles">
                  {translate("app.chat.detail.inquiry", "Inquiry")}
                </div>
                <div className="">{data?.order?.inquiry}</div>
              </div>

              <div className="chatDetail-cancelpolicy">
                <div className="policy">
                  <div> {translate("space.detail.policy1", "From 61 to 80 days before the start date")}
                  </div>
                  <div className="percent">
                    {translate("space.detail.policy.percents", "% of usage fee")}
                    <div style={{ fontWeight: 600 }}>
                      {data?.property?.cancelPolicy?.pcy1}%
                    </div>
                  </div>
                </div>
                <div className="policy">
                  <div>
                    {translate(
                      "space.detail.policy2",
                      "From 31 to 60 days before the start date"
                    )}
                  </div>
                  <div className="percent">
                    {translate("space.detail.policy.percents", "% of usage fee")}
                    <div style={{ fontWeight: 600 }}>
                      {data?.property?.cancelPolicy?.pcy2}%
                    </div>
                  </div>
                </div>
                <div className="policy">
                  <div>
                    {translate(
                      "space.detail.policy3",
                      "Up to 15 to 30 days before the start date"
                    )}
                  </div>
                  <div className="percent">
                    {translate("space.detail.policy.percents", "% of usage fee")}
                    <div style={{ fontWeight: 600 }}>
                      {data?.property?.cancelPolicy?.pcy3}%
                    </div>
                  </div>
                </div>
                <div className="policy">
                  <div>
                    {translate(
                      "space.detail.policy4",
                      "From 0 to 14 days before the start date"
                    )}
                  </div>
                  <div className="percent">
                    {translate("space.detail.policy.percents", "% of usage fee")}
                    <div style={{ fontWeight: 600 }}>
                      {data?.property?.cancelPolicy?.pcy4}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </InfoStyled>
        </ChatContainer>
      </PageContent>
    </div>
  );
};

const ProjectCard = styled.div`
  width: 100%;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-container {
      border: 1px solid #e5e7eb;
      padding: 5px;
      border-radius: 2px;
      width: 100%;

      .footer {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        div {
          font-size: 16px;
        }
      }
    }

    .title {
      font-weight: 600;
      color: black;
    }
    .avatar {
      position: relative;
      cursor: pointer;
      border: 1px solid #e5e7eb;
      padding: 5px;
      border-radius: 2px;
      object-fit: contain;
      width: 100%;
      height: 300px;

      .img {
        height: 100%;
        width: 100%;
      }

      .button {
        position: absolute;
        height: 40px;
        border-radius: 5px;
        width: 80%;
        background-color: #f1f0f5;
        color: black;
        font-size: 18px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        bottom: 20px;
        left: 10%;
        opacity: 80%;

        :hover {
          opacity: 100%;
        }
      }
    }
  }
`;
const ChatStyled = styled.div`
  width: 100%;
  height: 100%;

  .chat-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
// flex gap-3 items-center mt-2 flex-wrap

const InfoStyled = styled.div`
  max-width: 500px;
  width: 100%;
  border: 1px solid #dadada;
  border-top: none;
  border-bottom: none;
  overflow-y: scroll;
  height: 100vh;

  .item {
    display: flex;
    gap: 10px;
  }

  .info-content {
    padding: 14px;
    max-height: 90vh;
    height: 100%;

    .info-title {
      font-size: 18px;
      font-weight: 500;
      padding: 8px 0;
    }
  }
  .chatDetail-orderitems {
    border: 1px solid #e5e7eb;
    padding: 10px ;
    font-weight: 400;
    display: flex;
    gap: 10px;
    /* justify-content: space-around; */
    color: black;
    border-radius: 2px;
    margin-top: 10px;

    .chatDetail-date{
      width: 100%;
      display: flex;
      justify-content: center;

    }
  }
  .chatDetail-container {
    border: 1px solid #e5e7eb;
    padding: 5px;
    border-radius: 2px;
    margin-top: 10px;
    .chatDetail-dates {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      color: black;
    }
    .chatDetail-titles {
      font-weight: 600;
      color: black;
      margin-top: 10px;
    }
    .items {
      font-weight: 400;
      display: flex;
      justify-content: space-around;
      color: black;
    }
  }
    .chatDetail-cancelpolicy {
    border: 1px solid #e5e7eb;
    padding: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 500px;
    width: 100%;
    .policy {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .percent {
      display: flex;
    }
  }
`;

const ChatContainer = styled.div`
  background: #fff;
  display: flex;
  max-width: 1500px;
  max-height: calc(100vh - 120px);

  .header {
    border-bottom: 1px solid #dadada;
    padding: 10px 14px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export default Details;
