import { useSelector } from "react-redux";

export default () => {
  const { user, permission } = useSelector(state => state.general);
  console.log("general.permission", permission);

  return {
    checkPermission: (code) => {
      if (user?.role === "SUPER_ADMIN")
        return true;
      if (permission.find(p => p?.role === user?.role && p?.code === code && p.isAccept))
        return true;
      return false;
    }
  };
};