/* eslint-disable indent */
/* eslint-disable key-spacing */
import React from "react";
import * as yup from "yup";
import { Formik, useFormikContext } from "formik";
import { Form as AntForm, Row, Col, Button, message } from "antd";
import {
  GoogleMapPin,
  ImageTextMultiUpload,
  ImageUpload,
} from "../../components";
import {
  FormItem,
  Input,
  Select,
  Form,
  TimePicker,
  InputNumber,
  Checkbox,
} from "formik-antd";
import { formItemLayout } from "../../utils";
import {
  equipment as equipmentApi,
  propertyType as propertyTypeApi,
  useType as useTypeApi,
} from "../../apis";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import useFetch from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import { useTranslate } from "../../hooks";
import InputMask from "react-input-mask";

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();
      let errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        return false;
      }

      return values;
    },
    async isValid() {
      let errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        return false;
      }

      return true;
    },
  }));

  return null;
});

const { TextArea } = Input;

export default React.forwardRef((props, ref) => {
  const { translate } = useTranslate();
  const { action, onSubmit } = props;
  const submitRef = React.useRef();
  const [editorState, setEditorState] = React.useState(
    action && action[0] === "update"
      ? (() => {
          const contentBlock = htmlToDraft(action[1]?.userManual || "<p></p>");
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);

            return editorState;
          }

          return EditorState.createEmpty();
        })()
      : EditorState.createEmpty()
  );
  const [detailState, setDetailState] = React.useState(
    action && action[0] === "update"
      ? (() => {
          const contentBlock = htmlToDraft(action[1]?.details || "<p></p>");
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);

            return editorState;
          }

          return EditorState.createEmpty();
        })()
      : EditorState.createEmpty()
  );
  const [introState, setIntroState] = React.useState(
    action && action[0] === "update"
      ? (() => {
          const contentBlock = htmlToDraft(action[1]?.intro || "<p></p>");
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);

            return editorState;
          }
          return EditorState.createEmpty();
        })()
      : EditorState.createEmpty()
  );
  const [contractstate, setContractState] = React.useState(
    action && action[0] === "update"
      ? (() => {
          const contentBlock = htmlToDraft(action[1]?.contract || "<p></p>");
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);

            return editorState;
          }

          return EditorState.createEmpty();
        })()
      : EditorState.createEmpty()
  );
  const [equipments] = useFetch(equipmentApi.select)([]);
  const [types] = useFetch(propertyTypeApi.select)([]);
  const [useTypes] = useFetch(useTypeApi.select)([]);
  const { policies } = useSelector((state) => state.general);

  const FormSchema = () =>
    yup.object({
      type: yup
        .string()
        .typeError(translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      name: yup
        .string()
        .typeError(translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      title: yup
        .string()
        .typeError(translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      intro: yup
        .string()
        .min(9, translate("system.regex.required", "required field"))
        .typeError(translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      details: yup
        .string()
        .min(9, translate("system.regex.required", "required field"))
        .typeError(translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      startTime: yup
        .date()
        .typeError(translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      endTime: yup
        .date()
        .typeError(translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      negotiable: yup
        .boolean()
        .typeError(translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      space: yup
        .number()
        .typeError(translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      minimumDay: yup
        .number()
        .typeError(translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      minRequired: yup
        .boolean()
        .required(translate("system.regex.required", "required field")),
      planImage: yup.string().optional().nullable(),
      feeNotes: yup
        .string()
        .required(translate("system.regex.required", "required field")),
      images: yup
        .array()
        .of(
          yup.object({
            description: yup.string(),
          })
        )
        .min(1, translate("system.regex.required", "required field"))
        .required(translate("system.regex.required", "required field")),
      furnitures: yup
        .array()
        .of(yup.string().required())
        .min(1)
        .required(translate("system.regex.required", "required field")),
      features: yup
        .array()
        .of(yup.string().required())
        .min(1)
        .required(translate("system.regex.required", "required field")),
      facilities: yup
        .array()
        .of(yup.string().required())
        .min(1)
        .required(translate("system.regex.required", "required field")),
      furnitureOther: yup.string().max(10000).optional().nullable(),
      featureOther: yup.string().max(10000).optional().nullable(),
      facilityOther: yup.string().max(10000).optional().nullable(),
      useTypes: yup
        .array()
        .of(yup.string().required())
        .min(1)
        .required(translate("system.regex.required", "required field")),
      postCode: yup
        .string()
        .required(translate("system.regex.required", "required field")),
      city: yup
        .string()
        .required(translate("system.regex.required", "required field")),
      buildingName: yup.string().nullable(),
      address: yup
        .string()
        .required(translate("system.regex.required", "required field")),
      location: yup
        .array()
        .of(
          yup
            .number()
            .typeError(translate("system.regex.required", "required field"))
            .required(translate("system.regex.required", "required field"))
        )
        .length(2)
        .required(translate("system.regex.required", "required field")),
      userManual: yup
        .string()
        .nullable(),
      contract: yup
        .string()
        .nullable(),
      station: yup
        .string()
        .required(translate("system.regex.required", "required field")),
      cancelPolicy: yup
        .object({
          pcy1: yup
            .number()
            .typeError(translate("system.regex.required", "required field"))
            .required(translate("system.regex.required", "required field")),
          pcy2: yup
            .number()
            .typeError(translate("system.regex.required", "required field"))
            .required(translate("system.regex.required", "required field")),
          pcy3: yup
            .number()
            .typeError(translate("system.regex.required", "required field"))
            .required(translate("system.regex.required", "required field")),
          pcy4: yup
            .number()
            .typeError(translate("system.regex.required", "required field"))
            .required(translate("system.regex.required", "required field")),
        })
        .required(translate("system.regex.required", "required field")),
      weekPrice: yup
        .number()
        .when("negotiable", {
          is: false,
          then: yup
            .number()
            .min(
              3000,
              translate(
                "app.space.step5.dailypricemin",
                "Price field must be at least 3000Yen"
              )
            )
            .typeError(translate("system.regex.required", "required field"))
            .required(translate("system.regex.required", "required field")),
        })
        .nullable(),
      weekendPrice: yup
        .number()
        .when(["negotiable", "useMultiPrice"], {
          is: (negotiable, useMultiPrice) => !negotiable && useMultiPrice,
          then: yup
            .number()
            .min(
              3000,
              translate(
                "app.space.step5.dailypricemin",
                "Price field must be at least 3000Yen"
              )
            )
            .typeError(translate("system.regex.required", "required field"))
            .required(translate("system.regex.required", "required field")),
        })
        .nullable(),
      specialPrice: yup
        .number()
        .when(["negotiable", "useMultiPrice", "useSpecialPrice"], {
          is: (negotiable, useMultiPrice, useSpecialPrice) =>
            !negotiable && useMultiPrice && useSpecialPrice,
          then: yup
            .number()
            .min(
              3000,
              translate(
                "app.space.step5.dailypricemin",
                "Price field must be at least 3000Yen"
              )
            )
            .typeError(translate("system.regex.required", "required field"))
            .required(translate("system.regex.required", "required field")),
        })
        .nullable(),
      useMultiPrice: yup
        .boolean()
        .when("negotiable", {
          is: false,
          then: yup
            .boolean()
            .required(translate("system.regex.required", "required field")),
        })
        .nullable(),
      useSpecialPrice: yup
        .boolean()
        .when("negotiable", {
          is: false,
          then: yup
            .boolean()
            .required(translate("system.regex.required", "required field")),
        })
        .nullable(),
    });

  const [data] = React.useState({
    type: undefined,
    name: undefined,
    title: undefined,
    intro: undefined,
    details: undefined,
    startTime: "2023-05-01T16:00:00.000Z",
    endTime: "2023-05-01T16:00:00.000Z",
    dailyPrice: undefined,
    space: undefined,
    feeNotes: undefined,
    weekTax: undefined,
    cancelPolicy: {
      pcy1: 0,
      pcy2: 20,
      pcy3: 50,
      pcy4: 100,
    },
    minimumDay: undefined,
    minRequired: false,
    images: [],
    planImage: undefined,
    furnitures: undefined,
    features: undefined,
    facilities: undefined,
    furnitureOther: undefined,
    featureOther: undefined,
    facilityOther: undefined,
    useTypes: undefined,
    postCode: undefined,
    city: undefined,
    buildingName: undefined,
    address: undefined,
    location: undefined,
    userManual: undefined,
    station: undefined,
    useMultiPrice: false,
    useSpecialPrice: false,
    negotiable: false,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    },
  }));

  function getCoordinates(address, setFieldValue, toroi) {
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        address +
        "&key=AIzaSyAS74VkA2YA0FBA8w7lOJiGwb1mqXFhplw" +
        "&language=ja"
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "OK") {
          const address = data?.results[0]?.address_components
            ?.filter((i) => i?.types?.includes("sublocality"))
            .map((y) => y?.short_name)
            .reverse()
            .toString()
            .replace(",", "");
          if (!toroi) {
            setFieldValue("address", address.toString());
            setFieldValue(
              "city",
              `${
                data?.results[0]?.address_components?.find((o) =>
                  o.types?.includes("administrative_area_level_1")
                )?.short_name
              }${
                data?.results[0]?.address_components?.find((o) =>
                  o.types?.includes("locality")
                )?.short_name
              }`
            );
          }

          setFieldValue("location", [
            data?.results[0]?.geometry?.location?.lat,
            data?.results[0]?.geometry?.location?.lng,
          ]);
        } else {
          console.log("getCordError", data);
          message.error(
            translate(
              "property.new.form.getcoordinate.error",
              "Could not find coordinate"
            )
          );
        }
      });
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema()}
        onSubmit={onSubmit}
      >
        {({ errors, values, setFieldValue }) => {
          console.log(errors);
          console.log(values);
          return (
            <Form {...formItemLayout}>
              <FormItem
                label={translate("property.form.space.type", "Space type")}
                name="type"
                required
              >
                <Select
                  name="type"
                  placeholder={translate(
                    "property.form.space.type",
                    "Space type"
                  )}
                >
                  {types.map((type, index) => (
                    <Select.Option key={index} value={type._id}>
                      {type.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                name="name"
                label={translate("property.form.space.name", "Space name")}
                required
              >
                <Input
                  prefix={<></>}
                  name="name"
                  placeholder={translate(
                    "property.form.space.name",
                    "Space name"
                  )}
                />
              </FormItem>
              <FormItem
                name="title"
                label={translate("property.form.title", "Title")}
                required
              >
                <Input
                  prefix={<></>}
                  name="title"
                  placeholder={translate("property.form.title", "Title")}
                />
              </FormItem>
              <FormItem
                label={translate("property.form.introdution", "Introduction")}
                name="intro"
                required
              >
                <Editor
                  editorState={introState}
                  defaultEditorState={ContentState.createFromBlockArray(
                    convertFromHTML(values.intro || "<p></p>")
                  )}
                  editorClassName="user-manual-editor"
                  onEditorStateChange={(editorState) => {
                    setIntroState(editorState);
                    setFieldValue(
                      "intro",
                      draftToHtml(convertToRaw(editorState.getCurrentContent()))
                    );
                  }}
                />
              </FormItem>
              <FormItem
                label={translate(
                  "property.form.space.details",
                  "Space details"
                )}
                name="details"
                required
              >
                <Editor
                  editorState={detailState}
                  defaultEditorState={ContentState.createFromBlockArray(
                    convertFromHTML(values.details || "<p></p>")
                  )}
                  editorClassName="user-manual-editor"
                  onEditorStateChange={(editorState) => {
                    setDetailState(editorState);
                    setFieldValue(
                      "details",
                      draftToHtml(convertToRaw(editorState.getCurrentContent()))
                    );
                  }}
                />
              </FormItem>
              <FormItem
                name="minimumDay"
                label={translate("property.form.minimumday", "Minimum day")}
                required
              >
                <InputNumber
                  controls={false}
                  style={{ width: "100%" }}
                  prefix={<></>}
                  type="number"
                  name="minimumDay"
                  placeholder={translate(
                    "property.form.minimumday",
                    "Minimum day"
                  )}
                />
              </FormItem>
              <FormItem
                name="minRequired"
                label={translate(
                  "property.form.minimumday.required",
                  "Minimum days required ?"
                )}
              >
                <Checkbox name="minRequired" />
              </FormItem>
              <FormItem
                name="negotiable"
                label={translate("property.form.negotiable", "Negotiable")}
              >
                <Checkbox
                  name="negotiable"
                  onChange={() => {
                    setFieldValue("weekPrice", undefined);
                    setFieldValue("weekendPrice", undefined);
                    setFieldValue("specialPrice", undefined);
                    setFieldValue("useMultiPrice", false);
                    setFieldValue("useSpecialPrice", false);
                  }}
                />
              </FormItem>
              <FormItem
                name="useMultiPrice"
                label={translate("property.form.usemultiprice", "Multi price")}
                hidden={values.negotiable}
              >
                <Checkbox
                  name="useMultiPrice"
                  onChange={() => {
                    setFieldValue("weekPrice", undefined);
                    setFieldValue("weekendPrice", undefined);
                    setFieldValue("specialPrice", undefined);
                    setFieldValue("useSpecialPrice", false);
                  }}
                />
              </FormItem>
              <FormItem
                name="weekPrice"
                label={translate(
                  values.useMultiPrice
                    ? "property.form.weekprice"
                    : "property.form.daily.change",
                  "Week price"
                )}
                required
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <InputNumber
                    disabled={values.negotiable}
                    onChange={(e) => {
                      setFieldValue("weekTax", (e * 10) / 100 + e);
                    }}
                    style={{ width: "100%" }}
                    prefix={<></>}
                    parser={(value) => {
                      return value ? value.replace(/\$\s?|(,*)/g, "") : "";
                    }}
                    formatter={(value) => {
                      return !Number.isNaN(parseFloat(value || ""))
                        ? value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : "";
                    }}
                    controls={false}
                    name="weekPrice"
                    placeholder={translate(
                      values.useMultiPrice
                        ? "property.form.weekprice.taxless"
                        : "property.form.daily.change.tax",
                      "Week price (excluding tax)"
                    )}
                  />
                  <InputNumber
                    name="weekTax"
                    parser={(value) => {
                      return value ? value.replace(/\$\s?|(,*)/g, "") : "";
                    }}
                    formatter={(value) => {
                      return !Number.isNaN(parseFloat(value || ""))
                        ? value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : "";
                    }}
                    disabled={true}
                    style={{ width: "100%" }}
                    prefix={
                      <>
                        {" "}
                        {translate(
                          values.useMultiPrice
                            ? "property.form.weektax"
                            : "property.form.daily.change.tax2",
                          "Week price (including tax)"
                        )}{" "}
                      </>
                    }
                    controls={false}
                  />
                </div>
              </FormItem>

              <FormItem
                name="weekendPrice"
                hidden={!values.useMultiPrice}
                label={translate("property.form.weekendprice", "Weekend price")}
                required
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <InputNumber
                    disabled={values.negotiable}
                    onChange={(e) => {
                      setFieldValue("weekendTax", (e * 10) / 100 + e);
                    }}
                    parser={(value) => {
                      return value ? value.replace(/\$\s?|(,*)/g, "") : "";
                    }}
                    formatter={(value) => {
                      return !Number.isNaN(parseFloat(value || ""))
                        ? value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : "";
                    }}
                    style={{ width: "100%" }}
                    prefix={<></>}
                    controls={false}
                    name="weekendPrice"
                    placeholder={translate(
                      "property.form.weekendprice.taxless",
                      "Weekend price (excluding tax)"
                    )}
                  />
                  <InputNumber
                    name="weekendTax"
                    onChange={(e) => {
                      setFieldValue("weekendTax", (e * 10) / 100 + e);
                    }}
                    parser={(value) => {
                      return value ? value.replace(/\$\s?|(,*)/g, "") : "";
                    }}
                    formatter={(value) => {
                      return !Number.isNaN(parseFloat(value || ""))
                        ? value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : "";
                    }}
                    disabled={true}
                    style={{ width: "100%" }}
                    prefix={
                      <>
                        {" "}
                        {translate(
                          "property.form.weekendtax",
                          "Weekend price (including tax)"
                        )}{" "}
                      </>
                    }
                    controls={false}
                  />
                </div>
              </FormItem>
              <FormItem
                name="useSpecialPrice"
                label={translate(
                  "property.form.usespecialprice",
                  "Use special price"
                )}
                hidden={!values.useMultiPrice && !values.useSpecialPrice}
              >
                <Checkbox
                  name="useSpecialPrice"
                  onChange={() => {
                    setFieldValue("specialPrice", undefined);
                  }}
                />
              </FormItem>
              <FormItem
                name="specialPrice"
                hidden={!values.useMultiPrice && !values.useSpecialPrice}
                label={translate("property.form.specialprice", "Special price")}
                required
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <InputNumber
                    disabled={!values.useSpecialPrice}
                    onChange={(e) => {
                      setFieldValue("specialTax", (e * 10) / 100 + e);
                    }}
                    parser={(value) => {
                      return value ? value.replace(/\$\s?|(,*)/g, "") : "";
                    }}
                    formatter={(value) => {
                      return !Number.isNaN(parseFloat(value || ""))
                        ? value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : "";
                    }}
                    style={{ width: "100%" }}
                    prefix={<></>}
                    controls={false}
                    name="specialPrice"
                    placeholder={translate(
                      "property.form.specialprice.taxless",
                      "Special price (excluding tax)"
                    )}
                  />
                  <InputNumber
                    name="specialTax"
                    disabled={true}
                    style={{ width: "100%" }}
                    parser={(value) => {
                      return value ? value.replace(/\$\s?|(,*)/g, "") : "";
                    }}
                    formatter={(value) => {
                      return !Number.isNaN(parseFloat(value || ""))
                        ? value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : "";
                    }}
                    prefix={
                      <>
                        {translate(
                          "property.form.specialtax",
                          "Special price (including tax)"
                        )}
                      </>
                    }
                    controls={false}
                  />
                </div>
              </FormItem>
              <FormItem
                name="feeNotes"
                label={translate("property.form.fee.note", "Fee note")}
                required
              >
                <TextArea
                  prefix={<></>}
                  name="feeNotes"
                  placeholder={translate(
                    "property.form.fee.note.placeholder",
                    "Fee note"
                  )}
                />
              </FormItem>
              {policies?.map((policy, index) => {
                return (
                  <div key={index}>
                    <div className="mt-3 mb-1">{policy.name}</div>
                    <div className="w-80">
                      <FormItem
                        label={translate("property.form.percent", "Percent")}
                        name={`cancelPolicy.pcy${index + 1}`}
                        required
                      >
                        <Select
                          name={`cancelPolicy.pcy${index + 1}`}
                          placeholder={policy.name}
                        >
                          {policy.values.map((type, index) => (
                            <Select.Option key={index} value={type}>
                              {type}%
                            </Select.Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </div>
                );
              })}

              <FormItem
                name="space"
                label={translate("property.form.space.m", "Space (㎡)")}
                required
              >
                <InputNumber
                  controls={false}
                  style={{ width: "100%" }}
                  type="number"
                  prefix={<></>}
                  name="space"
                  placeholder={translate("property.form.space.m", "Space (㎡)")}
                />
              </FormItem>
              <AntForm.Item
                label={translate(
                  "property.form.available.time",
                  "Available time"
                )}
                required
              >
                <Row>
                  <Col>
                    <FormItem name="startTime" required>
                      <TimePicker
                        name="startTime"
                        format="HH:mm"
                        placeholder={translate(
                          "property.form.start.time",
                          "Start time"
                        )}
                      />
                    </FormItem>
                  </Col>
                  <Col span={1}></Col>
                  <Col>
                    <FormItem name="endTime" required>
                      <TimePicker
                        name="endTime"
                        format="HH:mm"
                        placeholder={translate(
                          "property.form.end.time",
                          "End time"
                        )}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </AntForm.Item>

              <FormItem
                name="images"
                label={translate("property.form.img", "Images")}
                required
              >
                <ImageTextMultiUpload
                  error={errors}
                  action="/api/general/upload"
                  name="images"
                />
              </FormItem>
              <FormItem
                name="planImage"
                label={translate("property.form.plan.img", "Plan image")}
              >
                <ImageUpload action="/api/general/upload" name="planImage" />
              </FormItem>
              <FormItem
                label={translate("property.form.furniture", "Furniture")}
                name="furnitures"
                required
              >
                <Select
                  mode="multiple"
                  name="furnitures"
                  placeholder={translate(
                    "property.form.furniture",
                    "Furniture"
                  )}
                >
                  {equipments
                    .filter((ep) => ep.type === "FURNITURE")
                    .map((eq, index) => (
                      <Select.Option key={index} value={eq._id}>
                        {eq.name}
                      </Select.Option>
                    ))}
                </Select>
              </FormItem>
              <FormItem
                name="furnitureOther"
                label={translate(
                  "property.form.furnitures.other",
                  "Other furnitures"
                )}
              >
                <Input
                  prefix={<></>}
                  name="furnitureOther"
                  placeholder={translate(
                    "property.form.furnitures.other",
                    "Other furnitures"
                  )}
                />
              </FormItem>
              <FormItem
                label={translate("property.form.feature", "Feature")}
                name="features"
                required
              >
                <Select
                  mode="multiple"
                  name="features"
                  placeholder={translate("property.form.feature", "Feature")}
                >
                  {equipments
                    .filter((ep) => ep.type === "FEATURE")
                    .map((eq, index) => (
                      <Select.Option key={index} value={eq._id}>
                        {eq.name}
                      </Select.Option>
                    ))}
                </Select>
              </FormItem>
              <FormItem
                name="featureOther"
                label={translate(
                  "property.form.features.other",
                  "Other feature"
                )}
              >
                <Input
                  prefix={<></>}
                  name="featureOther"
                  placeholder={translate(
                    "property.form.features.other",
                    "Other feature"
                  )}
                />
              </FormItem>
              <FormItem
                label={translate("property.form.facility", "Facility")}
                name="facilities"
                required
              >
                <Select
                  mode="multiple"
                  name="facilities"
                  placeholder={translate("property.form.facility", "Facility")}
                >
                  {equipments
                    .filter((ep) => ep.type === "FACILITY")
                    .map((eq, index) => (
                      <Select.Option key={index} value={eq._id}>
                        {eq.name}
                      </Select.Option>
                    ))}
                </Select>
              </FormItem>

              <FormItem
                name="facilityOther"
                label={translate(
                  "property.form.facility.other",
                  "Other facility"
                )}
              >
                <Input
                  prefix={<></>}
                  name="facilityOther"
                  placeholder={translate(
                    "property.form.facility.other",
                    "Other facility"
                  )}
                />
              </FormItem>
              <FormItem
                label={translate(
                  "property.form.available.use.types",
                  "Available use types"
                )}
                name="useTypes"
                required
              >
                <Select
                  mode="multiple"
                  name="useTypes"
                  placeholder={translate(
                    "property.form.available.use.types",
                    "Available use types"
                  )}
                >
                  {useTypes.map((eq, index) => (
                    <Select.Option key={index} value={eq._id}>
                      {eq.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <hr />

              <FormItem
                name="postCode"
                label={translate("property.form.post.code", "Post code")}
                required
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  {/* <Input
                    style={{ width: "100%" }}
                    controls={false}
                    prefix={<></>}
                    name="postCode"
                    placeholder={translate(
                      "property.form.post.code",
                      "Post code"
                    )}
                  /> */}
                  <InputMask
                    className="ant-input"
                    value={values.postCode}
                    mask="999-9999"
                    name="postCode"
                    onChange={(e) => {
                      const value = e.target.value || "";
                      setFieldValue("postCode", value);
                    }}
                    placeholder={translate(
                      "property.form.post.code",
                      "Post code"
                    )}
                  ></InputMask>
                  <Button
                    disabled={values?.postCode?.length < 3}
                    onClick={() =>
                      getCoordinates(values?.postCode, setFieldValue)
                    }
                  >
                    {translate("property.form.post.code.btn", "Search")}
                  </Button>
                </div>
              </FormItem>

              <FormItem
                name="city"
                label={translate("property.form.city", "City")}
                required
              >
                <Input
                  type="text"
                  name="city"
                  prefix={<></>}
                  placeholder={translate("property.form.city", "City")}
                />
              </FormItem>

              <FormItem
                name="address"
                label={translate("property.form.address", "Address")}
                required
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <Input
                    type="text"
                    name="address"
                    prefix={<></>}
                    placeholder={translate(
                      "property.form.address.placeholder",
                      "Address"
                    )}
                  />{" "}
                  <Button
                    disabled={!values?.city || !values?.address}
                    onClick={() =>
                      getCoordinates(
                        `${values?.city} ${values?.address}`,
                        setFieldValue,
                        "toroi"
                      )
                    }
                  >
                    {translate("property.form.post.address.btn", "Search")}
                  </Button>
                </div>
              </FormItem>

              <FormItem
                name="buildingName"
                label={translate(
                  "property.form.building.name",
                  "Building name"
                )}
              >
                <Input
                  type="text"
                  prefix={<></>}
                  name="buildingName"
                  placeholder={translate(
                    "property.form.building.name",
                    "Building name"
                  )}
                />
              </FormItem>
              <FormItem
                name="station"
                label={translate("property.form.station", "Closest station")}
                required
              >
                <Input
                  type="text"
                  prefix={<></>}
                  name="station"
                  placeholder={translate(
                    "property.form.station.placeholder",
                    "Closest station"
                  )}
                />
              </FormItem>
              <hr />
              <AntForm layout="vertical">
                <FormItem
                  label={translate("property.form.location", "Location")}
                  name="location"
                  required
                >
                  <GoogleMapPin name="location" />
                </FormItem>
              </AntForm>
              <hr />
              {/* <FormItem name="contract" required>
                <div style={{ display: "flex" }}>
                  <div style={{ color: "red" }}>*</div>
                  {translate("property.form.contract", "Usage contract")}
                </div>
                <Editor
                  editorState={contractstate}
                  defaultEditorState={ContentState.createFromBlockArray(
                    convertFromHTML(values.contract || "<p></p>")
                  )}
                  editorClassName="user-manual-editor"
                  onEditorStateChange={(editorState) => {
                    setContractState(editorState);
                    setFieldValue(
                      "contract",
                      draftToHtml(convertToRaw(editorState.getCurrentContent()))
                    );
                  }}
                />
              </FormItem> */}
              <FormItem name="userManual">
                  {translate("property.form.user.manual", "User manual")}
                <Editor
                  editorState={editorState}
                  defaultEditorState={ContentState.createFromBlockArray(
                    convertFromHTML(values.userManual || "<p></p>")
                  )}
                  editorClassName="user-manual-editor"
                  onEditorStateChange={(editorState) => {
                    console.log(editorState);
                    setEditorState(editorState);
                    setFieldValue(
                      "userManual",
                      draftToHtml(convertToRaw(editorState.getCurrentContent()))
                    );
                  }}
                />
              </FormItem>
              {values.images.some((s) => !s.description) ? (
                <></>
              ) : (
                <SubmitForm ref={submitRef} />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});
