
import React, { useTransition } from "react";
import { Button, message } from "antd";
import { policy as policyApi } from "../../apis";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as yup from "yup";
import { useTranslate } from "../../hooks";

const FormSchema = yup.object().shape({
  name: yup.string().required(),
});

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [action, setAction] = React.useState([]);
  const { isMobile } = useSelector((state) => state.general);

  const columns = useHeader({
    translate,
    action,
    setAction,
    loading,
    onAction: async (key, record) => {
      switch (key) {
        case "edit": {
          setAction([record.key, record]);
          break;
        }
        default:
      }
    },
  });

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await policyApi.update({
        key   : values.key,
        name  : values.name,
        values: values.values,
      });
      setLoading(false);
      setAction([]);

      message.success(translate("system.request.success", "Success!"));

      myTableRef.current.reload();
    } catch (err) {
      message.error(
        err?.message || translate("system.request.failed", "Failed!")
      );

      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <PageHeader
        title={translate("policy.pageheader.title", "Cancel policy")}
      />
      <PageContent>
        <Formik
          enableReinitialize
          initialValues={action[1]}
          validationSchema={FormSchema}
          onSubmit={onSubmit}
        >
          {() => {
            return (
              <Form>
                <Table
                  ref={myTableRef}
                  columns={columns}
                  loadData={policyApi.list}
                  rowKey={(record) => record?.rowKey}
                />
              </Form>
            );
          }}
        </Formik>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, setAction, action, loading, translate }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("system.useheader.title.name", "Name"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="name" style={{ width: "100%", margin: 0 }}>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return record.name;
      },
    },
    {
      title : translate("policy.useheader.var1", "Variant 1"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[0]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[0]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[0]} %`;
      },
    },
    {
      title : translate("policy.useheader.var2", "Variant 2"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[1]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[1]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[1]} %`;
      },
    },
    {
      title : translate("policy.useheader.var3", "Variant 3"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[2]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[2]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[2]} %`;
      },
    },
    {
      title : translate("policy.useheader.var4", "Variant 4"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[3]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[3]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[3]} %`;
      },
    },
    {
      title : translate("policy.useheader.var5", "Variant 5"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[4]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[4]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[4]} %`;
      },
    },
    {
      title : translate("policy.useheader.var6", "Variant 6"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[5]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[5]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[5]} %`;
      },
    },
    {
      title : translate("policy.useheader.var7", "Variant 7"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[6]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[6]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[6]} %`;
      },
    },
    {
      title : translate("policy.useheader.var8", "Variant 8"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[7]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[7]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[7]} %`;
      },
    },
    {
      title : translate("policy.useheader.var9", "Variant 9"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[8]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[8]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[8]} %`;
      },
    },
    {
      title : translate("policy.useheader.var10", "Variant 10"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[9]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[9]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[9]} %`;
      },
    },
    {
      title : translate("policy.useheader.var11", "Variant 11"),
      render: (record) => {
        if (action[0] === record.key)
          return (
            <Form.Item name="values[10]" style={{ width: "100%", margin: 0 }}>
              <Input
                type="number"
                name="values[10]"
                placeholder="Percent"
                style={{ width: "100%" }}
              />
            </Form.Item>
          );

        return `${record.values[10]} %`;
      },
    },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        return !(action[0] === record.key) ? (
          <RowAction
            actions={{
              edit: translate("system.action.edit", "Edit"),
            }}
            onClick={(key) => onAction(key, record)}
          />
        ) : (
          <>
            <Button
              type="primary"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={loading}
            >
              {translate("system.btn.save", "Save")}
            </Button>
            <Button
              type="default"
              style={{ width: "100%", marginTop: 10 }}
              onClick={() => setAction([])}
            >
              {translate("system.btn.cancel", "Cancel")}
            </Button>
          </>
        );
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
  .ant-form-item-explain {
    display: none !important;
  }
`;
