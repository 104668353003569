import React from "react";
import * as Yup from "yup";
import { income as incomeApi } from "../../../apis";
import { Button, message, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, InputNumber } from "formik-antd";
import { useTranslate } from "../../../hooks";

const FormComponents = ({ onCancel, setAction, reload, action }) => {
  const { translate } = useTranslate();

  const FormSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError(translate("system.regex.required", "required field"))
      .required(translate("system.regex.required", "required field"))
  });

  const onSubmit = async (data) => {
    try {
      await incomeApi.update({
        amount: data.amount,
        _id   : action._id
      });
      setAction([]);
      message.success(translate("system.request.success", "Success"));
      reload();
    } catch (err) {
      message.error(err.message || "Failed");
    }
  };

  return (
    <div>
      <Formik
        initialValues={{ amount: undefined }}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <FormItem name="amount" label={translate("income.form.amount", "Income amount")} required>
                <InputNumber
                  parser={(value) => {
                    return value ? value.replace(/\$\s?|(,*)/g, "") : "";
                  }}
                  formatter={(value) => {
                    return !Number.isNaN(parseFloat(value || ""))
                      ? value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      : "";
                  }}
                  style={{ width: "100%" }}
                  prefix={<></>}
                  controls={false}
                  name="amount"
                  placeholder={translate(
                    "income.form.amount",
                    "Income amount"
                  )}
                />
              </FormItem>
              <Row justify="end">
                <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>{translate("system.btn.confrim", "Confirm")}</Button>
                <Button className="button" type="default" onClick={onCancel}>{translate("system.btn.cancel", "Cancel")}</Button>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormComponents;