import { combineReducers } from "redux";

import auth from "./auth";
import general from "./general";
import socket from "./socket";

export default combineReducers({
  auth,
  general,
  socket
});
