/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */

import React from "react";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { useTranslate } from "../../hooks";
import { Row, Col, Button, message } from "antd";
import styled from "styled-components";
import { CalendarIcon, XMarkIcon, FolderIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import NewCalendarForm from "../../components/Calendar/NewCalendarForm";
import { calendar as calendarApi } from "../../apis";

const Calendar = () => {
  const { translate } = useTranslate();
  const [data, setData] = React.useState();

  const [selectedDateRange, setSelectedDateRange] = React.useState([]);
  const [gahaiError, setGahaiError] = React.useState([false, false]);

  const [disabledDates, setDisabledDates] = React.useState([]);

  const getDates = (startDate, endDate) => {
    const daysbetween =
      Math.floor((Date.parse(startDate) - Date.parse(endDate)) / 86400000) - 1;

    const dates = Array(-daysbetween || 0).fill("");
    return dates?.map((date, index) => {
      if (index === 0) {
        return startDate;
      }
      if (index === -daysbetween) {
        return endDate;
      }
      return moment(startDate, "YYYY-MM-DD").add(index, "days");
    });
  };

  const onSubmit = async () => {
    const convertedDates = selectedDateRange.map((c) => {
      return {
        startDate: moment(c.startDate).format("YYYY/MM/DD"),
        endDate  : moment(c.endDate).format("YYYY/MM/DD"),
      };
    });
    try {
      await calendarApi.update({
        dates: convertedDates,
      });
      message.success(translate("admin.calendar.save", "Saved!"));
    } catch (error) {
      message.error(error.message || "Could not saved ");
    }
  };

  const deleteItem = (indexs, item) => {
    let array = selectedDateRange;
    array = array.filter(function (item, index) {
      return index !== indexs;
    });
    setSelectedDateRange(array);
    const res = getDates(item.startDate, item.endDate).map((item) =>
      moment(item)?.format("YYYY/MM/DD")
    );

    setDisabledDates(disabledDates.filter((val) => !res.includes(val)));
  };

  React.useEffect(() => {
    (async () => {
      try {
        const res = await calendarApi.get();
        setSelectedDateRange(
          res?.dates.map((c) => {
            return { startDate: c?.startDate, endDate: c?.endDate };
          })
        );
        setDisabledDates(res?.disableDates.map((c) => c));
      } catch (error) {
        message.error(error.message || "Could not get dates");
      }
    })();
  }, []);

  return (
    <PageContainer>
      <PageHeader
        title={translate("localization.list.pageheader.calendar", "Calendar")}
        extra={
          <Button
            onClick={() => onSubmit()}
            size="middle"
            type="primary"
            danger
          >
            {translate("system.btn.save", "Save")}
          </Button>
        }
      />
      <PageContent>
        <div>
          <WrapperContent>
            <Row gutter={[16, 16]}>
              <Col className="calendarContainer" span={14}>
                <NewCalendarForm
                  size={"lg"}
                  minRange={0}
                  minRequired={false}
                  gahaiError={setGahaiError}
                  setDisable={setDisabledDates}
                  setRange={setSelectedDateRange}
                  disabledDates={disabledDates}
                />
                <div className="gahaiError">
                  {gahaiError[0] ? (
                    <div className="gahaiError1">
                      利用できない日が含まれています。
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col className="datessss" span={10}>
                {selectedDateRange?.length > 0 ? (
                  selectedDateRange?.map((item, index) => (
                    <div key={index} className="secondDateItem">
                      <CalendarIcon
                        style={{
                          width : "24px",
                          height: "24px",
                          color : "#f2670a",
                        }}
                      />
                      <div className="">
                        {item?.endDate === item?.startDate ? (
                          <div className="">
                            {moment(item.startDate).format("YYYY/MM/DD")}
                          </div>
                        ) : (
                          <div className="">
                            {moment(item.startDate).format("YYYY/MM/DD")} -{" "}
                            {moment(item.endDate).format("YYYY/MM/DD")}
                          </div>
                        )}
                      </div>
                      <div>
                        <a
                          onClick={() => deleteItem(index, item)}
                          style={{
                            display   : "flex",
                            alignItems: "center",
                          }}
                        >
                          <XMarkIcon
                            style={{ width: "24px", height: "24px" }}
                          />
                        </a>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="empty">No picked dates</div>
                )}
              </Col>
            </Row>
          </WrapperContent>
        </div>
      </PageContent>
    </PageContainer>
  );
};

const WrapperContent = styled.div`
  width: 70%;
  padding: 10px;
  gap: 10px;

  .datessss {
    padding: 0px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .empty {
      border-radius: 4px 4px;
      border: 1px solid #e4e6ea;
      padding: 5px 10px;
      text-align: center;
      align-items: center;
      color: #d9dade;
      font-weight: 500;
      padding: 10px;
      font-size: 14px;
    }

    .secondDateItem {
      border-radius: 4px 4px;
      display: flex;
      justify-content: space-between;
      border: 1px solid #e4e6ea;
      padding: 5px 10px;
      display: flex;
      gap: 8px;
      align-items: center;
      color: #8d8d8d;
      font-weight: 500;
      padding: 10px;
      font-size: 14px;
    }
  }
  .gahaiError {
    margin-top: 30px;

    .gahaiError1 {
      border: 1px solid red;
      border-radius: 5px;
      padding: 10px;
      text-align: center;
      color: red;
    }
  }
`;
export default Calendar;
