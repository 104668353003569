/* eslint-disable key-spacing */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { general as generalApi } from "../../apis";

export let socket;

export default () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  React.useEffect(() => {
    socket = io();

    socket.on("connect_error", () => {
      console.log("--------------------connect_error>");
      dispatch({
        type: "auth.logout",
      });
    });

    socket.on("connect", () => {
      console.log(
        "* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *"
      );
      console.log("SOCKET INITED");
      console.log(
        "* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *"
      );
    });

    socket.on("action", (action) => {
      console.log("socket action------", action);
      if (action.type === "socket.chat") {
        dispatch({
          type: "socket.chat",
          payload: action.payload,
        });
      }
      if (action.type === "socket.chat.reload") {
        console.log("socket reload------", action);
        dispatch({
          type: "socket.chat.reload",
          payload: action.payload.conversationId,
        });
      }

      if (action.type === "socket.init") {
        generalApi.init()(dispatch);
      }
      if (action.type === "socket.conversation") {
        dispatch({
          type: "socket.conversation",
        });
      }
    });
  }, [user]);

  return <></>;
};
