import React from "react";
import { Form, FormItem, Input, Select } from "formik-antd";
import { Button, Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslate } from "../../hooks";



const ProjectForm = ({ action, onSubmit, onCancel, }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    type: "FURNITURE",
    name: undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  const FormSchema = () => Yup.object({
    type: Yup.string().required(translate("equipment.form.required.type", "type is a required field")),
    name: Yup.string().required(translate("equipment.form.required.name", "name  is a required field")),
    sort: Yup.number(translate("equipment.form.required.name", "name  is a required field")).min(0, translate("equipment.form.required.name", "name  is a required field")).required(translate("equipment.form.required.name", "name  is a required field")),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => {
        return (
          <Form {...formItemLayout}>
            <FormItem name="type" label={translate("equipments.form.type", "Type")} required>
              <Select name="type" placeholder={translate("equipments.form.type", "Type")}>
                <Select.Option value="FURNITURE">{translate("equipments.form.furniture", "Furniture")}</Select.Option>
                <Select.Option value="FEATURE">{translate("equipments.form.feature", "Feature")}</Select.Option>
                <Select.Option value="FACILITY">{translate("equipments.form.facility", "Facility")}</Select.Option>
              </Select>
            </FormItem>
            <FormItem name="name" label={translate("equipments.form.name", "Name")} required>
              <Input name="name" placeholder={translate("equipments.form.name", "Name")} />
            </FormItem>
            <FormItem name="sort" label={translate("equipments.form.sort", "Sort")} required>
              <Input name="sort" placeholder={translate("equipments.form.sort", "Sort")} />
            </FormItem>
            <AntForm.Item {...tailFormItemLayout}>
              <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>{translate("system.btn.save", "Save")}</Button>
              <Button className="button" type="default" onClick={onCancel}>{translate("system.btn.cancel", "Cancel")}</Button>
            </AntForm.Item>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProjectForm;