
import qs from "qs";
import { store } from "../";

const statusHandler = async (response) => {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }

  const error = new Error(response.statusText);
  error.status = response.status;
  error.response = response;

  throw error;
};

const errorHandler = async (error) => {
  if (error.response) {
    const json = await error.response.json();

    if (error.response && error.response.status === 401) {
      store.dispatch({
        type: "auth.logout",
      });
    }

    throw json;
  } else {
    throw new Error("JSON_PARSE_ERROR");
  }
};

const request = async (url, data, options) => {
  let defaultOptions = {
    credentials: "include",
    headers    : {
      Accept        : "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    ...options,
  };

  let queryString = "";

  if (options.method === "GET") {
    delete defaultOptions.body;

    queryString = `?${qs.stringify(data)}`;
  }

  const { token } = store.getState().auth;

  if (token) {
    defaultOptions.headers.Authorization = "Bearer " + token;
  }

  try {
    const res = await fetch(`${url}${queryString}`, defaultOptions);

    await statusHandler(res);

    const json = await res.json();

    return json;
  } catch (err) {
    await errorHandler(err);
  }
};

const httpMethod = (signal) => {
  return {
    get: (url, data, options) => {
      if (signal) options.signal = signal;

      return request(url, data, { ...options, method: "GET" });
    },
    post: (url, data, options) => {
      if (signal) options.signal = signal;

      return request(url, data, { ...options, method: "POST" });
    },
    put: (url, data, options) => {
      if (signal) options.signal = signal;

      return request(url, data, { ...options, method: "PUT" });
    },
    del: (url, data, options) => {
      if (signal) options.signal = signal;

      return request(url, data, { ...options, method: "DELETE" });
    },
    // upload: (url, data, options) => {
    //   if (signal) {
    //     options.signal = signal;
    //     return;
    //   }
    // },
  };
};

export default {
  ...httpMethod(),
  signal: (signal) => httpMethod(signal),
};
