/* eslint-disable key-spacing */
import React, { useState, useEffect } from "react";
import { Upload } from "antd";
import { Field } from "formik";
import { v4 as uuid } from "uuid";
import { connect } from "react-redux";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../colors";
import { useTranslate } from "../../hooks";
import { TextInput } from "@mantine/core";

const wrapper = ({ name, action, auth, ...restProps }) => {
  return (
    <Field name={name}>
      {(fieldProps) => (
        <FieldComponent
          action={action}
          name={name}
          auth={auth}
          {...fieldProps}
        />
      )}
    </Field>
  );
};

const FieldComponent = (props) => {
  const {
    field,
    form,
    name,
    auth: { token },
    action,
  } = props;
  const { value = [] } = field;
  const { setFieldValue } = form;
  const { translate } = useTranslate();
  const [fileList, setFileList] = useState([]);

  // const [previewVisible, setPreviewVisible] = useState(false);
  // const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    const extra = [];

    value.forEach((entry) => {
      const tmp = fileList.find((iterator) => iterator.url === entry.url);
      if (!tmp) {
        extra.push({
          uid: uuid(),
          status: "done",
          name: "image.png",
          url: entry.url,
          isMain: entry.isMain,
          description: entry?.description,
        });
      }
    });

    if (extra.length) {
      setFileList([...fileList, ...extra]);
    }
  }, [fileList, value]);

  const setDescription = (file, desc) => {
    setFileList(
      fileList.map((entry) => {
        if (entry.uid === file.uid) {
          return {
            ...entry,
            description: desc,
          };
        }
        return {
          ...entry,
        };
      })
    );
    setFieldValue(
      name,
      fileList
        .filter((f) => f.url)
        .map((entry) => {
          if (entry.uid === file.uid) {
            return {
              ...entry,
              description: desc,
            };
          }
          return {
            ...entry,
          };
        })
    );
    console.log("--------onSetDescription", fileList);
  };

  const remove = (file) => {
    setFileList(
      fileList
        .filter((entry) => entry.uid !== file.uid)
        .map((entry, index) => {
          return {
            ...entry,
            isMain: index === 0,
            description: entry.description,
          };
        })
    );

    if (file.url) {
      setFieldValue(
        name,
        value.filter((entry) => entry.url !== file.url)
      );
    } else if (file.response && file.response.url) {
      setFieldValue(
        name,
        value.filter((entry) => entry.url !== file.response.url)
      );
    }
  };

  const handleChange = ({ fileList }) => {
    setFileList(
      fileList.map((entry, index) => {
        if (entry.response && entry.response.url) {
          return {
            ...entry,
            url: entry.response.url,
            isMain: index === 0,
            description: entry.description || "",
          };
        }

        return entry;
      })
    );

    const filtered = fileList.filter((entry) => {
      return entry.url || (entry.response && entry.response.url);
    });

    setFieldValue(
      name,
      filtered.map((entry) => ({
        url: entry.url || entry.response.url,
        description: entry.description || "",
        isMain: !!entry.isMain,
      }))
    );
  };

  const uploadButton = (
    <UploadButton>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </UploadButton>
  );

  return (
    <div className="clearfix">
      <Container>
        {fileList.map((file, index) => (
          <div key={file.uid}>
            <div className={`${"upload-grid"} ${file.isMain ? "active" : ""}`}>
              <div className="overlay">
                <DeleteOutlined
                  className="icon"
                  style={{ color: "#fff" }}
                  type="delete"
                  onClick={() => remove(file, index)}
                />
              </div>
              {file.status === "uploading" && (
                // <div className={styles.background}>
                <LoadingOutlined size="lg" />
                // </div>
              )}
              {file.status === "done" && (
                <div
                  style={{
                    backgroundImage: `url(${file?.url || file?.response?.url})`,
                  }}
                  className="background"
                />
              )}
            </div>

            <div className="upload-grid description-input-wrapper">
              <TextInput
                error={!file.description ? translate("system.regex.required", "Comment required") : null}
                disabled={file.status === "uploading"}
                placeholder={translate(
                  "property.form.img.description",
                  "Description"
                )}
                className="description-input"
                radius={2}
                value={file.description}
                onChange={(e) => {
                  setDescription(file, e.target.value);
                }}
              />
            </div>
          </div>
        ))}
        <Upload
          name="file"
          action={action}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          fileList={fileList}
          onChange={handleChange}
          showUploadList={false}
          multiple
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
      </Container>
    </div>
  );
};

const UploadButton = styled.div`
  width: 128px;
  height: 128px;
  border: 1px dashed #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s;
  &:hover {
    border-color: ${colors.primary};
    color: ${colors.primary};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;

  .description-input-wrapper {
    height: min-content !important;
    padding: 0 !important;
    border: none !important;
  }

  .upload-grid {
    width: 128px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin: 0 8px 8px 0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    position: relative;
    &.active {
      border-color: #188fff;
      border-width: 2px;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #0000006e;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    z-index: 100;
    opacity: 0;
    transition: all 0.3s;
    .icon {
      font-size: 14px;
      &:hover {
        color: #40a9ff;
      }
    }

    &:hover {
      opacity: 100;
    }
  }

  .background {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
  }
`;

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps)(wrapper);
