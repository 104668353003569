
import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form as AntForm, Row, Col, message } from "antd";
import { ImageUpload } from "../../components";
import { FormItem, Input, Select, Form, InputNumber } from "formik-antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { app_user as appuserApi } from "../../apis";
import { useTranslate } from "../../hooks";
import InputMask from "react-input-mask";
import styled from "styled-components";

export default ({ action }) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const params = useParams();
  const { userTypes } = useSelector((state) => state.general);
  const [password, setPassword] = React.useState(false);
  const [data] = React.useState({
    type       : undefined,
    lastName   : undefined,
    firstName  : undefined,
    avatar     : undefined,
    email      : undefined,
    password   : undefined,
    orgname    : undefined,
    phoneNumber: undefined,
    rep        : undefined,
    address    : undefined,
    birthDate  : undefined,
    paymentName: undefined,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const FormSchema = (action) =>
    Yup.object({
      type        : Yup.string() .trim() .required(translate("appuser.form.required.user.type", "required field")),
      customerType: Yup
        .string()
        .required(translate("appuser.form.required.email", "Required!")),
      lastName: Yup.string() .when("type", { is: "PERSONAL", then: Yup.string() .trim() .required(translate("appuser.form.required.lastname", "lastName is a required field")), })
        .trim()
        .nullable(),
      firstName: Yup.string()
        .when("type", {
          is  : "PERSONAL",
          then: Yup.string()
            .trim()
            .required(
              translate(
                "appuser.form.required.firstname",
                "firstName is a required field"
              )
            ),
        })
        .trim()
        .nullable(),
      paymentName: Yup.string().required(translate("appuser.form.required.email", "email is a required field")),
      orgname    : Yup.string()
        .when("type", {
          is  : "ORGANIZATION",
          then: Yup.string()
            .trim()
            .required(
              translate(
                "appuser.form.required.orgname",
                "orgname is a required field"
              )
            ),
        })
        .trim()
        .nullable(),
      rep: Yup.string()
        .when("type", {
          is  : "ORGANIZATION",
          then: Yup.string()
            .trim()
            .required(
              translate(
                "appuser.form.required.orgname",
                "orgname is a required field"
              )
            ),
        })
        .trim()
        .nullable(),
      avatar: Yup.string().trim().optional().nullable(),
      email : Yup.string()
        .trim()
        .email(translate("app.auth.forgot.email", "Email must be a valid email"))
        .required(
          translate("appuser.form.required.email", "email is a required field")
        ),
      password   : action === "update" ? Yup.string().trim().nullable() : Yup.string().trim().required(),
      phoneNumber: Yup.number()
        .typeError(translate(
          "appuser.form.regex.phone",
          "Phone number must be only number"
        ))
        .required(
          translate(
            "appuser.form.required.phone.number",
            "phoneNumber is a required field"
          )
        ),
      address: Yup.string()
        .trim()
        .required(
          translate(
            "appuser.form.required.address",
            "address is a required field"
          )
        ),
      birthDate: Yup.string()
        .trim()
        .required(
          translate(
            "appuser.form.required.bithdate",
            "birthDate is a required field"
          )
        ),
    });
  const onSubmit = async (values) => {
    try {
      if (action && action[0] === "update") {
        await appuserApi.update(data._id, {
          ...values,
          phoneNumber: values.phoneNumber.toString()
        });
        history.push("/app_user");
      } else {
        await appuserApi.create({
          type       : params.type,
          ...values,
          phoneNumber: values.phoneNumber.toString()
        });
        history.push("/app_user");
      }
      message.success(translate("system.request.success", "Success!"));
    } catch (err) {
      console.log(err);
      message.error(
        err?.message || translate("system.request.error", "Error!")
      );
    }
  };

  return (
    <FormCustom >
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema(action[0])}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, values, setFieldValue }) => {
          console.log(errors);
          return (
            <Form {...formItemLayout}>
              <Input type="hidden" name="type" />
              <FormItem
                name="email"
                label={translate("appuser.form.email", "Email")}
                required
              >
                <Input
                  disabled={action[0] === "update"}
                  prefix={<></>}
                  name="email"
                  placeholder={translate("appuser.form.email", "Email")}
                />
              </FormItem>

              <FormItem
                name="type"
                label={translate("app.account.userForm.personal.placeholder", "User type")}
                required
              >
                <Select
                  disabled={action[0] === "update"}
                  name="type"
                  placeholder={translate("appuser.form.type", "User type")}
                >
                  {userTypes?.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item === "PERSONAL" ? translate(
                        "appuser.form.personal",
                        "Personal"
                      ) : translate(
                        "appuser.form.org",
                        "Organization"
                      )}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              {/* Doorh ni Customertype-g owner songoj boldog bolgoh tsag dor comment-g ni awnaa */}
              {/* <FormItem
                name="customerType"
                label={translate("appuser.form.customertype", "Customer type")}
                required
              >
                <Select
                  disabled={action[0] === "update"}
                  name="customerType"
                  placeholder={translate("appuser.form.customertype", "Customer type")}
                >
                  {[
                    {
                      value: "TENANT",
                      label: translate("constant.tenant", "Tenant"),
                    },
                    {
                      value: "OWNER",
                      label: translate("constant.owner", "Owner"),
                    },
                  ]?.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem> */}
              {action[0] !== "update" && (
                <FormItem
                  label={translate("appuser.form.password", "Password")}
                  name="password"
                  required
                >
                  <Row gutter={12}>
                    <Col>
                      <Input
                        prefix={<></>}
                        name="password"
                        placeholder={translate(
                          "appuser.form.password",
                          "Password"
                        )}
                      />
                    </Col>
                  </Row>
                </FormItem>
              )}

              {values.type === "PERSONAL" && (
                <FormItem
                  name="lastName"
                  label={translate("appuser.form.lastname", "Lastname")}
                  required
                >
                  <Input
                    disabled={action[0] === "update"}
                    prefix={<></>}
                    name="lastName"
                    placeholder={translate("appuser.form.lastname", "Lastname")}
                  />
                </FormItem>
              )}
              {values.type === "PERSONAL" && (
                <FormItem
                  name="firstName"
                  label={translate("appuser.form.firstname", "Firstname")}
                  required
                >
                  <Input
                    disabled={action[0] === "update"}
                    className={action[0] === "update" ? "custom-disabled-input" : ""}
                    prefix={<></>}
                    name="firstName"
                    placeholder={translate(
                      "appuser.form.firstname",
                      "Firstname"
                    )}
                  />
                </FormItem>
              )}
              {values.type === "ORGANIZATION" && (
                <FormItem
                  name="orgname"
                  label={translate("app.account.userForm.organzName", "Organization name")}
                  required
                >
                  <Input
                    disabled={action[0] === "update"}
                    prefix={<></>}
                    name="orgname"
                    placeholder={translate(
                      "appuser.form.orgname",
                      "Organization name"
                    )}
                  />
                </FormItem>
              )}
              {values.type === "ORGANIZATION" && (
                <FormItem
                  name="rep"
                  label={translate("app.account.userForm.rep", "Representative")}
                  required
                >
                  <Input
                    prefix={<></>}
                    disabled={action[0] === "update"}
                    name="rep"
                    placeholder={translate(
                      "appuser.form.rep",
                      "Representative"
                    )}
                  />
                </FormItem>
              )}
              <FormItem
                name="birthDate"
                label={values.type === "ORGANIZATION" ? translate("app.account.userForm.date", "Birth date"): translate("appuser.form.birthdate", "Birth date")}
                required
              >
                <InputMask
                  className="ant-input"
                  disabled={action[0] === "update"}
                  value={values.birthDate}
                  mask={"9999/99/99"}
                  onChange={e => {
                    const value = e.target.value || "";
                    setFieldValue("birthDate", value);
                  }}
                  placeholder="YYYY/MM/DD">
                </InputMask>
              </FormItem>

              <FormItem
                name="paymentName"
                label={translate("appuser.form.paymentname", "Payment name")}
                required
              >
                <Input
                  disabled={action[0] === "update"}
                  prefix={<></>}
                  name="paymentName"
                  placeholder={translate(
                    "appuser.form.paymentname.placeholder",
                    "Payment name"
                  )}
                />
              </FormItem>
              <FormItem
                name="address"
                label={translate("appuser.form.address", "Address")}
                required
              >
                <Input
                  disabled={action[0] === "update"}
                  prefix={<></>}
                  name="address"
                  placeholder={translate("appuser.form.address", "Address")}
                />
              </FormItem>
              <FormItem
                name="phoneNumber"
                label={translate("appuser.form.phonenumber", "Phone number")}
                required
              >
                <Input
                  disabled={action[0] === "update"}
                  style={{ width: "100%" }}
                  prefix={<></>}
                  name="phoneNumber"
                  placeholder="09012345678"
                />
              </FormItem>
              <FormItem
                name="avatar"
                label={translate("appuser.form.avatar", "Avatar")}
              >
                <ImageUpload disabled={action[0] === "update"} action="/api/general/upload" name="avatar" />
              </FormItem>

              <AntForm.Item {...tailFormItemLayout}>
                <Button
                  hidden={action[0] === "update"}
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                  style={{ marginRight: 10 }}
                >
                  {translate("system.btn.save", "Save")}
                </Button>
                <Button hidden={action[0] === "update"} onClick={() => history.push("/app_user")}>
                  {translate("system.btn.cancel", "Cancel")}
                </Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </FormCustom>
  );
};


const FormCustom = styled.div`
  .ant-input-affix-wrapper-disabled{
    color: black !important;
    background-color: #FFFFFF;
    cursor: not-allowed;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    color: black !important;
    background-color: #FFFFFF;
    cursor: not-allowed;
  }
  .ant-input[disabled]{
    color: black !important;
    background-color: #FFFFFF;
    cursor: not-allowed;
  }
`;