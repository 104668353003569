import request from "../utils/request";

export const archive = (id) => request.put(`/api/conversation/${id}/archive`);
export const list = (data) => request.get("/api/conversation", data);
export const get = (id) => request.get(`/api/conversation/${id}`);
export const chat = (data) => request.get(`/api/chats/${data.filter.id}`, data);
export const history = (data) => request.get("/api/conversation/history", data);

export const upload = (data) => request.post("/api/conversation/upload", data);

export const conversation = (data) => request.get("/api/conversation", data);
