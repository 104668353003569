/* eslint-disable key-spacing */
import React from "react";
import {
  Tag,
  Button,
  Avatar,
  Modal,
  Input,
  DatePicker,
  Select,
  Radio,
} from "antd";
import {
  property as propertyApi,
  propertyType as propertyTypeApi,
  user as userApi,
} from "../../apis";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import { ExclamationCircleOutlined, PictureOutlined } from "@ant-design/icons";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";
import useFetch from "../../hooks/useFetch";
import QueryForm from "./components/QueryForm";
import ManagerForm from "./components/ManagerForm";
import { useTranslate } from "../../hooks";
import { postCode, renderUser, yen } from "../../utils";
import CalendarModal from "./components/CalendarModal";

export default () => {
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [query, setQuery] = React.useState("");
  const [action, setAction] = React.useState([]);
  const [rowData, setRowData] = React.useState();
  const [types] = useFetch(propertyTypeApi.select)([]);
  const { s3host, webhost, roles, isMobile, user } = useSelector(
    (state) => state.general
  );
  const { checkPermission } = usePermission();
  const { translate } = useTranslate();
  const [calendarModal, setCalendarModal] = React.useState([]);
  const [users] = useFetch(userApi.select)([]);

  const [filters, setFilters] = React.useState({
    type: undefined,
    query: "",
    startDate: "",
    ownerType: "ADMIN",
    endDate: "",
    user: params.user,
    isActive: undefined,
    status: undefined,
  });

  const reload = () => {
    myTableRef.current.reload();
    setAction([]);
  };

  const onCancel = () => {
    setAction([]);
  };

  const columns = useHeader({
    s3host,
    roles,
    user,
    type: params.type,
    translate,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "activate": {
          Modal.confirm({
            title: translate("system.list.model.title", "Confirm"),
            icon: <ExclamationCircleOutlined />,
            content: translate(
              "property.list.modal.content",
              "Would you like to activate this property?"
            ),
            okText: translate("property.list.modal.oktext", "Activate"),
            cancelText: translate("system.list.modal.canceltext", "Cancel"),
            onOk: async () => {
              await propertyApi.activate(record._id);
              myTableRef.current.reload();
            },
          });
          break;
        }
        case "deactivate": {
          Modal.confirm({
            title: translate("system.list.model.title", "Confirm"),
            icon: <ExclamationCircleOutlined />,
            content: translate(
              "property.request.list.modal.content.deactivate",
              "Would you like to deactivate this property?"
            ),
            okText: translate(
              "property.request.list.modal.deactivate",
              "Deactivate"
            ),
            cancelText: translate("system.list.modal.canceltext", "Cancel"),
            onOk: async () => {
              await propertyApi.deactivate(record._id);
              myTableRef.current.reload();
            },
          });
          break;
        }
        case "recommend": {
          setAction(["recommend"]);
          setRowData(record);
          break;
        }
        case "manager": {
          setAction(["manager"]);
          setRowData(record);
          break;
        }
        case "preview": {
          window.open(`${webhost}/preview/${record?._id}`);
          break;
        }
        case "edit": {
          history.push(`/property/edit/${record._id}`);
          break;
        }
        case "orders": {
          history.push(
            `/order/${record.isAdmin ? "ADMIN" : "CUSTOMER"}/${record._id}`
          );
          break;
        }
        case "calendar": {
          setCalendarModal([true, record]);
          break;
        }

        default:
      }
    },
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").toDate(),
      endDate: e && moment(e[1]).endOf("days").toDate(),
    });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      query: "",
      type: undefined,
      isActive: undefined,
      ownerType: "ADMIN",
      startDate: undefined,
      status: undefined,
      endDate: undefined,
    });
  };

  React.useEffect(() => {
    setFilters({
      ...filters,
      ownerType: params.user ? "CUSTOMER" : "ADMIN",
    });
  }, [params.user]);

  return (
    <PageContainer>
      <PageHeader
        title={translate("property.list.pageheader.title", "Property")}
        extra={[
          <Button
            type="primary"
            shape={isMobile && "circle"}
            onClick={() => history.push("/property/create/new")}
            icon={<PlusOutlined />}
          >
            {!isMobile &&
              translate("property.list.btn.add.property", "Add property")}
          </Button>,
        ]}
      />
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              // renderExtraFooter={() => (
              //   <div>
              //     {/* Customize the week days here */}
              //     <div>
              //       {moment.weekdaysMin(true).map((day) => (
              //         <span key={day}>{day}</span>
              //       ))}
              //     </div>
              //   </div>
              // )}
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Button onClick={onClear}>
              {translate("system.filter.btn.clear", "Clear")}
            </Button>,
          ]}
        >
          <Radio.Group
            defaultValue={filters.ownerType}
            value={filters.ownerType}
            buttonStyle="solid"
            onChange={(e) =>
              setFilters((state) => ({ ...state, ownerType: e.target.value }))
            }
          >
            <Radio.Button value="ADMIN">
              {translate("order.list.radio.btn.admin", "Admin")}
            </Radio.Button>
            <Radio.Button value="CUSTOMER">
              {translate("order.list.radio.btn.customer", "Customer")}
            </Radio.Button>
          </Radio.Group>
          <Input
            className="grid-item"
            prefix={<SearchOutlined />}
            value={query}
            placeholder={translate("system.input.search", "Search...")}
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />
          <Select
            className="grid-item"
            value={filters.type}
            style={{ width: "130px" }}
            onChange={(e) => setFilters({ ...filters, type: e })}
            placeholder={translate(
              "property.list.select.type",
              "Property type"
            )}
            allowClear
          >
            {types.map((type, index) => (
              <Select.Option key={index} value={type._id}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
          <Select
            className="grid-item"
            value={filters.status}
            style={{ width: "130px" }}
            onChange={(e) => setFilters({ ...filters, status: e })}
            placeholder={translate("property.list.select.status", "Status")}
            allowClear
          >
            {["DRAFT", "ACTIVE", "DISACTIVE"].map((item, index) => (
              <Select.Option key={index} value={item}>
                {translate(item, item)}
              </Select.Option>
            ))}
          </Select>
        </Filter>
        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={propertyApi.list}
          rowKey={(record) => record?.rowKey}
        />
      </PageContent>
      <Modal
        title={
          action &&
          action[0] === "recommend" &&
          translate("system.list.model.title", "Confirm")
        }
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}
      >
        {action && action[0] === "recommend" && (
          <QueryForm
            onCancel={onCancel}
            action={action}
            setAction={setAction}
            rowData={rowData}
            reload={reload}
          />
        )}
      </Modal>
      <Modal
        title={
          action &&
          action[0] === "manager" &&
          translate("system.list.model.title", "Confirm")
        }
        visible={action && action[0] === "manager"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}
      >
        {action && action[0] && (
          <ManagerForm
            onCancel={onCancel}
            action={action}
            setAction={setAction}
            rowData={rowData}
            reload={reload}
          />
        )}
      </Modal>
      <CalendarModal
        reload={() => myTableRef.current.reload()}
        setOpen={setCalendarModal}
        open={calendarModal}
      />
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate, user }) => {
  return [
    {
      title: "№",
      dataIndex: "rowKey",
      width: "20px",
    },
    {
      title: translate("system.property.list.image", "Image"),
      render: (record) => {
        return (
          <Avatar
            src={`${record.image}`}
            shape="square"
            icon={<PictureOutlined />}
          />
        );
      },
    },
    {
      title: translate("system.useheader.title.space.name", "Name"),
      render: (record) => {
        return (
          <div style={{ minWidth: 150 }}>
            <p>{record.name}</p>
          </div>
        );
      },
    },
    {
      title: translate("system.useheader.title.owner", "Owner"),
      render: (row) => {
        return <div style={{ minWidth: 100 }}>{renderUser(row.user)}</div>;
      },
    },
    {
      title: translate("system.property.list.address", "Address"),
      render: (record) => {
        if (!record.address) return <div>-</div>;
        return (
          <Tag style={{ whiteSpace: "pre" }}>
            {postCode(record?.address?.postCode)}
            {record?.address?.city}
            {record?.address?.address}
            {"\n"}
            {record?.address?.buildingName}
          </Tag>
        );
      },
    },
    {
      title: translate("system.useheader.title.day.taxexcluded", "Day price"),
      render: (record) => {
        if (record.negotiable) {
          return (
            <Tag>
              {translate("app.property.detail.negotiable", "Negotiable")}
            </Tag>
          );
        }
        if (record.useMultiPrice) {
          if (record.useSpecialPrice) {
            return (
              <div>
                <Tag style={{ marginBottom: "2px" }}>
                  {translate("property.list.tag.week", "Week")}{" "}
                  {`${yen(record.weekPrice)}`}
                </Tag>
                <Tag style={{ marginBottom: "2px" }}>
                  {translate("property.list.tag.weekend", "Weekend")}{" "}
                  {`${yen(record.weekendPrice)}`}
                </Tag>
                <Tag>
                  {" "}
                  {translate("property.list.tag.special", "Special")}
                  {`${yen(record.specialPrice)}`}
                </Tag>
              </div>
            );
          }
          return (
            <div>
              <Tag style={{ marginBottom: "2px" }}>
                {translate("property.list.tag.week", "Week")}{" "}
                {`${yen(record.weekPrice)}`}
              </Tag>
              <Tag style={{ marginBottom: "2px" }}>
                {translate("property.list.tag.weekend", "Weekend")}{" "}
                {`${yen(record.weekendPrice)}`}
              </Tag>
            </div>
          );
        }
        return (
          <Tag style={{ marginBottom: "2px" }}>{`${yen(
            record.weekPrice
          )}`}</Tag>
        );
      },
    },
    {
      title: translate(
        "system.useheader.title.day.taxincluded",
        "Tax included"
      ),
      render: (record) => {
        if (record.negotiable) {
          return (
            <Tag>
              {translate("app.property.detail.negotiable", "Negotiable")}
            </Tag>
          );
        }
        if (record.useMultiPrice) {
          if (record.useSpecialPrice) {
            return (
              <div>
                <Tag style={{ marginBottom: "2px" }}>
                  {translate("property.list.tag.week", "Week")}{" "}
                  {`${yen(record.weekTax)}`}
                </Tag>
                <Tag style={{ marginBottom: "2px" }}>
                  {translate("property.list.tag.weekend", "Weekend")}{" "}
                  {`${yen(record.weekendTax)}`}
                </Tag>
                <Tag>
                  {translate("property.list.tag.special", "Special")}
                  {`${yen(record.specialTax)}`}
                </Tag>
              </div>
            );
          }
          return (
            <div>
              <Tag style={{ marginBottom: "2px" }}>
                {translate("property.list.tag.week", "Week")}{" "}
                {`${yen(record.weekTax)}`}
              </Tag>
              <Tag style={{ marginBottom: "2px" }}>
                {translate("property.list.tag.weekend", "Weekend")}{" "}
                {`${yen(record.weekendTax)}`}
              </Tag>
            </div>
          );
        }
        return (
          <Tag style={{ marginBottom: "2px" }}>{`${yen(record.weekTax)}`}</Tag>
        );
      },
    },
    {
      title: translate("system.useheader.title.status", "Status"),
      render: (record) => {
        return (
          <Tag>{translate(record.propertyStatus, record.propertyStatus)}</Tag>
        );
      },
    },
    {
      title: translate("property.list.recommendation", "Recommendation"),
      render: (record) => {
        if (record.recommendation.length > 0) {
          return record.recommendation.map((item, index) => {
            return <Tag key={index}>{item.name}</Tag>;
          });
        }
        return "-";
      },
    },
    {
      title: translate("system.useheader.title.date", "Date"),
      render: (record) => {
        return (
          <div>
            {moment(record.propertyStatusDate).format("YYYY/MM/DD HH:mm")}
          </div>
        );
      },
    },
    {
      title: translate("system.useheader.title.action", "Action"),
      width: 100,
      render: (record) => {
        let actions = {
          edit: translate("system.action.edit", "Edit"),
          preview: translate("system.action.preview", "Preview"),
          calendar: translate("system.action.calendar", "Calendar"),
          recommend: translate("property.list.action.recommend", "Recommend"),
          orders: translate("property.list.action.orders", "Orders"),
        };
        if (record.isAdmin) {
          actions = {
            ...actions,
            manager: translate("property.list.action.manager", "Manager"),
          };
        }
        if (
          record.propertyStatus === "DISACTIVE" ||
          record.propertyStatus === "DRAFT"
        ) {
          actions = {
            ...actions,
            activate: translate("property.list.modal.oktext", "Activate"),
          };
        }
        if (record.propertyStatus === "ACTIVE") {
          actions = {
            ...actions,
            deactivate: translate(
              "property.request.action.deactivate",
              "Deactivate"
            ),
          };
        }
        if (user.role === "OPERATOR") {
          actions = {
            preview: translate("system.action.preview", "Preview"),
          };
        }
        return (
          <RowAction
            actions={actions}
            onClick={(key) => onAction(key, record)}
          />
        );
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
