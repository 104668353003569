import React from "react";
import { Tag, Button, Avatar, DatePicker, Space, Select, Input, Modal } from "antd";
import { message as messageApi, property as propertyApi } from "../../apis";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { dateFormat, renderUser } from "../../utils";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/useFetch";

const userName = (user) => {
  if (user) {
    return (
      <Space>
        {user?.type === "PERSONAL" ? (
          <div>
            <b>{user?.lastName}</b>
            <b>{user?.firstName}</b> <br />
          </div>
        ) : (
          <div>
            <b>{user?.orgname}</b> <br />
          </div>
        )}
      </Space>
    );
  }
  return (
    <Space>
      {"ユーザー見つかりません"}
    </Space>
  );
};

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [query, setQuery] = React.useState("");
  const { s3host, roles, orderStatuses } = useSelector(
    (state) => state.general
  );
  const { isConversation } = useSelector((state) => state.socket);
  const [properties] = useFetch(propertyApi.selectAdmin)([]);
  const { checkPermission } = usePermission();
  const [filters, setFilters] = React.useState({
    query     : "",
    startDate : "",
    propertyId: undefined,
    endDate   : "",
    status    : "",
  });
  const renderStatus = (status) => {
    if (status === "NEW")
      return (
        <Tag color="blue">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "PAID")
      return (
        <Tag color="green">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "CHAT")
      return (
        <Tag color="blue">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "PENDING")
      return (
        <Tag color="orange">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "TRANSFER")
      return (
        <Tag color="blue">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "CANCELLED")
      return (
        <Tag color="red">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
  };
  const columns = useHeader({
    translate,
    s3host,
    roles,
    renderStatus,
    type    : params.type,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "detail": {
          history.push(`/chat/${record._id}`);
          break;
        }
        case "archive": {
          Modal.confirm({
            title  : translate("system.list.model.title", "Confirm"),
            icon   : <ExclamationCircleOutlined/>,
            content: translate(
              record.isArchive ? "chat.list.modal.content2" :"chat.list.modal.content",
              "Would you like to archive/sunarchive this chat?"
            ),
            okText    : translate("income.list.modal.oktext", "Verify"),
            cancelText: translate("system.list.modal.canceltext", "Cancel"),
            onOk      : async () => {
              try {
                await messageApi.archive(record._id);
                myTableRef.current.reload();
              } catch (err) {
                console.log(err);
              }
            },
          });
          break;
        }
        default:
      }
    },
  });
  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    setFilters({
      startDate : undefined,
      query     : "",
      endDate   : undefined,
      propertyId: undefined,
      status    : undefined,
    });
  }, [params.type]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").toDate(),
      endDate  : e && moment(e[1]).endOf("days").toDate(),
    });
  };

  React.useEffect(() => {
    setFilters({
      startDate: undefined,
      endDate  : undefined,
    });
  }, [isConversation]);

  const onClear = () => {
    setQuery("");
    setFilters({
      startDate : undefined,
      query     : "",
      endDate   : undefined,
      propertyId: undefined,
      status    : undefined,
    });
  };

  return (
    <PageContainer>
      <PageHeader title={translate("chat.list.title", "Chat")} />
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Button onClick={onClear}>
              {translate("system.filter.btn.clear", "Clear")}
            </Button>,
          ]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 300 }}
            value={filters.propertyId}
            onChange={(e) => setFilters({ ...filters, propertyId: e })}
            placeholder={translate("income.list.select.property", "Property ")}
            allowClear
            options={properties.map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })}
          ></Select>
          <Input
            className="grid-item"
            prefix={<SearchOutlined />}
            value={query}
            placeholder={translate(
              "system.input.search.orderid",
              "Search by order id..."
            )}
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />
          <Select
            className="grid-item"
            value={filters.status}
            style={{ width: "130px" }}
            onChange={(e) => setFilters({ ...filters, status: e })}
            placeholder={translate("order.list.filter.status", "Order status")}
            allowClear
          >
            {orderStatuses.map((type, index) => (
              <Select.Option key={index} value={type}>
                {translate(`system.orderstatus.${type}`, type)}
              </Select.Option>
            ))}
          </Select>
        </Filter>
        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={messageApi.conversation}
          rowKey={(record) => record?.rowKey}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate, renderStatus }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("system.useheader.title.owner", "Owner"),
      render: (row) => {
        return renderUser(row.owner);
      },
    },
    {
      title : translate("system.useheader.title.tenant", "Tenant"),
      render: (row) => {
        return renderUser(row.tenant);
      },
    },
    {
      title : translate("system.useheader.list.property", "Property"),
      render: (row) => {
        return (
          <Space>
            <Avatar size={45} shape="square" src={row?.property?.image} />
            <div>
              <b style={{}}>{row?.property?.name}</b> <br />
            </div>
          </Space>
        );
      },
    },
    {
      title : translate("system.useheader.title.orderid", "Order ID"),
      render: (record) => {
        return <Tag>{record?.order?.orderId}</Tag>;
      },
    },
    {
      title : translate("system.useheader.list.order.status", "Order status"),
      render: (record) => {
        return renderStatus(record.orderStatus);
      },
    },
    {
      title : translate("chat.useheader.title.date", "Last chat date"),
      render: (record) => {
        return (
          <Space>
            {moment(record?.lastChatDate).format("YYYY/MM/DD HH:mm")}
            {!record?.ownerSeen && (
              <Tag color="red">
                {translate("chat.list.date.tag", "new chat")}
              </Tag>
            )}
            <Tag>{userName(record?.lastChat?.user)}</Tag>
          </Space>
        );
      },
    },
    {
      title : translate("order.list.useheader.dates", "Dates"),
      render: (record) => {
        return (
          <Tag>{`${dateFormat(record?.order?.startDate)}-${dateFormat(
            record?.order?.endDate
          )}`}</Tag>
        );
      },
    },
    // {
    //   title : translate("system.useheader.title.createdat", "Created at"),
    //   render: (record) => {
    //     return (
    //       <Space>{moment(record?.createdAt).format("YYYY/MM/DD HH:mm")}</Space>
    //     );
    //   },
    // },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        return (
          <RowAction
            actions={{
              detail : translate("system.list.action.detail", "Detail"),
              archive: translate(record.isArchive ?"chat.list.action.unarchive" : "chat.list.action.archive", "Archive"),
            }}
            onClick={(key) => onAction(key, record)}
          />
        );
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
