import { Avatar, Space, Image, Row, Col, Tag, Table, Descriptions } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { property as propertyApi, propertyType as propertyTypeApi } from "../../apis";
import { Loader } from "../../components";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import useFetch from "../../hooks/useFetch";
import { dateFormat, datetimeFormat, postCode, yen } from "../../utils";

const Detail = () => {
  const params = useParams();
  const history = useHistory();
  const [data, setData] = useState();
  const { translate } = useTranslate();
  const reload = React.useCallback(async (signal) => {
    let res = await propertyApi.get(params.id, null, { signal });
    console.log(res);
    setData(res);
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);
  const column = useHeader({ translate });
  const [types] = useFetch(propertyTypeApi.select)([]);

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("property.request.pageheader.title", "Property detail")} />
      <ContainerStyled> {!data ? <Loader/> :
        <PageContent>
          <StyledCardRow>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div style={{ width: "100%", borderBottom: "1px solid #dadada", paddingBottom: "24px" }}>
                  <div className="ant-tag">{translate("property.request.col.order.status", "Status")}: <b>{data?.propertyStatus}</b></div>
                  <div className="ant-tag">{translate("property.request.col.order.date", "Request date")}: <b>{datetimeFormat(data?.createdAt)}</b></div>
                  <div className="ant-tag">{translate("property.request.col.daily.price", "Daily price")}: <b>{yen(data?.dailyPrice)}</b></div>
                  <div className="ant-tag">{translate("property.request.col.space", "Space")}: <b>{data?.space}㎡</b></div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={14} xl={16} xxl={18} >
                <div className="content-wrapper">
                  <div>
                    <div className="ant-list-item-meta-title">{translate("property.request.col.title", "Title")} : </div>
                    <div className="ant-list-item-meta-description">{data?.name || "-"}</div>
                    <div className="ant-list-item-meta-title">{translate("property.request.col.Name", "Name")} : </div>
                    <div className="ant-list-item-meta-description">{data?.title || "-"}</div>
                    <div className="ant-list-item-meta-title">{translate("property.request.col.introduction", "Introduction")} :</div>
                    <div className="ant-list-item-meta-description">{data?.intro || "-"}</div>
                    <div className="ant-list-item-meta-title">{translate("property.request.col.space.details", "Space details")} :</div>
                    <div className="ant-list-item-meta-description">{data?.details || "-"}</div>
                    <div className="ant-list-item-meta-title">{translate("property.request.col.fee.note", "Fee note")}:</div>
                    <div className="ant-list-item-meta-description">{data?.feeNotes || "-"}</div>
                  </div>
                  <Space direction="vertical">
                    <div>
                      <div className="ant-list-item-meta-title">{translate("property.request.col.address", "Address")}: </div>
                      <Tag>{postCode(data?.address?.postCode)}</Tag>
                      <Tag>{data?.address?.city}</Tag>
                      <Tag>{data?.address?.address}</Tag>
                      <Tag>{data?.address?.buildingName}</Tag>
                    </div>
                    <div>
                      <div className="ant-list-item-meta-title">{translate("property.request.col.cancel.policy", "Cancel policy")}: </div>
                      <Tag>Cancel polciy 1:{data?.cancelPolicy?.pcy1}%</Tag>
                      <Tag>Cancel polciy 2:{data?.cancelPolicy?.pcy2}%</Tag>
                      <Tag>Cancel polciy 3:{data?.cancelPolicy?.pcy3}%</Tag>
                      <Tag>Cancel polciy 4:{data?.cancelPolicy?.pcy4}%</Tag>
                    </div>
                    <div>
                      <div className="ant-list-item-meta-title">{translate("property.request.col.type", "Type")}: </div>
                      <Tag>{types.find(i => i._id === data.type).name}</Tag>
                    </div>
                    <div>
                      <div className="ant-list-item-meta-title">{translate("property.request.col.use.types", "Use types")}: </div>
                      {data?.useTypes?.map((item) => {
                        return <Tag>{item.name}</Tag>;
                      })}
                    </div>
                    <div>
                      <div className="ant-list-item-meta-title">{translate("property.request.col.furniture", "Furniture")}: </div>
                      {data?.equipments.filter(item => item.type === "FURNITURE").map((item) => {
                        return <Tag>{item.name}</Tag>;
                      })}
                    </div>
                    <div>
                      <div className="ant-list-item-meta-title">{translate("property.request.col.feature", "Feature")}: </div>
                      {data?.equipments.filter(item => item.type === "FEATURE").length > 0 ? data?.equipments.filter(item => item.type === "FEATURE").map((item) => {
                        return <Tag>{item.name}</Tag>;
                      }) : <Tag>-</Tag>}
                    </div>
                    <div>
                      <div className="ant-list-item-meta-title">{translate("property.request.col.facility", "Facility")}: </div>
                      {data?.equipments.filter(item => item.type === "FACILITY").length > 0 ? data?.equipments.filter(item => item.type === "FACILITY").map((item) => {
                        return <Tag>{item.name}</Tag>;
                      }) : <Tag>-</Tag>}
                    </div>
                  </Space>
                </div>
              </Col>
            </Row>
          </StyledCardRow>
          <div style={{ margin: "14px" }}>
            <div className="ant-page-header-heading-title">{translate("property.request.col.images", "Images")}</div>
          </div>
          <StyledFiles>
            <Row gutter={[16, 16]}>
              {
                data?.images.length > 0 ? data.images.map((item, index) => {
                  return <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} key={index}>
                    <Image height={170} width={"100%"} style={{ objectFit: "cover", backgroundSize: "fill" }} src={item?.url} />
                  </Col>;
                }) : <div>{translate("recruit.details.no.data", "No data")}</div>
              }
            </Row>
          </StyledFiles>
        </PageContent>}
      </ContainerStyled>
    </PageContainer>
  );
};

const useHeader = ({ translate }) => (
  [ {
    title : translate("recruit.details.category", "Start Date"),
    width : "200px",
    render: (row) => {
      return <Tag> {dateFormat(row?.startDate)}</Tag>;
    }
  }, {
    title : translate("recruit.details.point", "End Date"),
    width : "200px",
    render: (row) => {
      return <Tag> {dateFormat(row?.endDate)}</Tag>;
    }
  }]
);
const StyledFiles = styled.div`
  padding: 14px;
  background: #fff;

`;
const ContainerStyled = styled.div`
  max-width: 1200px;
`;
const StyledCardRow = styled.div`
  background: #fff;  
  padding: 0;
  padding: 24px;
  
  .content-wrapper { 
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    .ant-list-item-meta-description {
      padding-bottom: 8px;
    }
    .like {  
      padding: 8px 12px;
      border:  1px dashed #dadada;
      width: max-content; 
      border-radius: 2px;

    }
  } 
`;

export default Detail;