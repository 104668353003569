/* eslint-disable key-spacing */
import React from "react";
import { Col, Row, Button, message } from "antd";
import {
  PageHeader,
  PageContainer,
  PageContent,
} from "../../components/Layout";
import { useHistory } from "react-router-dom";
import { property as propertyApi } from "../../apis";
import Form from "./Form";
import { useTranslate } from "../../hooks";
import { useSelector } from "react-redux";

export default () => {
  const { translate } = useTranslate();
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const [preview, setPreview] = React.useState(false);
  const { webhost } = useSelector((state) => state.general);

  const onSubmit = async (values) => {
    if (preview) {
      if (values.images.some((s) => !s.description)) {
        message.error(
          translate(
            "system.request.description.error",
            "Images description required!"
          )
        );
        setLoading(false);
      } else {
        try {
          let res = await propertyApi.preview({
            ...values,
            images: values.images.filter((f) => f.url),
            previewStatus: "DRAFT",
            locationLat: values.location[0],
            locationLng: values.location[1],
            address: {
              postCode: values.postCode,
              city: values.city,
              address: values.address,
              buildingName: values.buildingName,
            },
            equipments: [
              ...values.furnitures,
              ...values.features,
              ...values.facilities,
            ],
          });
          setPreview(false);
          history.replace(`/property/edit/${res?.property}`);
          window.open(`${webhost}/preview/${res.property}`);
        } catch (err) {
          message.error(
            err?.message || translate("system.request.error", "Error!")
          );
        }
      }
    } else {
      setLoading(true);
      if (values.images.some((s) => !s.description)) {
        message.error(
          translate(
            "system.request.description.error",
            "Images description required!"
          )
        );
        setLoading(false);
      } else {
        try {
          await propertyApi.create({
            ...values,
            images: values.images.filter((f) => f.url),
            locationLat: values.location[0],
            locationLng: values.location[1],
            address: {
              postCode: values.postCode,
              city: values.city,
              address: values.address,
              buildingName: values.buildingName,
            },
            equipments: [
              ...values.furnitures,
              ...values.features,
              ...values.facilities,
            ],
          });
          history.push("/property");
          message.success(translate("system.request.success", "Success!"));
        } catch (err) {
          message.error(
            err?.message || translate("system.request.error", "Error!")
          );
          setLoading(false);
        }
      }
    }
  };
  return (
    <PageContainer>
      <PageHeader
        title={translate(
          "property.new.pageheader.title.add.property",
          "Add property"
        )}
        extra={[
          <Button
            style={{ marginRight: 10 }}
            onClick={() => history.push("/property")}
          >
            {translate("system.btn.cancel", "Cancel")}
          </Button>,
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              formRef.current.validate();
              setPreview(true);
            }}
          >
            {translate("system.action.preview", "Preview")}
          </Button>,
          <Button
            type="primary"
            loading={loading}
            onClick={() => formRef.current.validate()}
          >
            {translate("system.btn.save", "Save")}
          </Button>,
        ]}
      />

      <Row>
        <Col xs={24} sm={24} md={20} lg={18} xl={14} xxl={12}>
          <PageContent>
            <Form ref={formRef} action={["create"]} onSubmit={onSubmit} />
          </PageContent>
        </Col>
      </Row>
    </PageContainer>
  );
};
