import React from "react";
import { Tag, Button, Avatar, Modal, Input, DatePicker, Select } from "antd";
import { user as userApi } from "../../apis";
import { PlusOutlined, UserOutlined, SearchOutlined } from "@ant-design/icons";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import useFetch from "../../hooks/useFetch";

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [query, setQuery] = React.useState("");
  const { s3host, roles, isMobile } = useSelector((state) => state.general);
  const { checkPermission } = usePermission();

  const [filters, setFilters] = React.useState({
    type     : undefined,
    query    : "",
    role     : undefined,
    startDate: "",
    endDate  : "",
    isActive : undefined,
  });

  const columns = useHeader({
    translate,
    s3host,
    roles,
    type    : params.type,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "remove": {
          Modal.confirm({
            title  : translate("system.list.model.title", "Confirm"),
            icon   : <ExclamationCircleOutlined />,
            content: record.isActive
              ? translate(
                "appuser.list.modal.content",
                "Would you like to disactivate this user!"
              )
              : translate(
                "appuser.list.modal.content.activate",
                "Would you like to disactivate this user!"
              ),
            okText: record.isActive
              ? translate("appuser.modal.disactivate", "Disactivate")
              : translate("appuser.modal.activate", "Activate"),
            cancelText: translate("system.list.modal.canceltext", "Cancel"),
            onOk      : async () => {
              await userApi.remove(record._id);

              myTableRef.current.reload();
            },
          });
          break;
        }
        case "edit": {
          history.push(`/user/edit/${record._id}/${params.type}`);
          break;
        }
        default:
      }
    },
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    setFilters({
      type     : undefined,
      query    : "",
      role     : undefined,
      isActive : undefined,
      startDate: undefined,
      endDate  : undefined,
    });
  }, [params.type]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").toDate(),
      endDate  : e && moment(e[1]).endOf("days").toDate(),
    });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      query    : "",
      type     : undefined,
      role     : undefined,
      isActive : undefined,
      startDate: undefined,
      endDate  : undefined,
    });
  };

  return (
    <PageContainer>
      <PageHeader
        title={
          params.type === "app"
            ? translate("user.list.pageheader.app.user", "App user")
            : translate("user.list.pageheader.admin.user", "Admin user")
        }
        extra={[
          <Button
            type="primary"
            shape={isMobile && "circle"}
            onClick={() => history.push(`/user/new/${params.type}`)}
            icon={<PlusOutlined />}
          >
            {!isMobile && translate("user.list.btn.add.user", "Add user")}
          </Button>,
        ]}
      />
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Button onClick={onClear}>
              {translate("system.filter.btn.clear", "Clear")}
            </Button>,
          ]}
        >
          <Input
            className="grid-item"
            prefix={<SearchOutlined />}
            value={query}
            placeholder={translate("system.input.search", "Search...")}
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />
          ,
          <Select
            className="grid-item"
            value={filters.isActive}
            style={{ width: "130px" }}
            onChange={(e) => setFilters({ ...filters, isActive: e })}
            placeholder={translate("property.list.select.status", "Status")}
            allowClear
          >
            {[true, false].map((item, index) => (
              <Select.Option key={index} value={item}>
                {item
                  ? translate("appuser.list.status.active", "Active")
                  : translate("appuser.list.status.disactive", "Disactive")}
              </Select.Option>
            ))}
          </Select>
          {params.type === "admin" && (
            <Select
              className="grid-item"
              value={filters.role}
              style={{ width: "150px" }}
              onChange={(e) => setFilters({ ...filters, role: e })}
              placeholder={translate("user.list.select.user.role", "User role")}
              allowClear
            >
              {roles?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.code}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          )}
        </Filter>

        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={userApi.list(params.type)}
          rowKey={(record) => record?.rowKey}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ type, onAction, s3host, translate }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("user.list.avatar", "Avatar"),
      render: (record) => {
        return <Avatar src={`${record.avatar}`} icon={<UserOutlined />} />;
      },
    },
    type === "ADMIN" && {
      title : translate("user.list.user.role", "User role"),
      render: (record) => {
        return record.roles.map((item, index) => {
          return <Tag key={index}>{item.name}</Tag>;
        });
      },
    },
    {
      title : translate("user.list.lastname", "Lastname"),
      render: (record) => {
        return record.lastName;
      },
    },
    {
      title : translate("user.list.firstname", "Firstname"),
      render: (record) => {
        return record.firstName;
      },
    },
    {
      title : translate("user.list.email", "Email"),
      render: (record) => {
        return record.email;
      },
    },
    {
      title : translate("appuser.list.useheader.title.status", "Status"),
      render: (record) => {
        return record.isActive ? (
          <Tag color="green">
            {translate("appuser.list.status.active", "Active")}
          </Tag>
        ) : (
          <Tag color="red">
            {translate("appuser.list.status.disactive", "Disactive")}
          </Tag>
        );
      },
    },
    {
      title : translate("system.useheader.title.date", "Date"),
      render: (record) => {
        return <div>{moment(record.createdAt).format("YYYY/MM/DD HH:mm")}</div>;
      },
    },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        return (
          <RowAction
            actions={{
              edit  : translate("system.action.edit", "Edit"),
              remove: record.isActive
                ? translate("appuser.modal.disactivate", "Disactivate")
                : translate("appuser.modal.activate", "Activate"),
            }}
            onClick={(key) => onAction(key, record)}
          />
        );
      },
    },
  ].filter((col) => col);
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
