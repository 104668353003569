/* eslint-disable key-spacing */
import React from "react";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import {
  Button,
  Modal,
  message as notify,
  Space,
  Radio,
  Tag,
  Input,
  Alert,
} from "antd";
import {
  ExclamationCircleOutlined,
  FileOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { MyTable, RowAction } from "../../components";
import { ticket as ticketApi } from "../../apis";
import { useSelector } from "react-redux";
import usePermission from "../../hooks/usePermission";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { Filter } from "../../components/Design";
import { renderUser } from "../../utils";
import { Interweave } from "interweave";
import moment from "moment";

const List = () => {
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const { checkPermission } = usePermission();
  const { isMobile } = useSelector((state) => state.general);
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    isArchive: false,
    query: "",
  });

  const columns = useHeader({
    translate,
    checkPermission,
    filters,
    onAction: async (key, record) => {
      switch (key) {
        case "preview": {
          setAction(["preview", record]);
          break;
        }
        case "archive": {
          Modal.confirm({
            title: translate("system.list.model.title", "Confirm"),
            icon: <ExclamationCircleOutlined />,
            content: record.isArchive
              ? translate(
                "ticket.list.modal.dearchive",
                "Would you like to dearchvie this ticket ?"
              )
              : translate(
                "ticket.list.modal.archive",
                "Would you like to archvie this ticket ?"
              ),
            okText: record.isArchive
              ? translate("system.action.dearchive", "Dearchive")
              : translate("ticket.action.archive", "Archive"),
            cancelText: translate("system.list.modal.canceltext", "Cancel"),
            onOk: async () => {
              await ticketApi.archive(record._id);
              window.location.reload();
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction([]);
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title={translate("navbar.menu.ticket", "Ticket")} />
      <Filter>
        <Space>
          <Radio.Group
            defaultValue={filters.isArchive}
            buttonStyle="solid"
            onChange={(e) =>
              setFilters((state) => ({ ...state, isArchive: e.target.value }))
            }
          >
            <Radio.Button value={false}>
              {translate("ticket.new", "New")}
            </Radio.Button>
            <Radio.Button value={true}>
              {translate("ticket.archive", "Archive")}
            </Radio.Button>
          </Radio.Group>
        </Space>
        <Input
          className="grid-item"
          prefix={<SearchOutlined />}
          value={query}
          placeholder={translate("system.input.search", "Search...")}
          onChange={(e) => setQuery(e.target.value)}
          allowClear
        />
      </Filter>
      <PageContent>
        <MyTable
          bordered={!isMobile}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={ticketApi.list}
          rowKey={(record) => record.id}
        />
      </PageContent>

      <Modal
        centered
        title={translate("navbar.menu.ticket", "Ticket")}
        width={800}
        open={action && action[0] === "preview"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}
      >
        <Container>
          <Alert
            type="info"
            message={translate(
              "ticket.send.support.description",
              "Send support request to our staff using this form. You can express any problems you are facing while using our services. We will respond to your request as quick as possible to the mail you provide in the form below."
            )}
          ></Alert>
          <div className="title">
            {translate("ticket.textfeild.name", "Name...")}: {action[1]?.name}
          </div>
          <div className="email">
            {translate("ticket.textfeild.email", "Email...")}:
            {action[1]?.email}
          </div>
          <div className="name">
            {translate("ticket.textfeild.title", "Title...")}:
            {action[1]?.title}
          </div>
          <div className="name">
            {translate("ticket.createdat", "Created at")}:
            {moment(action[1]?.createdAt).format("YYYY/MM/DD HH:mm")}
          </div>
          {action[1]?.isArchive && (
            <div className="name">
              {translate("ticket.updatedat", "Updated at")}:
              {moment(action[1]?.updatedAt).format("YYYY/MM/DD HH:mm")}
            </div>
          )}
          <div className="body">{action[1]?.body}</div>
          {action[1]?.file ? (
            <a className="file" onClick={() => window.open(action[1]?.file)}>
              <div
                style={{
                  maxWidth: "200px",
                  borderRadius: "5px",
                  backgroundColor: "#f3f4f6",
                  padding: "5px 7px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <div style={{ fontSize: "22px" }}>
                  <FileOutlined />
                </div>
                <div className="fileName">
                  {translate("system.action.download", "Download")}
                </div>
                <div style={{ fontSize: "24px" }}>
                  <DownloadOutlined />
                </div>
              </div>
            </a>
          ) : null}
        </Container>
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate, filters }) => {
  const columns = [
    {
      title: "№",
      width: 20,
      render: (r, i, count) => {
        return count + 1;
      },
    },
    {
      title: translate("ticket.email", "E-mail"),
      render: (record) => {
        return `${record.email}`;
      },
    },
    {
      title: translate("ticket.name", "Name"),
      render: (record) => {
        return `${record.name}`;
      },
    },
    {
      title: translate("ticket.title", "Title"),
      render: (record) => {
        return `${record.title}`;
      },
    },
    {
      title: translate("ticket.createat", "Created at"),
      render: (record) => {
        return <div>{moment(record?.createdAt).format("YYYY/MM/DD HH:mm")}</div>;
      },
    },
  ];
  if (filters.isArchive) {
    columns.push({
      title: translate("ticket.updatedat", "Updated at"),
      render: (record) => {
        return <div>{moment(record?.updatedAt).format("YYYY/MM/DD HH:mm")}</div>;
      },
    });
  }
  columns.push({
    title: translate("system.useheader.title.action", "Action"),
    width: 100,
    render: (record) => {
      return (
        <RowAction
          actions={{
            preview: translate("system.action.preview", "Preview"),
            archive: record.isArchive
              ? translate("system.action.dearchive", "Dearchive")
              : translate("ticket.action.archive", "Archive"),
          }}
          onClick={(key) => onAction(key, record)}
        />
      );
    },
  });
  return columns;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .title {
    font-size: 14px;
    font-weight: 400;
    border: #f2f2f2 solid;
    border-radius: 5px;
    padding: 8px;
  }
  .name {
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 5px;
    border: #f2f2f2 solid;
    border-radius: 5px;
    padding: 8px;
  }
  .email {
    font-size: 14px;
    font-weight: 400;
    border: #f2f2f2 solid;
    border-radius: 5px;
    padding: 8px;
  }
  .body {
    padding-top: 5px;
    font-size: 14px;
    color: #2e2d2c;
    max-height: 400px;
    overflow-y: auto;
    word-break: break-word;
    border-bottom: 1px solid #f2f2f2;
    padding: 8px;
    border: #f2f2f2 solid;
    border-radius: 5px;
    white-space: pre-wrap;
  }
  .file {
    width: 100%;
    padding-top: 5px;
    display: flex;
    justify-content: end;

    .fileName {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export default List;
