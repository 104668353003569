import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form as AntForm, Row, Col, message } from "antd";
import { ImageUpload } from "../../components";
import { FormItem, Input, Select, Form, Checkbox } from "formik-antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { user as userApi } from "../../apis";
import { useTranslate } from "../../hooks";



export default ({ action }) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const params = useParams();
  const { roles } = useSelector((state) => state.general);
  const [password, setPassword] = React.useState(false);
  const [data] = React.useState({
    role     : undefined,
    isActive : true,
    lastName : undefined,
    firstName: undefined,
    avatar   : undefined,
    email    : undefined,
    password : undefined,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const FormSchema = () => Yup.object({
    role     : Yup.string().required(translate("system.regex.required", "required field")),
    isActive : Yup.boolean().required(translate("system.regex.required", "required field")),
    lastName : Yup.string().trim().required(translate("system.regex.required", "required field")),
    firstName: Yup.string().trim().required(translate("system.regex.required", "required field")),
    avatar   : Yup.string().trim().optional().nullable(),
    email    : Yup.string() .trim().email(translate("app.auth.forgot.email", "Email must be a valid email")).required(translate("system.regex.required", "required field")),
    password : Yup.string().optional().nullable()
  });

  const onSubmit = async (values) => {
    try {
      if (action && action[0] === "update") {
        await userApi.update(data._id, values);

        history.push(`/user/${params.type}`);
      } else {
        await userApi.create({
          type: params.type,
          ...values
        });

        history.push(`/user/${params.type}`);
      }

      message.success(translate("system.request.success", "Success!"));
    } catch (err) {
      console.log(err);
      message.error(err?.message || translate("system.request.error", "Error!"));
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema()}
        onSubmit={onSubmit}>
        {({ isSubmitting, errors }) => {
          console.log(errors);
          return (
            <Form {...formItemLayout}>
              <Input type="hidden" name="type" />

              <FormItem name="email" label={translate("user.form.email", "Email")} required>
                <Input name="email" placeholder={translate("user.form.email", "Email")} />
              </FormItem>

              <FormItem name="role" label={translate("user.form.user.role", "User role")} required>
                <Select name="role" placeholder={translate("user.form.user.role", "User role")}>
                  {roles?.map((item, index) => (
                    <Select.Option value={item.code} key={index}>{item.name}</Select.Option>
                  ))}
                </Select>
              </FormItem>
              {password && (
                <FormItem label={translate("user.form.password", "Password")} name="password">
                  <Row gutter={12}>
                    <Col>
                      <Input name="password" placeholder={translate("user.form.password", "Password")} />
                    </Col>
                    <Col>
                      <Button type="default" shape="circle" onClick={() => setPassword(!password)}><CloseOutlined /></Button>
                    </Col>
                  </Row>
                </FormItem>
              )}
              {!password && (
                <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 0 }}>
                  <Button type="default" onClick={() => setPassword(!password)} block>{translate("user.form.btn.set.password", "Set password")}</Button>
                </AntForm.Item>
              )}
              <FormItem name="avatar" label={translate("user.form.avatar", "Avatar")}>
                <ImageUpload action="/api/general/upload" name="avatar" />
              </FormItem>
              <FormItem name="lastName" label={translate("user.form.lastname", "Lastname")} required>
                <Input name="lastName" placeholder={translate("user.form.lastname", "Lastname")} />
              </FormItem>
              <FormItem name="firstName" label={translate("user.form.firstname", "Firstname")} required>
                <Input name="firstName" placeholder={translate("user.form.firstname", "Firstname")} />
              </FormItem>
              <FormItem name="isActive" label={translate("user.form.active.status", "Active status")} required>
                <Checkbox name="isActive" />
              </FormItem>
              <AntForm.Item {...tailFormItemLayout}>
                <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.btn.save", "Save")}</Button>
                <Button onClick={() => history.push(`/user/list/${params.type}`)}>{translate("system.btn.cancel", "Cancel")}</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
