
import React from "react";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import {
  Button,
  Modal,
  message as notify,
  Input,
  Space,
  Tag,
  Radio,
  Avatar,
} from "antd";
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { MyTable, RowAction } from "../../components";
import { recommend as recommendApi } from "../../apis";
import { useSelector } from "react-redux";
import usePermission from "../../hooks/usePermission";
import styled from "styled-components";
import Form from "./Form";
import { useTranslate } from "../../hooks";

const List = () => {
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const { checkPermission } = usePermission();
  const { isMobile } = useSelector((state) => state.general);
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query: "",
  });

  const columns = useHeader({
    translate,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "edit": {
          setAction(["update", record]);
          break;
        }
        case "remove": {
          Modal.confirm({
            title  : translate("system.list.model.title", "Confirm"),
            icon   : <ExclamationCircleOutlined />,
            content: translate(
              "recommend.list.model.content",
              "Would you like to delete this user!!!"
            ),
            okText    : translate("system.list.modal.oktext", "Remove"),
            cancelText: translate("system.list.modal.canceltext", "Cancel"),
            onOk      : async () => {
              await recommendApi.remove(record._id);

              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction([]);
  };

  const onSubmit = async (data) => {
    if (action && action[0] === "update") {
      await recommendApi.update(action[1]?._id, data);
    } else {
      await recommendApi.create(data);
    }

    notify.success(translate("system.request.success", "Success!"));
    myTableRef.current.reload();
    onCancel();
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  return (
    <PageContainer>
      <PageHeader
        title={translate("recommend.pageheader.title", "Recommendation list")}
        extra={[
          <Button
            key={0}
            shape={isMobile && "circle"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setAction(["create"])}
          >
            {!isMobile && translate("system.btn.add", "Add")}
          </Button>,
        ]}
      />
      <PageFilter>
        <Space>
          <Input
            name="query"
            value={query}
            placeholder={translate("system.input.search", "Search...")}
            style={{ width: 250 }}
            onChange={(e) => setQuery(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          bordered={!isMobile}
          columns={columns}
          ref={myTableRef}
          filters={filters}
          loadData={recommendApi.list}
          rowKey={(record) => record.id}
        />
      </PageContent>

      <Modal
        title={
          action && action[0] === "create"
            ? translate("system.pagecontainer.model.title.add", "Add")
            : translate("system.pagecontainer.model.title.edit", "Edit")
        }
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}
      >
        {action && action[0] && (
          <Form onCancel={onCancel} action={action} onSubmit={onSubmit} />
        )}
      </Modal>
    </PageContainer>
  );
};

// const useHeader = ({ onAction, translate }) => {
//   return [{
//     title : "№",
//     width : 20,
//     render: (r, i, count) => {
//       return (count + 1);
//     },
//   }, {
//     title : translate("system.useheader.title.name", "Name"),
//     render: (record) => {
//       return `${record.name}`;
//     }
//   }, {
//     title : translate("recommend.useheader.description", "Description"),
//     render: (record) => {
//       return `${record.desc}`;
//     }
//   }, {
//     title : translate("system.useheader.title.action", "Action"),
//     width : 100,
//     render: (record) => {
//       return (
//         <RowAction
//           actions={{
//             edit  : translate("system.action.edit", "Edit"),
//             remove: translate("system.action.remove", "Remove"),
//           }}
//           onClick={(key) => onAction(key, record)} />
//       );
//     }
//   }];
// };

const useHeader = ({ onAction, translate }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("system.property.list.image", "Image"),
      render: (record) => {
        return (
          <Avatar
            src={`${record.icon}`}
            shape="square"
            icon={<PictureOutlined />}
          />
        );
      },
    },
    {
      title : translate("system.useheader.title.name", "Name"),
      render: (record) => {
        return (
          <div>
            <div>{record.name}</div>
          </div>
        );
      },
    },
    {
      title : translate("recommend.useheader.description", "Description"),
      render: (record) => {
        return `${record.desc}`;
      },
    },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        return (
          <RowAction
            actions={{
              edit  : translate("system.action.edit", "Edit"),
              remove: translate("system.action.remove", "Remove"),
            }}
            onClick={(key) => onAction(key, record)}
          />
        );
      },
    },
  ];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
`;

export default List;
