/* eslint-disable key-spacing */
import { RangeCalendar } from "@mantine/dates";
import { Button, createStyles, Group } from "@mantine/core";
import { useTranslate } from "../../hooks";
import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import styled from "styled-components";
import "dayjs/locale/ja";
const NewCalendarForm = ({
  minRange,
  gahaiError,
  disabledDates,
  setRange,
  setDisable,
  minRequired,
  owner,
  size,
  isBalai,
}) => {
  const [value, setValue] = React.useState([null, null]);
  const { translate } = useTranslate();
  const [selectedRangeDates, setSelectedRangeDates] = React.useState(null);
  const { dates } = useSelector((state) => state.general);

  const getDates = (startDate, endDate) => {
    const daysbetween =
      Math.floor((Date.parse(startDate) - Date.parse(endDate)) / 86400000) - 1;

    const dates = Array(-daysbetween || 0).fill("");

    return dates.map((date, index) => {
      if (index === 0) {
        return startDate;
      }
      if (index === -daysbetween) {
        return endDate;
      }

      return moment(startDate, "YYYY-MM-DD").add(index, "days");
    });
  };

  const selectedDateIncludeError = selectedRangeDates?.some((r) =>
    disabledDates.includes(r)
  );

  const minRageError = selectedRangeDates?.length < minRange;

  const onClick = () => {
    if (selectedDateIncludeError || (minRageError && minRequired)) {
      gahaiError([selectedDateIncludeError, minRageError]);
    } else {
      if (!minRequired && minRageError) {
        gahaiError([selectedDateIncludeError, minRageError]);
      }

      if (!isBalai) {
        setDisable((y) => [...y, ...selectedRangeDates]);
      }

      if (isBalai) {
        setRange((g) => [
          {
            startDate: moment(value[0]).format("YYYY/MM/DD"),
            endDate: value[1]
              ? moment(value[1]).format("YYYY/MM/DD")
              : moment(value[0]).format("YYYY/MM/DD"),
          },
        ]);
      } else {
        setRange((g) => [
          ...g,
          {
            startDate: moment(value[0]).format("YYYY/MM/DD"),
            endDate: value[1]
              ? moment(value[1]).format("YYYY/MM/DD")
              : moment(value[0]).format("YYYY/MM/DD"),
          },
        ]);
      }

      setValue([null, null]);

      setSelectedRangeDates(null);
    }
  };

  const useStyles = createStyles((theme) => ({
    outside: {
      opacity: 1,
    },

    weekend: {
      color: `${theme.colors.red[5]} !important`,
    },
    range: {
      color: "white !important",
      backgroundColor: `${theme.colors.orange[3]} !important`,
    },
    selectedRange: {
      color: "black !important",
      backgroundColor: `${theme.colors.orange[5]} !important`,
    },
    disabled: {
      backgroundColor: `${theme.colors.gray[1]} !important`,
      color: `${theme.colors.dark[2]} !important `,
      textDecoration: "line-through",
    },
  }));

  const { classes, cx } = useStyles();

  return (
    <BigContainer>
      <Group className="container" position="center">
        <RangeCalendar
          allowSingleDateInRange
          size={size || "md"}
          locale="ja"
          minDate={new Date()}
          value={value}
          onChange={(newValue) => {
            if (newValue) {
              setSelectedRangeDates(
                getDates(
                  newValue[0],
                  newValue[1] ? newValue[1] : newValue[0]
                ).map((c) => moment(c).format("YYYY/MM/DD"))
              );
              gahaiError([false, false]);
            }
            setValue(newValue);
          }}
          excludeDate={(date) => {
            const filtered = disabledDates?.filter(
              (e) => moment(date).format("YYYY/MM/DD") === e
            );
            const isDisabled =
              moment(date).format("YYYY/MM/DD") === filtered?.pop();

            return isDisabled;
          }}
          renderDay={(date) => {
            const day = moment(date).format("YYYY/MM/DD");

            if (dates?.includes(day)) {
              return <div className="text-red-300 ">{date.getDate()}</div>;
              // eslint-disable-next-line no-else-return
            } else {
              return <div className="font-semibold">{date.getDate()}</div>;
            }
          }}
          dayClassName={(date, modifiers) =>
            cx({
              [classes.outside]: modifiers.outside,
              [classes.weekend]: modifiers.weekend,
              [classes.range]: modifiers.inRange,
              [classes.selectedRange]: modifiers.selectedInRange,
              [disabledDates?.includes(moment(date).format("YYYY/MM/DD"))
                ? classes.disabled
                : ""]: modifiers.disabled,
            })
          }
          styles={(theme) => ({
            cell: {
              border: `1px solid ${theme.colors.gray[2]}`,
            },

            day: {
              "&[data-selected]": {
                backgroundColor: theme.colors.orange[5],
                borderRadius: 5,
                position: "relative",
              },

              "&[data-in-range]": {
                backgroundColor: theme.colors.orange[3],
                ":disabled": { backgroundColor: theme.colors.grape[5] },
              },
            },
          })}
        />
        <Button
          className="Button"
          color="orange"
          disabled={!selectedRangeDates || !value[0]}
          onClick={() => onClick()}
        >
          {translate("app.calendar.form.pickThisDates", "Pick this range date")}
        </Button>
      </Group>
    </BigContainer>
  );
};

const BigContainer = styled.div`
  .container {
    display: flex;
    flex-direction: column;

    .Button {
      width: 200px;
    }
  }
`;

export default NewCalendarForm;
