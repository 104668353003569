/* eslint-disable key-spacing */
/* eslint-disable no-nested-ternary */

/* eslint-disable no-promise-executor-return */
import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Row, Spin, Image, Modal } from "antd";
import styled from "styled-components";
import { datetimeFormat, yen } from "../../../utils";
import {
  SendOutlined,
  FileAddFilled,
  DownloadOutlined,
  FileOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { socket } from "../../../components/SocketClient";
import { message as messageApi } from "../../../apis";
import dayjs from "dayjs";
import { useTranslate } from "../../../hooks";
import { upload } from "../../../apis/message";
import Print from "./Print";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const Chat = ({ data }) => {
  const auth = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { chats, scrollOn, reload } = useSelector((state) => state.socket);
  const [value, setValue] = React.useState("");
  const [file, setFile] = React.useState(null);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [chatLength, setChatLength] = useState(0);
  const viewport = useRef();
  const [fileLoading, setFileLoading] = React.useState(false);

  const [print, setPrint] = useState([false]);

  const [filters, setFilters] = useState({
    offset: {
      page: 1,
      limit: 10,
    },
    filter: {
      id: params?.id,
    },
  });

  const send = async () => {
    if (file) {
      setValue("");

      if (value?.trim()?.length > 0) {
        socket.emit("action", {
          type: "conversation.chat",
          payload: {
            uid: uuidv4(),
            user: user?._id,
            message: value,
            url: undefined,
            conversationId: params.id,
          },
        });
      }


      socket.emit("action", {
        type: "conversation.chat",
        payload: {
          uid: uuidv4(),
          user: user?._id,
          message: file[0],
          url: file[1],
          conversationId: params.id,
        },
      });

      setFile(null);

    } else if (value?.trim()?.length > 0) {
      setValue("");
      const CHAT = {
        uid: uuidv4(),
        user: user?._id,
        message: value,
        url: undefined,
      };

      await socket.emit("action", {
        type: "conversation.chat",
        payload: {
          ...CHAT,
          conversationId: params.id,
        },
      });
    }
  };

  const onUploadFile = async (e) => {
    setFileLoading(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await fetch("/api/conversation/upload", {
        body: formData,
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        contentType: "multipart/form-data",
        method: "POST",
      });
      const data = await res.json();
      setFile([file?.name, data?.url]);
      setFileLoading(false);
    } catch (error) {
      console.log("File upload error");
      setFileLoading(false);
    }
  };

  const scrollToBottom = (smooth) =>
    viewport?.current?.scrollTo({
      top: viewport?.current.scrollHeight,
      behavior: smooth ? "smooth" : "auto",
    });

  const reloadChatInit = async () => {
    dispatch({
      type: "socket.clear",
    });
    if (params.id)
      await socket.emit("action", {
        type: "owner.seen",
        payload: params.id,
      });
  };

  const loaData = async () => {
    setLoading(true);
    if (filters.filter.id) {
      setTimeout(async () => {
        const res = await messageApi.chat(filters);

        dispatch({
          type: "socket.chats",
          payload: [...chats, ...res.rows],
        });
        dispatch({
          type: "socket.chat.reload",
          payload: null,
        });
        setChatLength(res?.count || 0);
        setLoading(false);
        await viewport?.current?.scrollTo({
          top: 20,
          behavior: "smooth",
        });
      }, 1000);
    }
  };

  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  const onScroll = async (e) => {
    if (e) {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (chats.length < chatLength) {
        if (scrollTop === 0) {
          setLoading(true);
          await delay(30);
          setLoading(false);
          setFilters({
            ...filters,
            offset: { ...filters.offset, page: filters.offset.page + 1 },
          });
        }
      }
    }
  };

  useEffect(() => {
    loaData();
  }, [filters]);

  useEffect(() => {
    if (scrollOn) scrollToBottom();
    dispatch({
      type: "scroll.off",
    });
  }, [scrollOn]);

  useEffect(() => {
    reloadChatInit();
  }, []);

  useEffect(() => {
    if (reload === params.id) {
      dispatch({ type: "socket.chats", payload: [] });
      setFilters({
        ...filters,
        offset: {
          page: 1,
          limit: 10,
        },
      });
      loaData();
    }
  }, [reload]);

  const paymentStatus = (status) => {
    if (status === "PENDING" || status === "TRANSFER")
      return (
        <div
          style={{
            padding: 4,
            textAlign: "center",
            backgroundColor: "#2196f3",
            color: "white",
            borderRadius: 5,
          }}
        >
          {translate(status, status)}
        </div>
      );
    if (status === "PAID")
      return (
        <div
          style={{
            padding: 4,
            textAlign: "center",
            backgroundColor: "#4caf50",
            color: "white",
            borderRadius: 5,
          }}
        >
          {translate(status, status)}
        </div>
      );
    if (status === "CANCELLED")
      return (
        <div
          style={{
            padding: 4,
            textAlign: "center",
            backgroundColor: "#f44336",
            color: "white",
            borderRadius: 5,
          }}
        >
          {translate(status, status)}
        </div>
      );
  };
  const Payment = ({ item }) => {
    return (
      <PaymentStyled>
        <div className="container">
          <table>
            <tr>
              <td>
                <div>{translate("chat.amount", "Amount")}:</div>
              </td>
              <td>
                <div>{yen(item?.payment?.amount)}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>{translate("chat.tax", "Tax")}:</div>
              </td>
              <td>
                <div>{yen(item?.payment?.tax)}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>{translate("chat.total.amount", "Total amount")}:</div>
              </td>
              <td>
                <div>{yen(item.payment.tax + item.payment.amount || 0)}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>{translate("chat.total.duration", "Duration")}:</div>
              </td>
              <td>
                <div>
                  {dayjs(item?.payment?.startDate).format("YYYY/MM/DD")}-
                  {dayjs(item?.payment?.endDate).format("YYYY/MM/DD")}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  {translate("app.chat.chat.expireDate", "Payment expire date")}
                  :
                </div>
              </td>
              <td>
                <div className="text-[13px] max-w-md break-words text-end">
                  {dayjs.utc(item?.payment?.expireDate).format("YYYY/MM/DD")}
                </div>
              </td>
            </tr>
          </table>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {translate("chat.payment.alert.pay", "Pay the before tommorow!")}
            </div>
            {item.payment.paymentStatus !== "CANCELLED" && (
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={() => setPrint([true, item])}
                  style={{ color: "#e05c04" }}
                  type="text"
                >
                  {translate("app.chat.chat.estimation", "See estimation")}
                </Button>
              </div>
            )}
          </div>
          <div style={{ padding: 10 }}>
            {paymentStatus(item?.payment?.paymentStatus)}
          </div>
        </div>
        <div className="chat-date">
          {dayjs(item.chatSendDate).format("YYYY/MM/DD HH:mm")}
        </div>
        <PrintModal
          centered
          title={translate("chat.payment.print", "Print")}
          onCancel={() => setPrint([false])}
          visible={print[0]}
          footer={false}
        >
          <Print data={print[1] || null} />
        </PrintModal>
      </PaymentStyled>
    );
  };

  const Order = ({ item }) => {
    return (
      <OrderStyled>
        <div className="container">
          <div className="title">
            {translate("chat.about.planning", "About planning")}
          </div>
          <div className="value">{item?.order.useType}</div>
          <div className="label">
            {translate("chat.project.name", "Project name")}
          </div>
          <div>{item?.order.businessName}</div>
          <div className="label">
            {translate("chat.plan.contents", "Plan contents")}
          </div>
          <div>{item?.order.businessDesc}</div>
          <div className="label">
            {translate(
              "chat.eating.and.drinking.tasting",
              "Eating and drinking / tasting"
            )}
          </div>
          <div>
            {item?.order.isProvisional
              ? translate("app.chat.detail.provided", "Provided")
              : translate("app.chat.detail.no", "No")}
          </div>
          <div className="label">{translate("chat.inquiry", "Inquiry")}</div>
          <div>{item?.order.inquiry}</div>
        </div>
        <div className="chat-date">
          {dayjs(item.chatSendDate).format("YYYY/MM/DD HH:mm")}
        </div>
      </OrderStyled>
    );
  };

  const uniqueChats = chats.reduce((accumulator, item) => {
    const foundItem = accumulator?.find(
      (existingItem) => existingItem._id === item._id
    );
    if (!foundItem) {
      accumulator.push(item);
    }
    return accumulator;
  }, []);

  if (loading) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }

  console.log("cjattttttts", uniqueChats);
  return (
    <Container>
      <ScrollStyled file={file} ref={viewport} onScroll={onScroll}>
        {uniqueChats
          ?.slice(0)
          .reverse()
          ?.map((item, index) => {
            if (item?.order) {
              return (
                <ChatRow
                  key={index}
                  className={item.user === data?.tenant?._id ? "" : "right"}
                >
                  <Order item={item} />
                </ChatRow>
              );
            }
            if (item?.url) {
              return (
                <div key={index}>
                  {item?.url && (
                    <ChatRow
                      className={item.user === data?.tenant?._id ? "" : "right"}
                    >
                      <div>
                        <div>
                          <div
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#f3f4f6",
                              padding: "10px 14px",
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <div style={{ fontSize: "32px" }}>
                              <FileOutlined />
                            </div>
                            <div style={{}} className="">
                              {item?.message}
                            </div>
                            <a
                              onClick={() => window.open(item?.url)}
                              style={{ fontSize: "24px" }}
                            >
                              <DownloadOutlined />
                            </a>
                          </div>
                          <div className="chat-date">
                            {datetimeFormat(item?.chatSendDate)}
                          </div>
                        </div>
                      </div>
                    </ChatRow>
                  )}
                </div>
              );
            }
            if (item?.payment) {
              return (
                <ChatRow
                  className={item.user === data?.tenant?._id ? "" : "right"}
                  key={index}
                >
                  <Payment item={item} />
                </ChatRow>
              );
            }

            return (
              <div key={index}>
                {item?.message && (
                  <ChatRow
                    className={item.user === data?.tenant?._id ? "" : "right"}
                  >
                    <div>
                      <div className="chat-wrapper">
                        <div className="chat-message">{item?.message}</div>
                        <div className="chat-date">
                          {/* {datetimeFormat(item?.chatSendDate)} */}
                          {datetimeFormat(item?.chatSendDate)}
                        </div>
                      </div>
                    </div>
                  </ChatRow>
                )}
              </div>
            );
          })}
      </ScrollStyled>

      <SendStyled>
        {file ? (
          <div className="file">
            <div className="fileBox">
              <a onClick={() => setFile(null)} className="x">
                <CloseCircleOutlined />
              </a>
              <Image preview={false} className="img" src={"/file.svg"} />
              <div className="text">{file[0]}</div>
            </div>
          </div>
        ) : null}
        <div className="inputContainer">
          {fileLoading ? (
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "59px",
                height: "30px",
                color: "white",
                borderRadius: "3px",
                backgroundColor: "#e05c04",
                padding: "2px",
              }}
            >
              <Spin
                style={{
                  marginLeft: "20%",
                  position: "absolute",
                  font: "24px",
                }}
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 24,
                      color: "white",
                    }}
                    spin
                  />
                }
              />
            </div>
          ) : (
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "50px",
                height: "30px",
                color: "white",
                borderRadius: "3px",
                backgroundColor: "#e05c04",
                padding: "2px",
              }}
            >
              <input
                type="file"
                onChange={onUploadFile}
                style={{
                  width: "50px",
                  opacity: 0,
                  zIndex: 1,
                }}
              />

              <FileAddFilled
                style={{
                  fontSize: "24px",
                  marginLeft: "25%",
                  position: "absolute",
                }}
              />
            </div>
          )}
          <Input.TextArea
            rows={!value ? 1 : 3}
            value={value}
            placeholder="..."
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              send();
            }}
          >
            {translate("chat.btn.send", "Send")} <SendOutlined />
          </Button>
        </div>
      </SendStyled>
    </Container>
  );
};

const Container = styled.div`
  @media print {
    display: none !important ;
  }
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ScrollStyled = styled.div`
  height: 100%;
  min-height: 85vh;
  overflow-y: scroll;
  padding: 10px 0 60px;
`;

const SendStyled = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #f3f4f6;
  gap: 8px;
  flex-direction: column;
  padding: 12px;
  bottom: 0;
  background-color: white;
  position: absolute;

  .file {
    .fileBox {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 3px;
      padding: 5px;
      border: 1px solid #c4c3c2;
      border-radius: 3px;
      background-color: #fcfbfa;
      width: 100px;
      height: 120px;

      .x {
        cursor: pointer;
        color: gray;
        font-size: 20px;
        position: absolute;
        top: 0;
        margin-top: -14px;
        margin-right: -10px;
        height: 24px;
        background-color: white;
        border-radius: 100%;
        right: 0;
        :hover {
          color: red;
        }
      }

      .img {
        height: 50px;
      }
      .text {
        overflow: hidden;
        line-clamp: 2;
        word-break: break-all;
      }
    }
  }
  .inputContainer {
    display: flex;
    width: 100%;
    gap: 8px;
  }
`;

const OrderStyled = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 450px;

  .container {
    border: 1px solid #dadada;
    max-width: 450px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .label {
      font-size: 18px;
      font-weight: 500;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #333;
    }
  }
`;

const PrintModal = styled(Modal)`
  .ant-modal-content {
    width: 620px;
  }

  @media print {
    .ant-modal {
      width: 100vh;
      height: 100vh;
      background-color: white;
    }

    .ant-modal-header {
      display: none;
    }
    .ant-modal-close-x {
      display: none;
    }

    .ant-modal-mask {
      display: none !important;
    }
    .ant-modal-body {
      position: relative;
      width: 100%;
      height: 90%;
    }
    .ant-modal-content {
      position: fixed;
      left: 0;
      top: 0;
      box-shadow: none;
      height: 100%;
      width: 100%;
    }
  }
`;

const PaymentStyled = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 450px;

  .container {
    max-width: 450px;
    background-color: white;
    border: 1px solid #dadada;
    padding: 0 10px;
    border-radius: 5px;
    width: 100%;
  }

  table {
    width: 100%;
    font-weight: 600;

    tr {
      border-bottom: 1px solid #dadada;
      div {
        padding: 8px 0;
      }

      td:last-child {
        text-align: end;
      }
    }

    tr:last-child {
      border: 0;
    }
  }
`;

const ChatRow = styled.div`
  width: 100%;
  display: flex;
  padding: 5px 14px;

  .chat-wrapper {
    max-width: 500px;

    .chat-message {
      background: #f3f4f6;
      border-radius: 5px;
      padding: 10px 14px;
      word-wrap: break-word;
      word-break: break-all;
      white-space: pre-wrap;
    }
  }
  .chat-date {
    font-size: 13px;
    color: #9ca3af;
    line-height: 22px;
  }

  &.right {
    justify-content: end;
    .chat-date {
      text-align: end;
    }

    .chat-message {
      background: #f66d3b;
      color: #fff;
    }
  }
`;

export default Chat;
