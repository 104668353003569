/* eslint-disable key-spacing */
import React from "react";
import { Col, Row, Spin, Button, message } from "antd";
import { property as propertyApi } from "../../apis";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/Layout";
import { useHistory, useParams } from "react-router-dom";
import Form from "./Form";
import { useTranslate } from "../../hooks";
import { useSelector } from "react-redux";

export default () => {
  const { translate } = useTranslate();
  const params = useParams();
  const formRef = React.useRef();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [action, setAction] = React.useState();
  const [preview, setPreview] = React.useState(false);
  const { webhost } = useSelector((state) => state.general);

  const reload = React.useCallback(
    async (signal) => {
      setLoading(true);
      let res = await propertyApi.get(params.id, { signal });
      setAction([
        "update",
        {
          ...res,
          location: [res.locationLat, res.locationLng],
          postCode: res.address?.postCode,
          city: res.address?.city,
          address: res.address?.address,
          buildingName: res.address?.buildingName,
          furnitures: res.equipments
            .filter((ep) => ep.type === "FURNITURE")
            .map((ep) => ep._id),
          features: res.equipments
            .filter((ep) => ep.type === "FEATURE")
            .map((ep) => ep._id),
          facilities: res.equipments
            .filter((ep) => ep.type === "FACILITY")
            .map((ep) => ep._id),
          useTypes: res.useTypes.map((type) => type._id),
        },
      ]);
      setLoading(false);
    },
    [params.id]
  );

  const onSubmit = async (values) => {
    if (preview) {
      try {
        await propertyApi.preview({
          ...values,
          property: params.id,
          previewStatus: "DRAFT",
          locationLat: values.location[0],
          locationLng: values.location[1],
          address: {
            postCode: values.postCode,
            city: values.city,
            address: values.address,
            buildingName: values.buildingName,
          },
          equipments: [
            ...values.furnitures,
            ...values.features,
            ...values.facilities,
          ],
        });
        setPreview(false);
        window.open(`${webhost}/preview/${params.id}`);
      } catch (err) {
        message.error(
          err?.message || translate("system.request.error", "Error!")
        );
      }
    } else {
      setLoading(true);

      try {
        await propertyApi.update(params.id, {
          ...values,
          locationLat: values.location[0],
          locationLng: values.location[1],
          address: {
            postCode: values.postCode,
            city: values.city,
            address: values.address,
            buildingName: values.buildingName,
          },
          equipments: [
            ...values.furnitures,
            ...values.features,
            ...values.facilities,
          ],
        });

        history.push("/property");
        message.success(translate("system.request.success", "Success!"));
      } catch (err) {
        message.error(
          err?.message || translate("system.request.error", "Error!")
        );
        setLoading(false);
      }
    }
  };
  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);

  return (
    <Spin spinning={loading}>
      <PageContainer>
        <PageHeader
          title={translate("property.edit.pageheader.title", "Edit property")}
          extra={[
            <Button
              style={{ marginRight: 10 }}
              onClick={() => history.push("/property")}
            >
              {translate("system.btn.cancel", "Cancel")}
            </Button>,
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                formRef.current.validate();
                setPreview(true);
              }}
            >
              {translate("system.action.preview", "Preview")}
            </Button>,
            <Button
              type="primary"
              loading={loading}
              onClick={() => formRef.current.validate()}
            >
              {translate("system.btn.save", "Save")}
            </Button>,
          ]}
        />

        <Row>
          <Col xs={24} sm={24} md={20} lg={18} xl={14} xxl={12}>
            <PageContent>
              {!loading && (
                <Form ref={formRef} action={action} onSubmit={onSubmit} />
              )}
            </PageContent>
          </Col>
        </Row>
      </PageContainer>
    </Spin>
  );
};
